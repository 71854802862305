import { AxiosError } from 'axios';

import { AtiraLinkActions } from '../redux/atira-link/atira-link.slice';
import store from '../redux/store';
import { userActions } from '../redux/user/user.slice';

/** Add actions here that are needed to be dispatched at the start of the app; */
export async function onAppStart() {
  const dispatch = store.store.dispatch;

  try {
    const state = store.store.getState();
    const userId = state.user.loggedInUserId;

    if (userId) {
      await dispatch(userActions.refreshToken()).unwrap();
      dispatch(userActions.getLoggedInUser({ userId }));
      dispatch(AtiraLinkActions.getUserAtiraLinks({ userId }));
    }
  } catch (e: any) {
    const error = e as AxiosError;

    if (error.response?.status === 403) {
      dispatch(userActions.logout());
    }
  }
}
