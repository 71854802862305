import { TabsProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraEmptyWithAction } from '../../components/AtiraEmptyWithAction';
import { AtiraTabs } from '../../components/AtiraTabs';
import { Flex } from '../../components/Flex';
import { UserHeader } from '../../components/UserHeader';
import { atiraLinkSliceSelectors } from '../../redux/atira-link/atira-link.selector';
import { useAppSelector } from '../../redux/store';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';
import { SettingsGeneral } from './tabs/SettingsGeneral';
import { SettingsIndex } from './tabs/SettingsIndex';
import { SettingsLanguage } from './tabs/SettingsLanguage';
import { SettingsOpenGraph } from './tabs/SettingsOpenGraph';
import { SettingsSelfHosting } from './tabs/SettingsSelfHosting';

const UserHeaderWrapper = styled(Flex)`
  display: none;
  padding-top: ${Spacing.s};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: flex;
  }
`;

export const SettingsRoute: React.FC = () => {
  const { t } = useTranslation();

  const userAtiraLinks = useAppSelector(
    atiraLinkSliceSelectors.selectUserAtiraLinks,
  );

  const navigate = useNavigate();

  const items: TabsProps['items'] = [
    {
      key: 'general',
      label: t('common.general'),
      children: <SettingsGeneral />,
    },
    {
      key: 'open-graph',
      label: t('settings.open_graph'),
      children: <SettingsOpenGraph />,
    },
    {
      key: 'self-hosting',
      label: t('settings.self_hosting'),
      children: <SettingsSelfHosting />,
    },
    {
      key: 'link-lang',
      label: t('common.language'),
      children: <SettingsLanguage />,
    },
    {
      key: 'link-index',
      label: t('common.index'),
      children: <SettingsIndex />,
    },
  ];

  if (!userAtiraLinks.length) {
    return (
      <AtiraEmptyWithAction
        description={t('settings.no_link')}
        buttonTitle={t('settings.create_link')}
        onClick={() => navigate('/my-link')}
      />
    );
  }

  return (
    <AtiraTabs
      items={items}
      sideComponent={
        <UserHeaderWrapper>
          <UserHeader />
        </UserHeaderWrapper>
      }
    />
  );
};
