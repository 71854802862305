import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const atiraLinkState = (state: RootState) => state.atiraLink;

const selectUserAtiraLinks = createSelector(
  atiraLinkState,
  (state) => state.atiraLinks || [],
);

const selectUserAtiraLinkLoading = createSelector(
  atiraLinkState,
  (state) => state.atiraLinkLoading,
);

const selectSelectedAtiraLinkStats = createSelector(
  atiraLinkState,
  (state) => state.selectedAtiraLinkStats,
);

const selectThemes = createSelector(
  atiraLinkState,
  (state) => state.themes || [],
);

const selectThemesLoading = createSelector(
  atiraLinkState,
  (state) => state.themesLoading,
);

const selectSelectedAtiraLink = createSelector(
  atiraLinkState,
  (state) => state.selectedAtiraLink,
);

export const atiraLinkSliceSelectors = {
  selectUserAtiraLinks,
  selectUserAtiraLinkLoading,
  selectSelectedAtiraLinkStats,
  selectThemes,
  selectThemesLoading,
  selectSelectedAtiraLink,
};
