import { User } from '../model/user/User';

export function isUserOnFreeTrial(user: User): boolean {
  if (!user?.plan) {
    return false;
  }

  return (
    user?.plan?.freeTrial?.hasUsed === true &&
    (user?.plan?.freeTrial?.endsAt?.length || 0) > 0
  );
}

export function isUserOnUnpaidPlan(user: User): boolean {
  if (!user?.plan) {
    return false;
  }

  return (
    user?.plan?.unpaidPlan?.hasUsed === true &&
    (user?.plan?.unpaidPlan?.endsAt?.length || 0) > 0
  );
}
