import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const paymentState = (state: RootState) => state.payment;

const selectCurrentCheckoutURL = createSelector(
  paymentState,
  (state) => state.currentCheckoutURL,
);

const selectCurrentCheckoutURLLoading = createSelector(
  paymentState,
  (state) => state.currenCheckoutURLLoading,
);

const selectPlans = createSelector(paymentState, (state) => state.plans);

const selectPlansLoading = createSelector(
  paymentState,
  (state) => state.plansLoading,
);

export const paymentSliceSelectors = {
  selectCurrentCheckoutURL,
  selectCurrentCheckoutURLLoading,
  selectPlans,
  selectPlansLoading,
};
