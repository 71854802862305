import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraColumn } from '../../../components/AtiraColumn';
import { AtiraIcon } from '../../../components/AtiraIcon';
import { AtiraRow } from '../../../components/AtiraRow';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { AppLangs } from '../../../i18n';
import { AtiraLinkPlanDefinition } from '../../../model/plan/Plan-definition';
import { AtiraLinkPlanType } from '../../../model/plan/types/AtiraLinkPlanType.enum';
import { paymentSliceSelectors } from '../../../redux/payment/payment.selector';
import { paymentActions } from '../../../redux/payment/payment.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { PlansLoading } from '../../pricing/components/PlansLoading';
import { SignupFlowModal } from '../../pricing/components/SignupFlowModal';
import {
  getPlanTitle,
  toUSD,
  translatePerks,
} from '../../pricing/pricingUtils';

const Card = styled(Flex)`
  border-radius: ${Rounded.pill};
  flex-direction: column;
  align-items: center;
  gap: ${Spacing.m};
  background-color: ${({ theme }) => theme.light};
  padding: ${Spacing.m};
  padding-bottom: ${Spacing.l};
  height: 100%;
  max-width: 20rem;
  margin: auto;
  box-shadow: 0px 4px 4px 0px #00000040;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: relative;

  &:hover {
    box-shadow: 0px 4px 4px 0px #00000000;
    outline: 1px solid ${({ theme }) => theme.darkerSub};
    background-color: ${({ theme }) => theme.darkSub};
  }
`;

const CardPlanInfoWrapper = styled(Flex)<{
  isSpecial?: boolean;
  showFreeTrial?: boolean;
  content?: string;
}>`
  padding: ${Spacing.l};
  aspect-ratio: 1 / 1;
  border-radius: ${Rounded.circle};
  width: 100%;
  background: radial-gradient(50% 50% at 50% 50%, #960baa 76.5%, #2b2694 100%);

  ${({ showFreeTrial, content, theme }) =>
    showFreeTrial
      ? `
  &::before {
    content: "${content}";
    text-align: center;
    color: white;
    position: absolute;
    top: 150px;
    font-size: 1rem;
    right: -20px;
    width: 15rem;
    height: 1.4rem;
    background-color: ${theme.main};
    transform-origin: top right;
    transform: rotate(45deg);
  }
  `
      : ``}
`;

const CardPlanInfoInnerWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: ${({ theme }) => theme.light};
`;

const CardTitle = styled(Text)`
  font-weight: 700;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.main};
  text-align: center;
`;

const Price = styled(Text)`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.textColor};
  font-weight: bold;
`;

const FeaturesWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.xl};
  width: 100%;
  margin-bottom: ${Spacing.l};
`;

const CardCallToActionWrapper = styled(Flex)`
  padding: ${Spacing.s};
  border-bottom: ${({ theme }) => `1px solid ${theme.textColor}`};
  width: 80%;
  justify-content: center;
  margin-bottom: ${Spacing.m};
`;

const CardCallToActionButton = styled(Button)`
  text-align: center;
  text-decoration: none;
  border-radius: ${Rounded.pill};
  color: ${({ theme }) => theme.lightTextColor};
  background: linear-gradient(0deg, #2b2694, #960baa);
  font-size: 1.2rem;
  transition: all 0.3s;
  padding: ${Spacing.s} ${Spacing.m};
  height: 2.5rem;
  min-width: 16rem;
  margin-top: auto;
  margin-bottom: ${Spacing.m};
  background: linear-gradient(0deg, #2b2694, #960baa);

  &:hover {
    box-shadow: 0px 4px 4px 0px #00000040;
  }
`;

const PerksList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.l};
  list-style-type: disc;
  padding-inline: ${Spacing.s};
  padding-bottom: ${Spacing.xl};
`;

type SignupFlow = {
  plan?: AtiraLinkPlanDefinition;
  modalVisible: boolean;
};

export const PublicPricingPlans: React.FC = () => {
  const [signupPaymentFlow, setSignupPaymentFlow] = useState<SignupFlow>({
    plan: undefined,
    modalVisible: false,
  });

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const loading = useAppSelector(paymentSliceSelectors.selectPlansLoading);
  const plans = useAppSelector(paymentSliceSelectors.selectPlans);

  const onPlanClick = (plan: AtiraLinkPlanDefinition) => {
    setSignupPaymentFlow({
      ...signupPaymentFlow,
      modalVisible: true,
      plan,
    });
  };

  useEffect(() => {
    dispatch(paymentActions.getAllPlans());
  }, [dispatch]);

  if (loading && !plans) {
    return <PlansLoading />;
  }

  return (
    <AtiraRow
      justify={'center'}
      gutter={[20, 20]}
      style={{
        width: '100%',
        transition: 'all 0.2s',
      }}
    >
      {plans?.map?.((plan, index) => (
        <AtiraColumn key={plan?._id} xs={24} sm={12} md={12} lg={12} xl={8}>
          <Card>
            <CardPlanInfoWrapper isSpecial={index === 1}>
              <CardPlanInfoInnerWrapper>
                <Price>
                  {toUSD(plan.lemonData.price || 0)}
                  <sup style={{ fontSize: '0.75rem' }}>$ </sup>
                </Price>

                <Text fontSize="l">{t('common.per_year')}</Text>
              </CardPlanInfoInnerWrapper>
            </CardPlanInfoWrapper>

            <FeaturesWrapper>
              <CardTitle as={'h3'}>{t(getPlanTitle(plan.type))}</CardTitle>

              <Flex
                flexDirection="column"
                justifyContent="space-evenly"
                alignItems="center"
                flex={1}
                gap="m"
              >
                <CardCallToActionWrapper>
                  <CardCallToActionButton
                    style={{ height: '2.5rem' }}
                    onClick={() => onPlanClick(plan)}
                  >
                    {plan.type === AtiraLinkPlanType.PLAN_0
                      ? t('subscription.plan.button.freeTrial')
                      : t('subscription.plan.button.subscribe')}
                  </CardCallToActionButton>
                </CardCallToActionWrapper>

                <PerksList>
                  {Object.entries(plan.perks)?.map(([key, value], i) => (
                    <li key={key}>
                      <Text fontSize="xm">
                        {/* @ts-ignore */}
                        {t(translatePerks(key, value))}
                      </Text>
                      {key === 'index' ? (
                        <Tooltip
                          title={
                            <span>
                              {t('plan.index.info')}
                              <a
                                href="https://index.atiralink.com"
                                target="_blank"
                                rel="noreferrer"
                              >
                                https://index.atiralink.com
                              </a>
                            </span>
                          }
                        >
                          <span></span>
                          <AtiraIcon icon={faInfoCircle} color="main" />
                        </Tooltip>
                      ) : null}
                    </li>
                  ))}
                </PerksList>
              </Flex>
            </FeaturesWrapper>
          </Card>
        </AtiraColumn>
      ))}

      <SignupFlowModal
        signupFlow={signupPaymentFlow}
        setSignupPaymentFlow={setSignupPaymentFlow}
      />
    </AtiraRow>
  );
};
