import { Checkbox } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { GoogleButton } from '../../../components/logins/GoogleButton';
import i18n, { AppLangs } from '../../../i18n';
import { SignupDto } from '../../../model/user/dto/SignupDto';
import { LoginWithGoogleReturnPath } from '../../../model/user/types/LoginWithGoogleReturnPath.enum';
import { useAppDispatch } from '../../../redux/store';
import { userActions } from '../../../redux/user/user.slice';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { Regexes } from '../../../utils/String';

const Container = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: ${Spacing.s};
`;

const StyledInput = styled(Input)`
  height: 2.5rem;
  border: 1px solid ${(props) => props.theme.lightergray};
  padding: ${Spacing.s};
  transition: border 0.2s;
  font-size: 1rem;
  border-radius: ${Rounded.lg};

  &:focus {
    border: ${({ theme }) => `1px solid ${theme.main} !important`};
  }
`;

const StyledButton = styled(Button)`
  height: 3rem;
  width: 100%;
  background-color: ${({ theme }) => theme.main};
  font-size: 1rem;
  transition: background-color 0.2s;
  margin: 0;
  border-radius: ${Rounded.lg};

  &:hover {
    background-color: ${(props) => props.theme.lightMain};
  }
`;

const StyledHr = styled.hr`
  background-color: ${(props) => props.theme.darkerSub};
  height: 1px;
  border: 0;
  opacity: 1;
  width: 100%;
`;

const SignInLink = styled(Link)`
  text-decoration: none;
  background-color: ${(props) => props.theme.transparent};
  color: ${(props) => props.theme.main};
  font-size: 1rem;
`;

const PrivacyTermsLink = styled(Link)`
  font-weight: bold;
  text-decoration: underline;
  color: ${({ theme }) => theme.black};
  font-size: 0.9rem;
`;

const WelcomeMessage = styled(Text)`
  color: ${({ theme }) => theme.main};
  font-weight: 600;
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: ${Spacing.m};
  border-bottom: ${({ theme }) => `1px solid ${theme.lightergray}`};
  padding: ${Spacing.s} 0;
  width: 100%;
  white-space: nowrap;
`;

const InputsWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};

  @media (min-width: ${Breakpoints.TABLET}) {
    padding: 0 ${Spacing.m};
  }
`;

const RequiredStar = styled.span`
  color: ${({ theme }) => theme.danger};
`;

type SignupEmailProps = {
  updateStep: VoidFunction;
};

export const SignupEmail: React.FC<SignupEmailProps> = ({ updateStep }) => {
  const [agreed, setAgreed] = useState(false);
  const { t } = useTranslation();

  const [signupLoading, setSignupLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { control, handleSubmit, getValues } = useForm<SignupDto>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
    },
  });

  const onNext = async () => {
    try {
      setSignupLoading(true);
      await dispatch(userActions.signup(getValues())).unwrap();
      AtiraToast.success(t('signup.verification_sent_message'));
      updateStep();
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setSignupLoading(false);
    }
  };

  return (
    <Container>
      <WelcomeMessage>{t('register.welcome_message')}</WelcomeMessage>

      {/* TODO Note that any change in those inputs below should be reflected in SignupFlowModal.tsx */}
      <InputsWrapper>
        <Controller
          name="name"
          rules={{
            required: true,
            minLength: {
              value: 3,
              message: t('signup.error.user_name.short_message'),
            },
            maxLength: {
              value: 18,
              message: t('signup.error.user_name.long_message'),
            },
            pattern: {
              value: Regexes.NAME_REGEX,
              message: t('signup.error.user_name.invalid_characters'),
            },
          }}
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <StyledInput
              title={t('common.name')}
              type="name"
              value={value}
              placeholder={t('common.name')}
              onChange={onChange}
              id="name"
              valid={isEmpty(errors.name)}
              errorMessage={errors.name?.message || t('signup.error.name')}
              required
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          rules={{
            required: true,
            pattern: Regexes.EMAIL_REGEX,
          }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <StyledInput
              title={t('common.email')}
              type="email"
              value={value}
              placeholder={t('common.email')}
              onChange={onChange}
              id="email"
              valid={isEmpty(errors.email)}
              errorMessage={errors.email?.message || t('signup.error.email')}
              required
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <StyledInput
              title={t('common.password')}
              placeholder={t('common.password')}
              type="password"
              value={value}
              onChange={onChange}
              id="password"
              valid={isEmpty(errors.password)}
              required
              errorMessage={
                errors.password?.message || t('signup.error.password')
              }
            />
          )}
        />

        <Flex gap="s">
          <Checkbox
            value={agreed}
            onChange={() => setAgreed((prev) => !prev)}
          />

          <Text align="center">
            <Trans
              i18nKey={'signup.terms_privacy.agree'}
              components={[
                <PrivacyTermsLink to={''} />,
                <PrivacyTermsLink to={''} />,
                <RequiredStar />,
              ]}
            />
          </Text>
        </Flex>

        <Flex flexDirection="column" gap="s" width={'100%'} marginTop="m">
          <StyledButton
            disabled={!agreed}
            loading={signupLoading}
            onClick={handleSubmit(onNext, (e) => {
              console.log('invalid form submission', e);
            })}
            title={t('common.signup')}
          />

          <GoogleButton returnPath={LoginWithGoogleReturnPath.SIGNUP}>
            {t('sign_up_with_google')}
          </GoogleButton>
          <StyledHr />

          <Flex gap="m" alignItems="center">
            <Text>{t('sign_in.has_account')}</Text>

            <SignInLink to={'/login'}>{t('common.login')}</SignInLink>
          </Flex>
        </Flex>
      </InputsWrapper>
    </Container>
  );
};
