import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraTabs } from '../../components/AtiraTabs';
import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import { UserHeader } from '../../components/UserHeader';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import i18n, { AppLangs } from '../../i18n';
import { CreateAtiraLinkDto } from '../../model/atira-link/dto/CreateAtiraLinkDto';
import { AtiraLinkThemes } from '../../model/atira-link/types/AtiraLinkThemes.enum';
import { AtiraLinkSettings } from '../../model/settings/types/AtiraLinkSettings';
import { atiraLinkSliceSelectors } from '../../redux/atira-link/atira-link.selector';
import { useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Breakpoints } from '../../theme/Breakpoints';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';
import { AtiraLinkPreviewPhone } from './components/AtiraLinkPreviewPhone';
import { AtiraLinkContentTab } from './tabs/AtiraLinkContentTab';
import { AtiraLinkTemplatesTab } from './tabs/AtiraLinkTemplatesTab';

const HeaderPhoneWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};
  align-items: flex-end;
  position: sticky;
  top: 0;
  height: min-content;
  width: 0;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    padding-right: ${i18n.language === AppLangs.AR ? '0' : Spacing.m};
    padding-left: ${i18n.language === AppLangs.AR ? Spacing.m : '0'};
    margin-top: ${Spacing.s};
    width: 100%;
    flex: 1;
  }
`;

const HeaderWrapper = styled(Flex)`
  display: none;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: flex;
  }
`;

const TabsWrapper = styled(Flex)`
  width: 100%;

  .ant-tabs-nav {
    flex-direction: column;
  }

  .ant-tabs-nav-wrap {
    width: 100%;
  }

  .ant-tabs-extra-content {
    align-self: flex-start;
  }

  .ant-tabs-extra-content > div {
    width: 15rem;
  }

  .ant-select-selector {
    border-radius: ${Rounded.lg};
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: 80%;
    padding-bottom: 0;

    .ant-tabs-nav {
      flex-direction: row;
    }

    .ant-tabs-nav-wrap {
      width: fit-content;
    }

    .ant-tabs-extra-content {
      height: calc(100% - ${Spacing.xl});
      align-self: center;
    }

    .ant-tabs-extra-content > div {
      height: 100%;
    }
  }
`;

export const AtiraLinkRoute: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  useDocumentTitle(
    `${t('app.name').toUpperCase()} - ${t('side_menu.my_link')}`,
  );

  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser)!;
  const selectedThemeFromTemplates = localStorage.getItem(
    'selectedThemeFromTemplates',
  );
  const selectedAtiraLink = useAppSelector(
    atiraLinkSliceSelectors.selectSelectedAtiraLink,
  );

  const formMethods = useForm<CreateAtiraLinkDto>();

  useEffect(() => {
    formMethods.reset({
      title: selectedAtiraLink?.title || '',
      email: selectedAtiraLink?.email || '',
      phone: selectedAtiraLink?.phone || '',
      userId: loggedInUser._id,
      theme:
        (selectedThemeFromTemplates as AtiraLinkThemes) ||
        selectedAtiraLink?.theme ||
        AtiraLinkThemes.BASIC,
      linkName: selectedAtiraLink?.linkName || '',
      bio: selectedAtiraLink?.bio || '',
      customLinks: selectedAtiraLink?.customLinks || [],
      image: selectedAtiraLink?.image || '',
      socials: selectedAtiraLink?.socials || [],
      map: selectedAtiraLink?.map || '',
      website: selectedAtiraLink?.website || '',
      embed: selectedAtiraLink?.embed || '',
      settings: selectedAtiraLink?.settings || ({} as AtiraLinkSettings),
    });
  }, [
    formMethods.reset,
    selectedAtiraLink,
    loggedInUser._id,
    selectedThemeFromTemplates,
    formMethods,
  ]);

  return (
    <Flex flex={1} position="relative">
      <FormProvider {...formMethods}>
        <TabsWrapper>
          <AtiraTabs
            items={[
              {
                key: 'content',
                label: t('common.content'),
                children: <AtiraLinkContentTab />,
              },
              {
                key: 'templates',
                label: t('common.templates'),
                children: <AtiraLinkTemplatesTab />,
              },
              {
                key: 'design',
                label: t('common.design'),
                children: (
                  <Text color="textColor" fontSize="xl">
                    {t('common.feature.coming_soon')}
                  </Text>
                ),
              },
            ]}
          />
        </TabsWrapper>

        <HeaderPhoneWrapper>
          <HeaderWrapper>
            <UserHeader />
          </HeaderWrapper>

          <AtiraLinkPreviewPhone
            values={formMethods.watch()}
            loading={loading}
            setLoading={setLoading}
            showActions
          />
        </HeaderPhoneWrapper>
      </FormProvider>
    </Flex>
  );
};
