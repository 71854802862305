import { faCrown } from '@fortawesome/free-solid-svg-icons/faCrown';
import { Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { UpgradeButton } from '../../../components/UpgradeButton';
import { EditAtiraLinkDto } from '../../../model/atira-link/dto/EditAtiraLinkDto';
import { AtiraLinkPlanType } from '../../../model/plan/types/AtiraLinkPlanType.enum';
import { usePlansContext } from '../../../plans-context';
import { atiraLinkSliceSelectors } from '../../../redux/atira-link/atira-link.selector';
import { AtiraLinkActions } from '../../../redux/atira-link/atira-link.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';

const Container = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  width: 100%;
`;

const SectionTitle = styled(Text)`
  color: ${({ theme }) => theme.textColor};
  font-weight: bold;
  font-size: 1.3rem;
  height: 2rem;
`;

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.s};
`;

const StyledButton = styled(Button)`
  height: 2.3rem;
  width: 9rem;
  margin-top: ${Spacing.l};
`;

export const SettingsGeneral: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { setPlansModalVisible } = usePlansContext();

  const selectedLink = useAppSelector(
    atiraLinkSliceSelectors.selectSelectedAtiraLink,
  );
  const user = useAppSelector(userSliceSelectors.selectLoggedInUser);

  const canHideBrand = user?.plan?.type !== AtiraLinkPlanType.PLAN_0;

  const { control, reset, getValues, handleSubmit } =
    useForm<EditAtiraLinkDto>();

  const onSave = async () => {
    try {
      setLoading(true);

      const dto = getValues();

      await dispatch(AtiraLinkActions.editAtiraLink(dto)).unwrap();
      await dispatch(
        AtiraLinkActions.getUserAtiraLinks({ userId: user?._id! }),
      ).unwrap();

      AtiraToast.success(t('settings.general.save.success'));
    } catch (e) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reset({
      atiraLinkId: selectedLink?._id,
      userId: user?._id,
      settings: {
        brand: {
          hidden: selectedLink?.settings?.brand?.hidden || false,
        },
      },
    });
  }, [reset, user?._id, selectedLink]);

  return (
    <Container>
      <SectionTitle>{t('settings.general.brand.title')}</SectionTitle>

      <Section>
        <Text color="subTextColor">
          {t('settings.general.brand.description')}
        </Text>

        <Controller
          control={control}
          name="settings.brand.hidden"
          render={({ field: { value, onChange } }) => (
            <Flex alignItems="center" gap="m">
              <Text>{t('common.show')}</Text>

              <Switch
                checked={!value}
                onChange={(v) =>
                  canHideBrand ? onChange(!v) : setPlansModalVisible(true)
                }
              />

              {!canHideBrand ? <UpgradeButton asIcon /> : null}
            </Flex>
          )}
        />

        <StyledButton onClick={handleSubmit(onSave)} loading={loading}>
          {t('common.save')}
        </StyledButton>
      </Section>
    </Container>
  );
};
