import { faCrown } from '@fortawesome/free-solid-svg-icons/faCrown';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../components/Button';
import { CopyButton } from '../../components/CopyButton';
import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { Text } from '../../components/Text';
import { usePlansContext } from '../../plans-context';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { urlSliceSelectors } from '../../redux/url/url.selector';
import { shortenUrlActions } from '../../redux/url/url.slice';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';
import { AtiraToast } from '../../utils/AtiraToast';

const StyledButton = styled(Button)`
  font-size: 1rem;
  height: 4rem;
  width: 100%;

  @media (min-width: ${Breakpoints.TABLET}) {
    width: 8rem;
    font-size: 1.3rem;
  }
`;

const InputButtonWrapper = styled(Flex)`
  align-items: center;
  flex-wrap: wrap;
  gap: ${Spacing.l};
  max-width: 32rem;

  @media (min-width: ${Breakpoints.TABLET}) {
    flex-wrap: nowrap;
  }
`;

const ResultWrapper = styled(Flex)`
  max-width: 32rem;
  border: 2px solid ${({ theme }) => theme.textColor};
  gap: ${Spacing.l};
  padding: ${Spacing.m};
  flex-wrap: wrap;

  & > div:nth-child(2) {
    border-top: 2px solid ${({ theme }) => theme.textColor};
    padding-top: ${Spacing.s};
  }

  @media (min-width: ${Breakpoints.TABLET}) {
    flex-wrap: nowrap;

    & > div:nth-child(2) {
      border-top: 0;
      padding-top: 0;
    }

    & > div:nth-child(2) {
      border-inline-start: 2px solid ${({ theme }) => theme.textColor};
      padding-inline-start: ${Spacing.s};
    }
  }
`;

const ResultPart = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: ${Spacing.m};

  @media (min-width: ${Breakpoints.TABLET}) {
    width: 50%;
    justify-content: flex-start;
  }
`;

const StyledInput = styled(Input)<{ isValid: boolean }>`
  height: 4rem;
  font-size: 1.2rem;
  border: ${({ theme, isValid }) =>
    `2px solid ${isValid ? theme.textColor : theme.danger}`};
  padding: ${Spacing.m};
`;

export const URLShortner = () => {
  const { t } = useTranslation();

  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser)!;
  const userURLsLength = useAppSelector((s) =>
    urlSliceSelectors.selectShortURLs(s, loggedInUser._id),
  ).length;

  const [url, setUrl] = useState('');
  const [currentShortURL, setCurrentShortURL] = useState('');
  const [currentOriginalURL, setCurrentOriginalURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [reachedLimit, setReachedLimit] = useState(
    Number(loggedInUser.plan?.perks?.urlsCount) <= userURLsLength,
  );

  const { setPlansModalVisible } = usePlansContext();

  const dispatch = useAppDispatch();
  const [isUrlValid, setIsUrlValid] = useState(true);

  const fetchShortURL = async () => {
    try {
      setLoading(true);

      if (!url) {
        setIsUrlValid(false);
        AtiraToast.error(t('shorten_url.create.error.enter_url'));
        return;
      }

      setIsUrlValid(true);

      const response = await dispatch(
        shortenUrlActions.getShortURL({
          userId: loggedInUser._id!,
          originalURL: url,
        }),
      ).unwrap();

      setCurrentOriginalURL(response.url.originalURL);
      setCurrentShortURL(response.url.shortURL!);

      await dispatch(
        shortenUrlActions.getUserShortURLs({ userId: loggedInUser._id }),
      ).unwrap();
      AtiraToast.success(t('shorten_url.create.success'));
      setCurrentShortURL(response.url.shortURL!);
      setUrl('');
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const shouldUpgrade = () => {
      const URLCountLimit = Number(loggedInUser.plan?.perks?.urlsCount);

      if (URLCountLimit <= userURLsLength) {
        setReachedLimit(true);
        return;
      }

      setReachedLimit(false);
    };

    shouldUpgrade();
  }, [userURLsLength, loggedInUser.plan?.perks?.urlsCount]);

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap="xxl"
    >
      <Text align="center" fontSize="xl">
        {t('shorten_url.title')}
      </Text>
      <Text align="center" fontSize="m" color="subTextColor">
        {t('shorten_url.description')}
      </Text>

      <InputButtonWrapper>
        <StyledInput
          value={url}
          isValid={isUrlValid}
          placeholder={t('shorten_url.input_placeholder')}
          onChange={(e) => {
            setUrl(e.currentTarget.value);
            setIsUrlValid(true);
          }}
        />

        <StyledButton
          onClick={
            !reachedLimit ? fetchShortURL : () => setPlansModalVisible(true)
          }
          loading={loading}
          icon={reachedLimit ? faCrown : undefined}
        >
          {!reachedLimit ? t('shorten_url.button_text') : t('common.upgrade')}
        </StyledButton>
      </InputButtonWrapper>

      {currentShortURL ? (
        <ResultWrapper>
          <ResultPart>
            <Text fontSize="l">{t('shorten_url.original_url')}</Text>
            <Text wordBreak="break-all">{currentOriginalURL}</Text>
          </ResultPart>

          <ResultPart>
            <Text fontSize="l">{t('shorten_url.response.new_url')}</Text>

            <Flex
              width={'100%'}
              gap="m"
              flexWrap="wrap"
              justifyContent="center"
            >
              <Text wordBreak="break-all">{currentShortURL}</Text>
              <CopyButton copy={currentShortURL} />
            </Flex>
          </ResultPart>
        </ResultWrapper>
      ) : null}
    </Flex>
  );
};
