import React from 'react';
import { useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { AtiraColumn } from '../../../components/AtiraColumn';
import { AtiraRow } from '../../../components/AtiraRow';
import { Flex } from '../../../components/Flex';
import i18n, { AppLangs } from '../../../i18n';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';

const animation = keyframes`
 0% {
  background-position-x: 0;
 } 100% {
  background-position-x: 100%;
 }
`;

const Card = styled(Flex)<{ isViewedInternally: boolean }>`
  border-radius: ${Rounded.pill};
  flex-direction: column;
  align-items: center;
  gap: ${Spacing.m};
  background-color: ${({ theme }) => theme.light};
  padding: ${Spacing.m};
  padding-bottom: ${Spacing.l};
  height: 110vh;
  max-width: 20rem;
  margin: auto;
  box-shadow: 0px 4px 4px 0px #00000040;
  transition: all 0.3s ease-in-out;
  background-image: ${({ theme, isViewedInternally }) => `
    linear-gradient(-45deg, ${isViewedInternally ? theme.light : theme.darkSub} 40%, ${theme.sub} 50%, ${isViewedInternally ? theme.light : theme.darkSub} 60%)
  `};
  background-size: 300%;
  background-position-x: 100%;
  animation: ${animation} 1s infinite linear;
`;

const CardPlanInfoWrapper = styled(Flex)<{ isSpecial?: boolean }>`
  padding: ${Spacing.l};
  aspect-ratio: 1 / 1;
  border-radius: ${Rounded.circle};
  width: 100%;
  background: radial-gradient(50% 50% at 50% 50%, #960baa 76.5%, #2b2694 100%);
`;

const CardPlanInfoInnerWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: ${({ theme }) => theme.light};
`;

const StyledColumn = styled(AtiraColumn)<{ isViewedInternally: boolean }>`
  ${({ isViewedInternally }) =>
    isViewedInternally
      ? `
  padding-left: 0 !important;
  padding-right: 0 !important;
  
  @media (min-width: ${Breakpoints.TABLET}) {
    &:first-child {
      padding-left: ${i18n.language === AppLangs.AR ? '10px' : '0'} !important;
      padding-right: ${i18n.language === AppLangs.AR ? '0' : '10px'} !important;
    }

    &:nth-child(2) {
      padding-right: ${i18n.language === AppLangs.AR ? '10px' : '0'} !important;
      padding-left: ${i18n.language === AppLangs.AR ? '0' : '10px'} !important;
    }
  }

  @media (min-width: ${Breakpoints.LARGE_DESKTOP}) {
    &:nth-child(2) {
      padding-left: ${i18n.language === AppLangs.AR ? '10px' : '0'} !important;
      padding-right: ${i18n.language === AppLangs.AR ? '0' : '10px'} !important;
    }
  }
`
      : ''}
`;

export const PlansLoading: React.FC = () => {
  const location = useLocation();

  const isViewedInternally = location.pathname.includes('/subscription');

  return (
    <AtiraRow
      justify={'space-around'}
      gutter={[20, 12]}
      style={{ transition: 'all 0.2s' }}
    >
      {[...Array(3)].map((_, index) => (
        <StyledColumn
          isViewedInternally={isViewedInternally}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          key={index}
        >
          <Card isViewedInternally={isViewedInternally}>
            <CardPlanInfoWrapper isSpecial={index === 1}>
              <CardPlanInfoInnerWrapper />
            </CardPlanInfoWrapper>
          </Card>
        </StyledColumn>
      ))}
    </AtiraRow>
  );
};
