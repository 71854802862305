import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../../../assets';
import { AtiraColumn } from '../../../components/AtiraColumn';
import { AtiraRow } from '../../../components/AtiraRow';
import { Flex } from '../../../components/Flex';
import { AtiraImage } from '../../../components/Image';
import { Text } from '../../../components/Text';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';

const features = (t: TFunction) => [
  {
    image: Images.LandingPageSecondSection1,
    title: t('landingPage.section.1.feature.1.title'),
    description: t('landingPage.section.1.feature.1.description'),
  },
  {
    image: Images.LandingPageSecondSection2,
    title: t('landingPage.section.1.feature.2.title'),
    description: t('landingPage.section.1.feature.2.description'),
  },
  {
    image: Images.LandingPageSecondSection3,
    title: t('landingPage.section.1.feature.3.title'),
    description: t('landingPage.section.1.feature.3.description'),
  },
];

const Container = styled.section`
  padding: ${Spacing.xxl} ${Spacing.s};
  background: linear-gradient(90deg, #2b2694 0%, #960baa 100%);
  padding-bottom: ${Spacing.xxl};
`;

const TitleDescriptionWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.l};
`;

const FeatureContainer = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
  }
`;

const FeatuerImageWrapper = styled(Flex)`
  max-width: 10rem;
`;

const FeaturesRow = styled(AtiraRow)`
  justify-content: center;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    justify-content: space-between;
  }
`;

const CallToActionRow = styled(AtiraRow)`
  padding-top: ${Spacing.m} !important;

  @media (min-width: ${Breakpoints.TABLET}) {
    padding-top: ${Spacing.xxl} !important;
  }
`;

const CallToAction = styled(Link)`
  text-decoration: none;
  transition: all 0.3s;
  color: ${(props) => props.theme.main};
  background-color: ${(props) => props.theme.light};
  padding: 0.75rem 1rem;
  width: 100%;
  border-radius: ${Rounded.md};
  text-align: center;
  margin: 0 auto;
  display: block;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.25rem;
    width: fit-content;
  }

  &:hover {
    background-color: ${({ theme }) => theme.darkSub};
    color: ${({ theme }) => theme.textColor};
  }
`;

export const LandingPageFeaturesSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <AtiraRow justify={'center'}>
        <AtiraColumn xs={24} sm={24} md={22} lg={20} xl={20}>
          <AtiraRow gutter={[0, 60]}>
            <AtiraColumn xs={24} sm={24} md={24} lg={24} xl={24}>
              <TitleDescriptionWrapper>
                <Text
                  color="sub"
                  fontSize="xl"
                  fontWeight={'bold'}
                  align="center"
                  as={'h2'}
                >
                  {t('landingPage.section.1.title')}
                </Text>

                <Text color="sub" fontSize="l" align="center">
                  {t('landingPage.section.1.paragraph')}
                </Text>
              </TitleDescriptionWrapper>
            </AtiraColumn>

            <FeaturesRow gutter={[20, 40]}>
              {features(t).map((f) => (
                <AtiraColumn
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xl={6}
                  key={f.title}
                >
                  <FeatureContainer>
                    <FeatuerImageWrapper>
                      <AtiraImage src={f.image} />
                    </FeatuerImageWrapper>

                    <Text
                      fontSize="l"
                      fontWeight={'bold'}
                      color="sub"
                      as={'h3'}
                    >
                      {f.title}
                    </Text>

                    <Text color="sub" fontSize="xm">
                      {f.description}
                    </Text>
                  </FeatureContainer>
                </AtiraColumn>
              ))}
            </FeaturesRow>
          </AtiraRow>

          <CallToActionRow>
            <AtiraColumn xs={24} sm={24} md={24} lg={24} xl={24}>
              <CallToAction to={'/signup'}>
                {t('common.signupForFreeNow')}
              </CallToAction>
            </AtiraColumn>
          </CallToActionRow>
        </AtiraColumn>
      </AtiraRow>
    </Container>
  );
};
