export enum AtiraLinkThemes {
  BASIC = 'BASIC',
  URANUS = 'URANUS',
  HOTH = 'HOTH',
  EGO = 'EGO',
  EARTH = 'EARTH',
  MERCURY = 'MERCURY',
  JUPITER = 'JUPITER',
  MARS = 'MARS',
  NEPTUNE = 'NEPTUNE',
  SATURN = 'SATURN',
  THERA = 'THERA',
  MOON = 'MOON',
  GLIESE = 'GLIESE',
  KEPLER = 'KEPLER',
  VIOLA = 'VIOLA',
  CERULEAN = 'CERULEAN',
  ENCELADUS = 'ENCELADUS',
  DIMIDUM = 'DIMIDUM',
  OSIRIS = 'OSIRIS',
  CANCRI = 'CANCRI',
  EUROPA = 'EUROPA',
  VULCANO = 'VULCANO',
  CALLISTO = 'CALLISTO',
  AZURE = 'AZURE',
  CERES = 'CERES',
  MIMAS = 'MIMAS',
  PROXIMA = 'PROXIMA',
  PLUTO = 'PLUTO',
  HAUMEA = 'HAUMEA',
  PHOBOS = 'PHOBOS',
  GENYMADE = 'GENYMADE',
  ERIS = 'ERIS',
  TRITON = 'TRITON',
  OBERON = 'OBERON',
  HYPERION = 'HYPERION',
  VESTA = 'VESTA',
  SIRIUS = 'SIRIUS',
  ARIEL = 'ARIEL',
  ONYX = 'ONYX',
  ALCYONE = 'ALCYONE',
  COBALT = 'COBALT',
  SOLSTICE = 'SOLSTICE',
  AQUARIUS = 'AQUARIUS',
  ZENITH = 'ZENITH',
  ABYSS = 'ABYSS',
  FUCHSIA = 'FUCHSIA',
  CRIMSON = 'CRIMSON',
  LYRA = 'LYRA',
  NOVA = 'NOVA',
  ORION = 'ORION',
  SYLVA = 'SYLVA',
  VORTEX = 'VORTEX',
}
