import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Swiper as SwiperClass } from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { AtiraColumn } from '../../../components/AtiraColumn';
import { AtiraRow } from '../../../components/AtiraRow';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { SpinnerFullScreen } from '../../../components/SpinnerFullScreen';
import { Text } from '../../../components/Text';
import i18n, { AppLangs } from '../../../i18n';
import { atiraLinkIndexSliceSelectors } from '../../../redux/atira-link/atira-link-index.selector';
import { AtiraLinkIndexActions } from '../../../redux/atira-link/atira-link-index.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { LinkCard } from '../components/LinkCard';

const Container = styled.section`
  padding-bottom: ${Spacing.m};
  padding-inline: ${Spacing.s};
  background: linear-gradient(90deg, #2b2694 0%, #960baa 100%);
  padding-top: ${Spacing.xxl};

  @media (min-width: ${Breakpoints.TABLET}) {
    padding-bottom: ${Spacing.xl};
  }
`;

const ContentWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${Spacing.xl};
`;

const CallToAction = styled.a`
  text-decoration: none;
  transition: all 0.3s;
  color: ${(props) => props.theme.main};
  background-color: ${(props) => props.theme.light};
  padding: 0.75rem 1rem;
  width: fit-content;
  border-radius: ${Rounded.md};
  text-align: center;
  margin: 0 auto;
  display: block;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.25rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.darkSub};
    color: ${({ theme }) => theme.textColor};
  }
`;

const StyledSwiper = styled(Swiper)`
  width: 100%;
`;

const SwiperControlButton = styled(Button)`
  padding: 0;
  margin: 0;
  background-color: transparent;
  width: 2rem;
  aspect-ratio: 1 / 1;
`;

export const LandingPageLatestUsers: React.FC = () => {
  const [isPrevDisabled, setIsPrevDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const swiperRef = useRef<SwiperClass | null>(null);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const latestUsers = useAppSelector(
    atiraLinkIndexSliceSelectors.selectLatestUsers,
  );
  const loading = useAppSelector(
    atiraLinkIndexSliceSelectors.selectLatestUsersLoading,
  );

  const updateNavButtons = (swiper: SwiperClass) => {
    setIsPrevDisabled(!swiper?.slides || swiper.isBeginning);
    setIsNextDisabled(!swiper?.slides || swiper.isEnd);
  };

  useEffect(() => {
    dispatch(AtiraLinkIndexActions.getLatestUsers());
  }, [dispatch]);

  return (
    <Container>
      <AtiraRow justify={'center'}>
        <AtiraColumn xs={24} sm={24} md={22} lg={20} xl={20}>
          <ContentWrapper>
            <Text
              fontSize="xl"
              fontWeight={'bold'}
              align="center"
              color="sub"
              as={'h2'}
            >
              {t('landingPage.section.2.title')}
            </Text>

            {!loading ? (
              <StyledSwiper
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                  updateNavButtons(swiper);

                  swiper.on('slideChange', () => updateNavButtons(swiper));
                  swiper.on('reachBeginning', () => setIsPrevDisabled(true));
                  swiper.on('reachEnd', () => setIsNextDisabled(true));
                  swiper.on('fromEdge', () => updateNavButtons(swiper));
                }}
                modules={[Autoplay, Navigation]}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                spaceBetween={10}
                slidesPerView={1}
                breakpoints={{
                  570: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  767: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  1200: { slidesPerView: 4, spaceBetween: 40 },
                }}
              >
                {latestUsers?.map((link) => (
                  <SwiperSlide key={link._id}>
                    <LinkCard link={link} key={link._id} />
                  </SwiperSlide>
                ))}
              </StyledSwiper>
            ) : (
              <SpinnerFullScreen color="sub" />
            )}

            <Flex alignItems="center" gap="s">
              <SwiperControlButton
                icon={
                  i18n.language === AppLangs.AR ? faAngleRight : faAngleLeft
                }
                onClick={() => swiperRef.current?.slidePrev()}
                disabled={isPrevDisabled}
              />

              <CallToAction href={'http://index.atiralink.com'} target="_blank">
                {t('landingPage.section.2.c2a')}
              </CallToAction>

              <SwiperControlButton
                icon={
                  i18n.language === AppLangs.AR ? faAngleLeft : faAngleRight
                }
                onClick={() => swiperRef.current?.slideNext()}
                disabled={isNextDisabled}
              />
            </Flex>
          </ContentWrapper>
        </AtiraColumn>
      </AtiraRow>
    </Container>
  );
};
