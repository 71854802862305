import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { AtiraTable } from '../../../components/AtiraTable';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { WarningModal } from '../../../components/WarningModal';
import { AtiraLinkCategory } from '../../../model/atira-link/AtiraLinkCategory';
import { atiraLinkIndexSliceSelectors } from '../../../redux/atira-link/atira-link-index.selector';
import { useAppSelector } from '../../../redux/store';
import { AdminCreateCategoryModal } from '../components/AdminCreateCategoryModal';
import { AdminEditCategoryModal } from '../components/AdminEditCategoryModal';
import { createAdminIndexTableColumns } from '../components/createAdminIndexTableColumns';

export const AdminIndexTab: React.FC = () => {
  const { t } = useTranslation();

  const [deleteIndexVisible, setDeleteIndexVisible] = useState(false);
  const [editIndexModalVisible, setEditIndexModalVisible] = useState(false);
  const [createIndexModalVisible, setCreateIndexModalVisible] = useState(false);
  const [category, setCategory] = useState<AtiraLinkCategory | null>(null);

  const indexes = useAppSelector(
    atiraLinkIndexSliceSelectors.selectAllCategoriesFlat,
  );

  const onEditCategory = (category: AtiraLinkCategory) => {
    setCategory(category);
    setEditIndexModalVisible(true);
  };

  const columns = createAdminIndexTableColumns(indexes, {
    t,
    delete: () => setDeleteIndexVisible(true),
    edit: onEditCategory,
  });

  return (
    <Flex flexDirection="column" gap="s" paddingBottom="m">
      <Flex justifyContent="flex-end">
        <Button onClick={() => setCreateIndexModalVisible(true)}>
          <Flex alignItems="center" gap="s">
            {t('common.add')}
            <AtiraIcon icon={faPlus} />
          </Flex>
        </Button>
      </Flex>

      <AtiraTable<AtiraLinkCategory>
        key={JSON.stringify(columns)}
        columns={columns}
        data={indexes}
        rowSelection={undefined}
        size="small"
        scroll={{ x: 'max-content' }}
        pagination={false}
      />

      <WarningModal
        open={deleteIndexVisible}
        onClose={() => setDeleteIndexVisible(false)}
        title={t('admin.index.delete_modal')}
        description={t('admin.index.delete_modal_description')}
      />

      <AdminEditCategoryModal
        category={category}
        open={editIndexModalVisible}
        onClose={() => setEditIndexModalVisible(false)}
      />

      <AdminCreateCategoryModal
        open={createIndexModalVisible}
        onClose={() => setCreateIndexModalVisible(false)}
      />
    </Flex>
  );
};
