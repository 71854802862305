import { faCircleUser } from '@fortawesome/free-solid-svg-icons/faCircleUser';
import isString from 'lodash/isString';
import React from 'react';
import styled from 'styled-components';

import { AtiraIcon } from '../../../../components/AtiraIcon';
import { AtiraLink } from '../../../../model/atira-link/AtiraLink';
import { AtiraLinkLanguageStrings } from '../../../../model/atira-link/AtiraLinkLangStrings';
import { CreateAtiraLinkDto } from '../../../../model/atira-link/dto/CreateAtiraLinkDto';
import { EditAtiraLinkDto } from '../../../../model/atira-link/dto/EditAtiraLinkDto';
import { getMediaUrl } from '../../../../utils/Media';
import { TemplatePreviewMode } from '../../../shared/model/TemplatePreviewMode.enum';

const PhotoWrapper = styled.div`
  svg {
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.h1`
  word-break: break-all;
`;

const Bio = styled.p`
  word-break: break-all;
`;

type AtiraLinkLeftSideProps = {
  atiraLink?:
    | AtiraLink
    | CreateAtiraLinkDto
    | EditAtiraLinkDto
    | Partial<AtiraLink>;
  linkLanguageStrings: AtiraLinkLanguageStrings;
  previewMode?: TemplatePreviewMode;
};

export const AtiraLinkLeftSide: React.FC<AtiraLinkLeftSideProps> = ({
  atiraLink,
  linkLanguageStrings,
  previewMode = TemplatePreviewMode.MOBILE,
}) => {
  return (
    <div
      className={`atira-card ${previewMode === TemplatePreviewMode.DESKTOP ? 'p-4' : 'p-2'} d-flex flex-column gap-3 align-items-center h-100`}
    >
      <div className="user-pfp overflow-hidden">
        {atiraLink?.image ? (
          <img
            src={
              isString(atiraLink.image)
                ? getMediaUrl(atiraLink.image!)
                : URL.createObjectURL(atiraLink.image! as any)
            }
            alt={`${atiraLink?.title}-image`}
            className="w-100 h-100 d-inline-block object-fit-cover"
          />
        ) : (
          <PhotoWrapper className="w-100 h-100 d-inline-block">
            <AtiraIcon icon={faCircleUser} />
          </PhotoWrapper>
        )}
      </div>

      <Title className="title">{atiraLink?.title}</Title>

      {atiraLink?.bio ? (
        <Bio className="bio text-center">{atiraLink?.bio}</Bio>
      ) : null}

      <div className="row justify-content-center align-items-center g-2 w-100 left-side-row">
        {atiraLink?.email ? (
          <div
            className={`${previewMode === TemplatePreviewMode.DESKTOP ? 'col-6' : 'col-12'} ${previewMode !== TemplatePreviewMode.MOBILE ? 'gx-1' : 'gx-0'} gy-1`}
          >
            <a
              href={`mailto:${atiraLink.email}`}
              className="social-link gap-2 py-2 px-1 w-100 d-flex align-items-center justify-content-center text-decoration-none"
            >
              <div className="d-flex align-items-center justify-content-center">
                <i className="fa-solid fa-envelope"></i>
              </div>
              <p className="m-0">{linkLanguageStrings.sendAnEmail}</p>
            </a>
          </div>
        ) : null}

        {atiraLink?.website ? (
          <div
            className={`${previewMode === TemplatePreviewMode.DESKTOP ? 'col-6' : 'col-12'} ${previewMode !== TemplatePreviewMode.MOBILE ? 'gx-1' : 'gx-0'} gy-1`}
          >
            <a
              href={
                atiraLink.website.startsWith('http')
                  ? atiraLink.website
                  : `https://${atiraLink.website}`
              }
              className="social-link gap-2 py-2 px-1 w-100 d-flex align-items-center justify-content-center text-decoration-none"
            >
              <div className="d-flex align-items-center justify-content-center">
                <i className="fa-solid fa-globe"></i>
              </div>
              <p className="m-0">{linkLanguageStrings.visitMyWebsite}</p>
            </a>
          </div>
        ) : null}

        {atiraLink?.map ? (
          <div
            className={`${previewMode === TemplatePreviewMode.DESKTOP ? 'col-6' : 'col-12'} ${previewMode !== TemplatePreviewMode.MOBILE ? 'gx-1' : 'gx-0'} gy-1`}
          >
            <a
              href={
                atiraLink.map.startsWith('http')
                  ? atiraLink.map
                  : `https://${atiraLink.map}`
              }
              className="social-link gap-2 py-2 px-1 w-100 d-flex align-items-center justify-content-center text-decoration-none"
            >
              <div className="d-flex align-items-center justify-content-center">
                <i className="fa-solid fa-location-dot"></i>
              </div>
              <p className="m-0">{linkLanguageStrings.myLocation}</p>
            </a>
          </div>
        ) : null}

        {atiraLink?.phone ? (
          <div
            className={`${previewMode === TemplatePreviewMode.DESKTOP ? 'col-6' : 'col-12'} ${previewMode !== TemplatePreviewMode.MOBILE ? 'gx-1' : 'gx-0'} gy-1`}
          >
            <a
              href={`tel:${atiraLink.phone}`}
              className="social-link gap-2 py-2 px-1 w-100 d-flex align-items-center justify-content-center text-decoration-none"
            >
              <div className="d-flex align-items-center justify-content-center">
                <i className="fa-solid fa-phone"></i>
              </div>
              <p className="m-0">{linkLanguageStrings.makeACall}</p>
            </a>
          </div>
        ) : null}

        {atiraLink?.customLinks?.map((link, index) =>
          link.label?.length && link.value?.length ? (
            <div
              className={`${previewMode === TemplatePreviewMode.DESKTOP ? 'col-6' : 'col-12'} ${previewMode !== TemplatePreviewMode.MOBILE ? 'gx-1' : 'gx-0'} gy-1`}
              key={`${link}-${index}`}
            >
              <a
                href={
                  link.value.startsWith('http')
                    ? link.value
                    : `https://${link.value}`
                }
                className="social-link gap-2 py-2 px-1 w-100 d-flex align-items-center justify-content-center text-decoration-none"
              >
                <div className="d-flex align-items-center justify-content-center">
                  <i className="fa-solid fa-link"></i>
                </div>
                <p className="m-0">{link.label}</p>
              </a>
            </div>
          ) : null,
        )}
      </div>
    </div>
  );
};
