import { TFunction } from 'i18next';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraSelect } from '../../../components/AtiraSelect';
import { AtiraTable } from '../../../components/AtiraTable';
import { Flex } from '../../../components/Flex';
import { GetReportsDto } from '../../../model/admin/dto/GetReportsDto';
import { CreationRange } from '../../../model/admin/types/CreationRange.enum';
import { AtiraLink } from '../../../model/atira-link/AtiraLink';
import { AtiraLinkReportReasons } from '../../../model/atira-link/types/AtiraLinkReportReasons';
import { adminSliceSelectors } from '../../../redux/admin/admin.selector';
import { adminActions } from '../../../redux/admin/admin.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Spacing } from '../../../theme/Spacing';
import { AdminReportsReadDrawer } from '../components/AdminReportsReadDrawer';
import { createAdminReportsTableColumns } from '../components/createAdminReportsTableColumns';

const getReportReasonsFilterOptions = (t: TFunction) => [
  { label: t('common.all_kinds'), value: null },
  {
    label: t(
      `admin.reports.report.reason.${AtiraLinkReportReasons.FRAUD.toLowerCase()}`,
    ),
    value: AtiraLinkReportReasons.FRAUD,
  },
  {
    label: t(
      `admin.reports.report.reason.${AtiraLinkReportReasons.INAPPROPRIATE_CONTENT.toLowerCase().replace(' ', '_')}`,
    ),
    value: AtiraLinkReportReasons.INAPPROPRIATE_CONTENT,
  },
  {
    label: t(
      `admin.reports.report.reason.${AtiraLinkReportReasons.HARASSMENT.toLowerCase().replace(' ', '_')}`,
    ),
    value: AtiraLinkReportReasons.HARASSMENT,
  },
  {
    label: t(
      `admin.reports.report.reason.${AtiraLinkReportReasons.HATE_SPEECH.toLowerCase().replace(' ', '_')}`,
    ),
    value: AtiraLinkReportReasons.HATE_SPEECH,
  },
  {
    label: t(
      `admin.reports.report.reason.${AtiraLinkReportReasons.IDENTITY_THEFT.toLowerCase().replace(' ', '_')}`,
    ),
    value: AtiraLinkReportReasons.IDENTITY_THEFT,
  },
  {
    label: t(
      `admin.reports.report.reason.${AtiraLinkReportReasons.OTHER.toLowerCase()}`,
    ),
    value: AtiraLinkReportReasons.OTHER,
  },
];

const getRangeFilterOptions = (t: TFunction) => [
  { label: t('common.all_times'), value: null },
  {
    label: t(`common.${CreationRange.TODAY.toLowerCase()}`),
    value: CreationRange.TODAY,
  },
  {
    label: t(`common.${CreationRange.YESTERDAY.toLowerCase()}`),
    value: CreationRange.YESTERDAY,
  },
  {
    label: t(`common.${CreationRange.LAST_WEEK.toLowerCase()}`),
    value: CreationRange.LAST_WEEK,
  },
  {
    label: t(`common.${CreationRange.LAST_MONTH.toLowerCase()}`),
    value: CreationRange.LAST_MONTH,
  },
];

const StyledSelect = styled(AtiraSelect)`
  height: 2.3rem;
  width: 12rem;
`;

const FiltersWrapper = styled(Flex)`
  gap: ${Spacing.s};
  flex-wrap: wrap;

  @media (min-width: ${Breakpoints.TABLET}) {
    flex-wrap: nowrap;
    width: max-content;
  }
`;

export const AdminReportsTab: React.FC = () => {
  const [reportsReadDrawerVisible, setReportsReadDrawerVisible] =
    useState(false);
  const [selectedReportedAtiraLink, setSelectedReportedAtiraLink] =
    useState<AtiraLink | null>(null);

  const { t } = useTranslation();
  const { control, getValues } = useForm<GetReportsDto>();

  const dispatch = useAppDispatch();
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const reportedLinks = useAppSelector(adminSliceSelectors.selectReports);
  const reportsLoading = useAppSelector(
    adminSliceSelectors.selectReportsLoading,
  );
  const reportsPage = useAppSelector(
    adminSliceSelectors.selectReportsTablePage,
  );
  const reportsPageSize = useAppSelector(
    adminSliceSelectors.selectReportsTablePageSize,
  );
  const reportsMeta = useAppSelector(adminSliceSelectors.selectReportsMeta);

  const readReports = (atiraLink: AtiraLink) => {
    setSelectedReportedAtiraLink(atiraLink);
    setReportsReadDrawerVisible(true);
  };

  const tableColumns = createAdminReportsTableColumns({ t, readReports });

  const onPageChange = async (page: number, pageSize: number) => {
    dispatch(adminActions.setReportsTablePage(page));
  };

  const onTableShowSize = (curr: number, newSize: number) => {
    dispatch(adminActions.setReportsTablePageSize(newSize));
    onPageChange(1, newSize);
  };

  const fetchReports = useCallback(
    () =>
      dispatch(
        adminActions.getReports({
          userId,
          meta: {
            count: reportsPageSize,
            page: reportsPage - 1,
          },
          ...omitBy(getValues(), isNil),
        }),
      ),
    [dispatch, getValues, reportsPage, reportsPageSize, userId],
  );

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  return (
    <Flex gap="m" flexDirection="column">
      <FiltersWrapper>
        <Controller
          control={control}
          name="range"
          render={({ field: { value, onChange } }) => (
            <StyledSelect
              options={getRangeFilterOptions(t)}
              defaultValue={getRangeFilterOptions(t)[0]}
              value={value}
              onChange={(e) => {
                onChange(e);
                fetchReports();
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="reason"
          render={({ field: { value, onChange } }) => (
            <StyledSelect
              options={getReportReasonsFilterOptions(t)}
              defaultValue={getReportReasonsFilterOptions(t)[0]}
              value={value}
              onChange={(e) => {
                onChange(e);
                fetchReports();
              }}
            />
          )}
        />
      </FiltersWrapper>

      <AtiraTable
        key={JSON.stringify(tableColumns)}
        columns={tableColumns}
        data={reportedLinks}
        loading={reportsLoading}
        pagination={{
          pageSizeOptions: ['5', '10', '20', '50', '100'],
          pageSize: reportsPageSize,
          current: reportsPage,
          total: reportsMeta.total,
          onChange: onPageChange,
          showSizeChanger: true,
          onShowSizeChange: onTableShowSize,
        }}
        rowSelection={undefined}
        size="small"
        scroll={{ x: 'max-content' }}
      />

      <AdminReportsReadDrawer
        reportedLink={selectedReportedAtiraLink}
        open={reportsReadDrawerVisible}
        onClose={() => setReportsReadDrawerVisible(false)}
      />
    </Flex>
  );
};
