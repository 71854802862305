import GoogleIcon from './google.svg';
import SaudiFlag from './saudi-flag.svg';
import TurkishFlag from './turkish-flag.svg';
import USAFlag from './usa-flag.svg';

export const Images = {
  TransparentColoredBackground: require('./transparent-colored-bg.png'),
  AtiraLinkMainLogo: require('./atiralink-main-logo.png'),
  AtiraLinkSubLogo: require('./atiralink-sub-logo.png'),
  AtiraLinkMainIcon: require('./atiralink-main-icon.png'),
  AtiraLinkSubIcon: require('./atiralink-sub-icon.png'),
  HeroPhoto: require('./hero.jpg'),
  HeroBackground: require('./hero-bg.jpg'),
  FormTheme1: require('./form-theme1.png'),
  FormTheme2: require('./form-theme2.png'),
  FormTheme3: require('./form-theme3.png'),
  LandingPageSecondSection1: require('./landing-page-second-section-1.png'),
  LandingPageSecondSection2: require('./landing-page-second-section-2.png'),
  LandingPageSecondSection3: require('./landing-page-second-section-3.png'),
  LandingPageHeroBackground: require('./landing-page-hero-image.png'),
  LandingPageHeroRightPhone: require('./landing-page-hero-right-phone.png'),
  LandingPageHeroLeftPhone: require('./landing-page-hero-left-phone.png'),
  RegisterBackground: require('./register-bg.webp'),
  PricingFAQImage: require('./pricing-faq-img.png'),
  PricingHeroImage: require('./pricing-hero-img.png'),
  TemplatesHeroImage: require('./templates-hero-image.png'),
  HelpSupportHeroImage: require('./help-support-hero-image.png'),
  HelpSupportFirstSectionImage: require('./help-support-first-section-image.png'),
  HelpSupportSecondSectionImage: require('./help-support-second-section-image.png'),
  HelpSupportCallToActionsImage: require('./help-support-call-to-actions-image.png'),
  GoogleIcon,
  SaudiFlag,
  TurkishFlag,
  USAFlag,
};
