import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Images } from '../../assets';
import { Flex } from '../../components/Flex';
import { PageHeroSection } from '../../components/PageHeroSection';
import { HelpSupportCallToActionsSection } from './sections/HelpSupportCallToActionsSection';
import { SectionWrapper } from './sections/HelpSupportSectionsWrapper';

const Container = styled(Flex)`
  flex-direction: column;
  height: 100%;
`;

export const HelpSupport: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <PageHeroSection
        title={t('helpSupport.hero.title')}
        description={t('helpSupport.hero.description')}
        moreDescription={t('helpSupport.hero.moreDescription')}
        image={Images.HelpSupportHeroImage}
        imageMaxWidth={'100%'}
      />

      <SectionWrapper
        title={t('helpSupport.firstSection.title')}
        description={t('helpSupport.firstSection.description')}
        moreDescription={t('helpSupport.firstSection.moreDescription')}
        image={Images.HelpSupportFirstSectionImage}
        reverse={true}
        onClick={() => {}}
        c2aTitle={t('helpSupport.firstSection.callToAction')}
      />

      <SectionWrapper
        title={t('helpSupport.secondSection.title')}
        description={t('helpSupport.secondSection.description')}
        moreDescription={t('helpSupport.secondSection.moreDescription')}
        image={Images.HelpSupportSecondSectionImage}
        onClick={() => {}}
        c2aTitle={t('helpSupport.secondSection.callToAction')}
      />

      <HelpSupportCallToActionsSection />
    </Container>
  );
};
