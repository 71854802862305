import { notification } from 'antd';
import { AxiosError, isAxiosError } from 'axios';

import i18n from '../i18n';

export class AtiraToast {
  static autoClose = 5;
  static style: React.CSSProperties = {};
  static theme = 'colored';
  private static toastMap: Map<string, number> = new Map();

  private static getPlacement() {
    return i18n.language === 'ar' ? 'topLeft' : 'topRight';
  }

  static success(message: string, config: any = {}) {
    const toastKey = 'success';
    if (!this.toastMap.has(toastKey)) {
      notification.success({
        message: i18n.t('common.success'),
        description: message,
        duration: this.autoClose,
        style: this.style,
        placement: this.getPlacement(),
        onClose: () => this.toastMap.delete(toastKey),
        ...config,
      });
    }
  }

  static info(message: string, config: any = {}) {
    const toastKey = 'info';
    if (!this.toastMap.has(toastKey)) {
      notification.info({
        message: i18n.t('common.info'),
        description: message,
        duration: this.autoClose,
        style: this.style,
        placement: this.getPlacement(),
        onClose: () => this.toastMap.delete(toastKey),
        ...config,
      });
    }
  }

  static error(message: string) {
    const toastKey = 'error';
    if (!this.toastMap.has(toastKey)) {
      notification.error({
        message: i18n.t('common.error'),
        description: message,
        duration: this.autoClose,
        style: this.style,
        placement: this.getPlacement(),
        onClose: () => this.toastMap.delete(toastKey),
      });
    }
  }

  static apiError(e: any, config: any = {}) {
    console.log(e);
    let message = '';
    if (isAxiosError(e)) {
      if (typeof (e as AxiosError).response?.data === 'string') {
        message = (e as AxiosError).response?.data as string;
      } else {
        message = (e as AxiosError).message;
      }
    } else if (typeof e.message === 'string') {
      message = e.message;
    }
    console.log(message);
    const toastKey = 'apiError';
    if (!this.toastMap.has(toastKey)) {
      notification.error({
        message: i18n.t('common.APIError'),
        description: message || 'Something went wrong. Please try again later',
        duration: this.autoClose,
        style: this.style,
        placement: this.getPlacement(),
        onClose: () => this.toastMap.delete(toastKey),
        ...config,
      });
    }
  }
}
