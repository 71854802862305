import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Images } from '../../../assets';
import { AtiraColumn } from '../../../components/AtiraColumn';
import { AtiraRow } from '../../../components/AtiraRow';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { AtiraImage } from '../../../components/Image';
import { Text } from '../../../components/Text';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';

const Container = styled.section`
  padding-block: ${Spacing.xl};
  padding-inline: ${Spacing.s};
  position: relative;

  &:nth-child(2) {
    padding-top: ${Spacing.xxxl};
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${Images.TransparentColoredBackground});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.2;
    z-index: -1;
  }

  @media (min-width: ${Breakpoints.TABLET}) {
    padding-block: ${Spacing.xxxl};
  }
`;

const CallToAction = styled(Button)`
  color: ${(props) => props.theme.lightTextColor};
  background: linear-gradient(180deg, #960baa 0%, #2b2694 100%);

  width: fit-content;
  border-radius: ${Rounded.md};
  text-align: center;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.25rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.darkSub};
    color: ${({ theme }) => theme.darkSub};
  }
`;

type SectionWrapperProps = {
  title: string;
  description: string;
  moreDescription?: string;
  image: string;
  onClick?: VoidFunction;
  c2aTitle?: string;
  reverse?: boolean;
};

export const SectionWrapper: React.FC<SectionWrapperProps> = ({
  title,
  description,
  moreDescription,
  image,
  onClick,
  c2aTitle,
  reverse = false,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <AtiraRow justify={'center'}>
        <AtiraColumn xs={24} sm={24} md={22} lg={20} xl={20}>
          <AtiraRow justify={'space-between'} align={'middle'} gutter={[0, 30]}>
            {reverse ? (
              <>
                <AtiraColumn
                  xs={{ span: 24, order: 2 }}
                  sm={{ span: 24, order: 2 }}
                  md={{ span: 12, order: 1 }}
                  lg={{ span: 12, order: 1 }}
                  xl={{ span: 12, order: 1 }}
                >
                  <AtiraImage src={image} />
                </AtiraColumn>
                <AtiraColumn
                  xs={{ span: 24, order: 1 }}
                  sm={{ span: 24, order: 1 }}
                  md={{ span: 12, order: 2 }}
                  lg={{ span: 12, order: 2 }}
                  xl={{ span: 12, order: 2 }}
                >
                  <Flex flexDirection="column" gap="l" padding="s">
                    <Text fontSize="xxl" fontWeight={'bold'} as={'h2'}>
                      {title}
                    </Text>

                    <Text fontSize="xm" lineHeight={'1.75rem'}>
                      {description}
                    </Text>

                    <Text fontSize="xm" lineHeight={'1.75rem'}>
                      {moreDescription}
                    </Text>

                    <Flex justifyContent="center">
                      <Tooltip title={t('common.comingSoon')}>
                        {onClick && c2aTitle ? (
                          <CallToAction disabled>{c2aTitle}</CallToAction>
                        ) : null}
                      </Tooltip>
                    </Flex>
                  </Flex>
                </AtiraColumn>
              </>
            ) : (
              <>
                <AtiraColumn xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Flex flexDirection="column" gap="xl" padding="s">
                    <Text fontSize="xxl" fontWeight={'bold'} as={'h2'}>
                      {title}
                    </Text>

                    <Text fontSize="xm" lineHeight={'1.75rem'}>
                      {description}
                    </Text>

                    <Text fontSize="xm" lineHeight={'1.75rem'}>
                      {moreDescription}
                    </Text>

                    <Flex justifyContent="center">
                      <Tooltip title={t('common.comingSoon')}>
                        {onClick && c2aTitle ? (
                          <CallToAction onClick={onClick} disabled>
                            {c2aTitle}
                          </CallToAction>
                        ) : null}
                      </Tooltip>
                    </Flex>
                  </Flex>
                </AtiraColumn>

                <AtiraColumn xs={24} sm={24} md={12} lg={12} xl={12}>
                  <AtiraImage src={image} />
                </AtiraColumn>
              </>
            )}
          </AtiraRow>
        </AtiraColumn>
      </AtiraRow>
    </Container>
  );
};
