import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { topojson } from 'chartjs-chart-geo';

import { AtiraApi } from '../../axios/AxiosClient';
import { GetAtiraLinkTimestampedStatsResponseDto } from '../../model/atira-link/dto/GetAtiraLinkTimestampedStatsResponseDto';
import { GenericWithUserId } from '../../model/shared/GenericWithUserId';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from '../user/user.slice';

interface AnalyticsReducer {
  worldCountries: any[];
  timestampedStats: GetAtiraLinkTimestampedStatsResponseDto;
}

const initialState = Object.freeze<AnalyticsReducer>({
  worldCountries: [],
  timestampedStats: [],
});

const getWorldCountries = AtiraThunk<AnalyticsReducer['worldCountries'], void>(
  '/world-atlas',
  () => {
    return axios
      .get('https://unpkg.com/world-atlas/countries-50m.json')
      .then(({ data }) => data);
  },
);

const getTimestampedStats = AtiraThunk<
  any,
  GenericWithUserId & { atiraLinkId: string }
>('/atira-link/stats', (dto) => {
  return AtiraApi.post(`/atira-link/${dto.atiraLinkId}/stats`, dto).then(
    ({ data }) => data,
  );
});

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getWorldCountries.fulfilled, (state, action) => {
      const data = action.payload as any;

      const countries = (topojson.feature(data, data.objects.countries) as any)
        .features;

      state.worldCountries = countries;
    });

    addCase(getTimestampedStats.fulfilled, (state, action) => {
      state.timestampedStats = action.payload;
    });

    addCase(userActions.logout.fulfilled, (state) => {
      return (state = initialState);
    });
  },
});

export const analyticsActions = {
  getWorldCountries,
  getTimestampedStats,
};

export default analyticsSlice.reducer;
