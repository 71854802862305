import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { AtiraLink } from '../../../../model/atira-link/AtiraLink';
import { AtiraLinkLanguageStrings } from '../../../../model/atira-link/AtiraLinkLangStrings';
import { CreateAtiraLinkDto } from '../../../../model/atira-link/dto/CreateAtiraLinkDto';
import { EditAtiraLinkDto } from '../../../../model/atira-link/dto/EditAtiraLinkDto';
import { atiraLinkSliceSelectors } from '../../../../redux/atira-link/atira-link.selector';
import { useAppSelector } from '../../../../redux/store';
import { TemplatePreviewMode } from '../../../shared/model/TemplatePreviewMode.enum';

const tabAppearAnimation = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`;

const Tab = styled.div`
  animation: ${tabAppearAnimation} 0.3s ease-in-out;
`;

interface AtiraLinkProps {
  socials?:
    | AtiraLink['socials']
    | CreateAtiraLinkDto['socials']
    | EditAtiraLinkDto['socials'];
  embed?: AtiraLink['embed'];
  linkLanuageStrings: AtiraLinkLanguageStrings;
  previewMode?: TemplatePreviewMode;
}

enum Tabs {
  SOCIAL = 'SOCIAL',
  EMBED = 'EMBED',
}

export const AtiraLinkRightSide: React.FC<AtiraLinkProps> = ({
  socials,
  embed,
  linkLanuageStrings,
  previewMode = TemplatePreviewMode.MOBILE,
}) => {
  const [activeTab, setActiveTab] = useState(Tabs.SOCIAL);

  const atiraLink = useAppSelector(
    atiraLinkSliceSelectors.selectSelectedAtiraLink,
  );

  const canDisplayEmbed = atiraLink?.user?.plan?.perks?.embed !== 'off';

  const content = () => {
    if (socials?.length && embed?.length && canDisplayEmbed) {
      return (
        <>
          <ul
            className="right-side-tabs nav nav-tabs mb-1"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                onClick={() => setActiveTab(Tabs.SOCIAL)}
                className={`nav-link ${activeTab === Tabs.SOCIAL ? 'active' : ''}`}
                id="social-tab"
                data-bs-toggle="tab"
                data-bs-target="#social-tab-pane"
                type="button"
                role="tab"
                aria-controls="social-tab-pane"
                aria-selected="true"
              >
                {linkLanuageStrings.socialsTabTitle}
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                onClick={() => setActiveTab(Tabs.EMBED)}
                className={`nav-link ${activeTab === Tabs.EMBED ? 'active' : ''}`}
                id="embed-tab"
                data-bs-toggle="tab"
                data-bs-target="#embed-tab-pane"
                type="button"
                role="tab"
                aria-controls="embed-tab-pane"
                aria-selected="false"
              >
                {linkLanuageStrings.embedTabTitle}
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <Tab
              className={`tab-pane fade ${activeTab === Tabs.SOCIAL ? 'show active' : ''} w-100`}
              id="social-tab-pane"
              role="tabpanel"
              aria-labelledby="social-tab"
              tabIndex={0}
            >
              <div className="d-flex flex-column gap-1 socials-wrapper">
                {socials?.map((social, index) => (
                  <a
                    key={`${social.label}-${index}`}
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-handler={social.handle}
                    className="trackable-social-link-target social-link gap-2 py-2 px-3 w-100 d-flex align-items-center justify-content-center text-decoration-none"
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      <i
                        className={`fa-brands fa-${social.handle.toLowerCase() === 'twitter' ? 'x-twitter' : social.handle.toLowerCase()}`}
                      ></i>
                    </div>
                    <p className="m-0 text-truncate">{social.label}</p>
                  </a>
                ))}
              </div>
            </Tab>
            <Tab
              className={`tab-pane fade ${activeTab === Tabs.EMBED ? 'show active' : ''} w-100`}
              id="embed-tab-pane"
              role="tabpanel"
              aria-labelledby="embed-tab"
              tabIndex={0}
              dangerouslySetInnerHTML={{ __html: embed }}
            ></Tab>
          </div>
        </>
      );
    } else if (socials?.length) {
      return (
        <div className="d-flex flex-column gap-1 socials-wrapper">
          {socials?.map((social, index) => (
            <a
              key={`${social.label}-${index}`}
              href={social.url}
              target="_blank"
              rel="noopener noreferrer"
              data-handler={social.handle}
              className="trackable-social-link-target social-link gap-2 py-2 px-3 w-100 d-flex align-items-center justify-content-center text-decoration-none"
            >
              <div className="d-flex align-items-center justify-content-center">
                <i
                  className={`fa-brands fa-${social.handle.toLowerCase() === 'twitter' ? 'x-twitter' : social.handle.toLowerCase()}`}
                ></i>
              </div>
              <p className="m-0 text-truncate">{social.label}</p>
            </a>
          ))}
        </div>
      );
    } else if (embed?.length && canDisplayEmbed) {
      return (
        <div dangerouslySetInnerHTML={{ __html: embed }} id="embed-tab-pane" />
      );
    }
  };

  const getCardColumnSize = () => {
    switch (previewMode) {
      case TemplatePreviewMode.TABLET:
        return 'col-6';
      case TemplatePreviewMode.DESKTOP:
        return 'col-5';
      case TemplatePreviewMode.MOBILE:
      default:
        return 'col-12';
    }
  };

  return (
    <div className={`${getCardColumnSize()}  gy-3`}>
      <div
        className={`atira-card ${previewMode === TemplatePreviewMode.DESKTOP ? 'p-4' : 'p-2'} d-flex flex-column gap-3 align-items-center h-100`}
      >
        <div className="row w-100">
          <div className="col-12 gx-0">{content()}</div>
        </div>
      </div>
    </div>
  );
};
