import React from 'react';
import Frame from 'react-frame-component';
import styled from 'styled-components';

import i18n, { AppLangs } from '../../../i18n';
import { AtiraLink } from '../../../model/atira-link/AtiraLink';
import { AtiraLinkPreviewPhoneEJSReadOnly } from './AtiraLinkPreviewPhoneEJSReadonly';

const StyledFrame = styled(Frame)`
  width: 100%;
  height: 100%;
  border: 0;
`;

type PhonePreviewIframeProps = {
  data: Partial<AtiraLink> & { linkName: string };
};

export const PhonePreviewIframe: React.FC<PhonePreviewIframeProps> = ({
  data,
}) => {
  return (
    <StyledFrame
      mountTarget={`#root-${data.theme}`}
      initialContent={`<!DOCTYPE html>
          <html lang="en" style="height: 100%; overflow: hidden">
          <head>
          ${
            i18n.language === AppLangs.AR
              ? '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.rtl.min.css" integrity="sha384-dpuaG1suU0eT09tx5plTaGMLBsfDLzUCCUXOY2j/LSvXYuG6Bqs43ALlhIqAJVRb" crossOrigin="anonymous" />'
              : '<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossOrigin="anonymous" />'
          }
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css" integrity="sha512-Kc323vGBEqzTmouAECnVceyQqyqdsSiqLQISBL29aUW4U/M7pSPA/gEUZQqv1cwx4OnYxTxve5UMg5GT6L4JJg==" crossorigin="anonymous" referrerpolicy="no-referrer">
            <link rel="stylesheet" type="text/css" href="/style/${data.theme?.toLowerCase()}.css">
          </head>
          <body style="height: 100%;">
            <div id="root-${data.theme}" style="height: 100%;"></div>
          </body>
          </html>`}
    >
      <AtiraLinkPreviewPhoneEJSReadOnly atiraLink={data} />
    </StyledFrame>
  );
};
