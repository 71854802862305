import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraDatePicker } from '../../../components/AtiraDatePicker';
import { AtiraSelect } from '../../../components/AtiraSelect';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Modal } from '../../../components/modal/Modal';
import { SearchUsersResponseDto } from '../../../model/admin/dto/SearchUsersResponseDto';
import { CreateUnpaidPlanDto } from '../../../model/plan/payment/dto/CreateUnpaidPlanDto';
import { AtiraLinkPlanType } from '../../../model/plan/types/AtiraLinkPlanType.enum';
import { AtiraLinkPlanThemeTier } from '../../../model/theme/type/AtiraLinkPlanThemeTier';
import { User } from '../../../model/user/User';
import { adminSliceSelectors } from '../../../redux/admin/admin.selector';
import { adminActions } from '../../../redux/admin/admin.slice';
import { paymentActions } from '../../../redux/payment/payment.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { isUserOnFreeTrial, isUserOnUnpaidPlan } from '../../../utils/Payment';

const getPlansOptions = (t: TFunction) =>
  Object.entries(AtiraLinkPlanThemeTier)
    .filter(([key, value]) => value !== AtiraLinkPlanThemeTier.PLAN_0)
    .map(([key, value]) => ({
      label: t(`plan.type.${value.toLowerCase()}`),
      value: key,
    }));

const Container = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};
  width: 100%;
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

type AdminManageUserPlanModalProps = {
  open: boolean;
  onClose: VoidFunction;
  user: Partial<SearchUsersResponseDto['data'][number]> | null;
};

export const AdminManageUserPlanModal: React.FC<
  AdminManageUserPlanModalProps
> = ({ open = false, onClose, user: _user }) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { user, plan } = _user || {};

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const usersPage = useAppSelector(adminSliceSelectors.selectUsersTablePage);
  const usersPageSize = useAppSelector(
    adminSliceSelectors.selectUsersTablePageSize,
  );

  const { control, getValues, handleSubmit, reset } =
    useForm<CreateUnpaidPlanDto>();

  const dispatch = useAppDispatch();

  const canCreatePlan =
    !isUserOnUnpaidPlan({ plan } as User) ||
    isUserOnFreeTrial({ plan } as User) ||
    plan?.type === AtiraLinkPlanType.PLAN_0;

  const onManageUserPlan = async () => {
    try {
      setLoading(true);

      const dto = getValues();
      dto.targetUserId = user?._id!;

      await dispatch(paymentActions.startUnpaidPlan(dto)).unwrap();
      await dispatch(
        adminActions.searchUsers({
          userId,
          meta: {
            page: usersPage - 1,
            count: usersPageSize,
          },
        }),
      ).unwrap();

      onClose();
      reset();
      AtiraToast.success(t('admin.managePlan.success'));
    } catch (e) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal title={t('admin.managePlan')} open={open} onClose={onClose}>
      <Container>
        {!canCreatePlan ? (
          <Text color="danger" align="center">
            {t('admin.managePlan.hasPlan.note')}
          </Text>
        ) : null}

        <Text>
          <Trans
            i18nKey={'admin.managePlan.modal.title'}
            values={{ name: user?.name }}
            components={[<BoldSpan />]}
          />
        </Text>

        <Controller
          control={control}
          name="planType"
          rules={{
            required: {
              value: true,
              message: t('common.this_field_required'),
            },
          }}
          render={({ field: { value, onChange } }) => (
            <AtiraSelect
              value={value}
              onChange={onChange}
              options={getPlansOptions(t)}
              disabled={!canCreatePlan}
              title={t('admin.managePlan.tier.title')}
            />
          )}
        />

        <Controller
          control={control}
          name="endsAt"
          rules={{
            required: {
              value: true,
              message: t('common.this_field_required'),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <AtiraDatePicker
              value={value}
              onChange={onChange}
              disabled={!canCreatePlan}
              title={t('admin.managePlan.endsAt.title')}
              valid={!error}
              errorMessage={error?.message}
            />
          )}
        />

        <Button
          onClick={handleSubmit(onManageUserPlan)}
          loading={loading}
          disabled={!canCreatePlan}
          height="2.5rem"
          hover
          hoverBackgroundColor="lightMain"
        >
          {t('common.create')}
        </Button>
      </Container>
    </Modal>
  );
};
