export enum AtiraLinkSocialHandlers {
  WHATSAPP = 'WHATSAPP',
  YOUTUBE = 'YOUTUBE',
  TIKTOK = 'TIKTOK',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  TELEGRAM = 'TELEGRAM',
  FACEBOOK = 'FACEBOOK',
  SNAPCHAT = 'SNAPCHAT',
  REDDIT = 'REDDIT',
  LINKEDIN = 'LINKEDIN',
  PINTEREST = 'PINTEREST',
  SPOTIFY = 'SPOTIFY',
  TWITCH = 'TWITCH',
  GITHUB = 'GITHUB',
}
