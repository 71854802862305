import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraTabs } from '../../components/AtiraTabs';
import { Flex } from '../../components/Flex';
import { UserHeader } from '../../components/UserHeader';
import { PageMeta } from '../../model/meta/PageMeta';
import { adminActions } from '../../redux/admin/admin.slice';
import { AtiraLinkIndexActions } from '../../redux/atira-link/atira-link-index.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Breakpoints } from '../../theme/Breakpoints';
import { AdminIndexTab } from './tabs/AdminIndexTab';
import { AdminPaymentTab } from './tabs/AdminPaymentTab';
import { AdminReportsTab } from './tabs/AdminReportsTab';
import { AdminSearchUsersTab } from './tabs/AdminSearchUserTab';
import { AdminStatsTab } from './tabs/AdminStatsTab';
import { AdminThemesTab } from './tabs/AdminThemesTab';

const UserHeaderWrapper = styled(Flex)`
  display: none;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: flex;
  }
`;

enum Tabkey {
  STATS = 'STATS',
  SEARCH = 'SEARCH',
  REPORTS = 'REPORTS',
  INDEX = 'INDEX',
  THEMES = 'THEMES',
  PAYMENT = 'PAYMENT',
}

export const AdminRoute: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const onTabChange = (key: string) => {
    if (key === Tabkey.SEARCH) {
      dispatch(adminActions.searchUsers({ userId, meta: PageMeta.create() }));
    }

    if (key === Tabkey.REPORTS) {
      dispatch(adminActions.getReports({ userId, meta: PageMeta.create() }));
    }

    if (key === Tabkey.STATS) {
      dispatch(adminActions.getAdminStats({ userId }));
    }

    if (key === Tabkey.INDEX) {
      dispatch(AtiraLinkIndexActions.getAllCategories());
      dispatch(AtiraLinkIndexActions.getAllCategoriesFlat());
    }

    if (key === Tabkey.PAYMENT) {
      dispatch(adminActions.getWebhooks({ userId, meta: PageMeta.create() }));
    }
  };

  useEffect(() => {
    dispatch(adminActions.getAdminStats({ userId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AtiraTabs
      onTabClick={onTabChange}
      items={[
        {
          key: Tabkey.STATS,
          label: t('admin.header.title'),
          children: <AdminStatsTab />,
        },
        {
          key: Tabkey.SEARCH,
          label: t('common.search'),
          children: <AdminSearchUsersTab />,
        },
        {
          key: Tabkey.REPORTS,
          label: t('common.reports'),
          children: <AdminReportsTab />,
        },
        {
          key: Tabkey.INDEX,
          label: t('common.index'),
          children: <AdminIndexTab />,
        },
        {
          key: Tabkey.THEMES,
          label: t('common.themes'),
          children: <AdminThemesTab />,
        },
        {
          key: Tabkey.PAYMENT,
          label: t('common.payment'),
          children: <AdminPaymentTab />,
        },
      ]}
      sideComponent={
        <UserHeaderWrapper>
          <UserHeader />
        </UserHeaderWrapper>
      }
    />
  );
};
