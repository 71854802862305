/* eslint-disable no-nested-ternary */
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { Tooltip } from 'antd';
import noop from 'lodash/noop';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraColumn } from '../../../components/AtiraColumn';
import { AtiraIcon } from '../../../components/AtiraIcon';
import { AtiraRow } from '../../../components/AtiraRow';
import { Button, ButtonProps } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { WarningModal } from '../../../components/WarningModal';
import { AtiraLinkPlanDefinition } from '../../../model/plan/Plan-definition';
import { AtiraLinkPlanType } from '../../../model/plan/types/AtiraLinkPlanType.enum';
import { LemonSubscriptionStatus } from '../../../model/plan/types/LemonSubscriptionStatus.enum';
import { atiraLinkSliceSelectors } from '../../../redux/atira-link/atira-link.selector';
import { AtiraLinkActions } from '../../../redux/atira-link/atira-link.slice';
import { paymentSliceSelectors } from '../../../redux/payment/payment.selector';
import { paymentActions } from '../../../redux/payment/payment.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { userActions } from '../../../redux/user/user.slice';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { standardDate } from '../../../utils/Date';
import { isUserOnFreeTrial, isUserOnUnpaidPlan } from '../../../utils/Payment';
import { PlansLoading } from '../../pricing/components/PlansLoading';
import {
  getPlanTitle,
  toUSD,
  translatePerks,
} from '../../pricing/pricingUtils';

enum NoticeModalType {
  CANCEL = 'CANCEL',
  DOWNGRADE = 'DOWNGRADE',
  UPGRADE = 'UPGRADE',
  RESUME = 'RESUME',
  FREE_TRIAL = 'FREE_TRIAL',
}

const Card = styled(Flex)`
  border-radius: ${Rounded.pill};
  flex-direction: column;
  align-items: center;
  gap: ${Spacing.m};
  background-color: ${({ theme }) => theme.light};
  padding: ${Spacing.m};
  padding-bottom: ${Spacing.l};
  height: 100%;
  max-width: 20rem;
  margin: auto;
  box-shadow: 0px 4px 4px 0px #00000040;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: relative;

  &:hover {
    box-shadow: 0px 4px 4px 0px #00000000;
    outline: 1px solid ${({ theme }) => theme.darkerSub};
    background-color: ${({ theme }) => theme.darkSub};
  }
`;

const CardPlanInfoWrapper = styled(Flex)<{
  isSpecial?: boolean;
  showFreeTrial?: boolean;
  showUnpaidPlan?: boolean;
  content?: string;
}>`
  padding: ${Spacing.l};
  aspect-ratio: 1 / 1;
  border-radius: ${Rounded.circle};
  width: 100%;
  background: radial-gradient(50% 50% at 50% 50%, #960baa 76.5%, #2b2694 100%);

  ${({ showFreeTrial, content, theme }) =>
    showFreeTrial
      ? `
  &::before {
    content: "${content}";
    text-align: center;
    color: white;
    position: absolute;
    top: 150px;
    font-size: 1rem;
    right: -20px;
    width: 15rem;
    height: 1.4rem;
    background-color: ${theme.main};
    transform-origin: top right;
    transform: rotate(45deg);
  }
  `
      : ``}

  ${({ showUnpaidPlan, content, theme }) =>
    showUnpaidPlan
      ? `
  &::before {
    content: "${content}";
    text-align: center;
    color: white;
    position: absolute;
    top: 150px;
    font-size: 1rem;
    right: -20px;
    width: 15rem;
    height: 1.4rem;
    background-color: ${theme.main};
    transform-origin: top right;
    transform: rotate(45deg);
  }
  `
      : ``}
`;

const CardPlanInfoInnerWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: ${({ theme }) => theme.light};
`;

const CardTitle = styled(Text)<{ current: boolean }>`
  font-weight: 700;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.main};
  text-align: center;
  text-decoration: ${({ current }) => (current ? 'underline' : 'none')};
`;

const Price = styled(Text)`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.textColor};
  font-weight: bold;
`;

const FeaturesWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  margin-bottom: ${Spacing.l};
  justify-content: flex-start;
`;

const CardCallToActionWrapper = styled(Flex)`
  padding: ${Spacing.s};
  border-bottom: ${({ theme }) => `1px solid ${theme.textColor}`};
  width: 80%;
  justify-content: center;
  margin-bottom: ${Spacing.m};
`;

const CardCallToAction = styled(Button)`
  text-align: center;
  text-decoration: none;
  border-radius: ${Rounded.pill};
  color: ${({ theme }) => theme.lightTextColor};
  background: linear-gradient(0deg, #2b2694, #960baa);
  font-size: 1.2rem;
  transition: all 0.3s;
  padding: ${Spacing.s} ${Spacing.m};
  height: 2.5rem;
  min-width: 16rem;
  margin-top: auto;
  margin-bottom: ${Spacing.m};
  background: linear-gradient(0deg, #2b2694, #960baa);

  &:hover {
    box-shadow: 0px 4px 4px 0px #00000040;
  }
`;

export const PrivatePricingPlans: React.FC = () => {
  const [planId, setPlanId] = useState<string | undefined>(undefined);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [mode, setMode] = useState<NoticeModalType | null>(null);

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const user = useAppSelector(userSliceSelectors.selectLoggedInUser);
  const plans = useAppSelector(paymentSliceSelectors.selectPlans);
  const loading = useAppSelector(paymentSliceSelectors.selectPlansLoading);
  const checkoutLoading = useAppSelector(
    paymentSliceSelectors.selectCurrentCheckoutURLLoading,
  );
  const userLinks = useAppSelector(
    atiraLinkSliceSelectors.selectUserAtiraLinks,
  );

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const createCheckout = useCallback(
    async (planId: string) => {
      try {
        setPlanId(planId);
        const checkoutURL = await dispatch(
          paymentActions.createCheckout({
            productId: planId,
            userId,
            embed: true,
          }),
        ).unwrap();
        window.createLemonSqueezy();
        window.LemonSqueezy.Url.Open(checkoutURL);
        paymentActions.resetCheckoutURL();
        setPlanId(undefined);
      } catch (e: any) {
        AtiraToast.apiError(e);
        console.log(e);
      }
    },
    [dispatch, userId],
  );

  const onDowngradePlan = async () => {
    try {
      setButtonsDisabled(true);
      await dispatch(
        paymentActions.changePlan({ lemonVariantId: planId!, userId }),
      ).unwrap();
      await dispatch(userActions.getLoggedInUser({ userId })).unwrap();
      AtiraToast.success(t('subscription.plan.noticeModal.downgrade.toast'));
      setTimeout(() => {
        dispatch(userActions.getLoggedInUser({ userId }));
        dispatch(
          AtiraLinkActions.setSelectedAtiraLink(
            userLinks.sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime(),
            )[0],
          ),
        );
      }, 6000);
      setMode(null);
      setPlanId(undefined);
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setButtonsDisabled(false);
    }
  };

  const onUpgradePlan = async () => {
    try {
      setButtonsDisabled(true);
      await dispatch(
        paymentActions.changePlan({ lemonVariantId: planId!, userId }),
      ).unwrap();
      await dispatch(userActions.getLoggedInUser({ userId })).unwrap();
      AtiraToast.success(t('subscription.plan.noticeModal.upgrade.toast'), {
        duration: 7,
      });
      setTimeout(() => {
        dispatch(userActions.getLoggedInUser({ userId }));
      }, 6000);
      setMode(null);
      setPlanId(undefined);
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setButtonsDisabled(false);
    }
  };

  const onCancelPlan = async () => {
    try {
      setButtonsDisabled(true);
      setPlanId(user?.plan?.lemonVariantId!);
      await dispatch(paymentActions.cancelPlan()).unwrap();
      await dispatch(userActions.getLoggedInUser({ userId })).unwrap();
      setMode(null);
      AtiraToast.success(t('subscription.plan.noticeModal.cancel.toast'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setButtonsDisabled(false);
      setPlanId(undefined);
    }
  };

  const onResumePlan = async () => {
    try {
      setButtonsDisabled(true);
      setPlanId(user?.plan?.lemonVariantId!);
      await dispatch(paymentActions.resumeCancelledPlan()).unwrap();
      await dispatch(userActions.getLoggedInUser({ userId })).unwrap();
      setMode(null);
      AtiraToast.success(t('subscription.plan.noticeModal.resume.toast'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setButtonsDisabled(false);
      setPlanId(undefined);
    }
  };

  const onStartFreeTrial = useCallback(async () => {
    try {
      setButtonsDisabled(true);
      await dispatch(paymentActions.startFreeTrial()).unwrap();
      await dispatch(userActions.getLoggedInUser({ userId })).unwrap();
      setMode(null);
      AtiraToast.success(t('subscription.plan.noticeModal.freeTrial.toast'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setButtonsDisabled(false);
      setPlanId(undefined);
    }
  }, [dispatch, t, userId]);

  const getActionButtonProps = (
    plan: AtiraLinkPlanDefinition,
  ): Partial<ButtonProps> => {
    const userPlan = user?.plan;

    if (!userPlan) {
      return {};
    }

    const plans = Object.keys(AtiraLinkPlanType);
    const isInFreeTrial = isUserOnFreeTrial(user);
    const isOnUnpaidPlan = isUserOnUnpaidPlan(user);
    const canUseFreeTrial =
      userPlan?.freeTrial?.hasUsed !== true &&
      plan.type === AtiraLinkPlanType.PLAN_0 &&
      user.plan?.unpaidPlan?.hasUsed !== true;
    const isCancelled =
      userPlan?.lemonPlanData?.status === LemonSubscriptionStatus.CANCELLED;
    const userCurrentPlanIndex = plans.indexOf(userPlan?.type);
    const targetPlanIndex = plans.indexOf(plan.type);

    const isCurrentPlan = userCurrentPlanIndex === targetPlanIndex;
    const isDowngrade = userCurrentPlanIndex > targetPlanIndex;
    const isUpgrade = userCurrentPlanIndex < targetPlanIndex;

    const getCurrentPlanButtonProps = (): Partial<ButtonProps> => {
      const userPlan = user?.plan;
      if (isInFreeTrial || isOnUnpaidPlan) {
        return {
          title: t('subscription.plan.button.subscribe'),
          onClick: () => createCheckout(plan.lemonVariantId),
        };
      }

      if (isCancelled) {
        return {
          title: t('subscription.plan.button.resume'),
          onClick: () => setMode(NoticeModalType.RESUME),
        };
      }

      if (userPlan?.type !== AtiraLinkPlanType.PLAN_0) {
        return {
          title: t('subscription.plan.button.cancel'),
          onClick: () => setMode(NoticeModalType.CANCEL),
        };
      }

      if (canUseFreeTrial) {
        return {
          title: t('subscription.plan.button.freeTrial'),
          onClick: () => {
            setMode(NoticeModalType.FREE_TRIAL);
            setPlanId(plan.lemonVariantId);
          },
        };
      }

      return {
        title: t('subscription.plan.button.current'),
      };
    };

    const getDowngradeButtonProps = (): Partial<ButtonProps> => {
      if (
        isInFreeTrial ||
        (isOnUnpaidPlan && plan.type !== AtiraLinkPlanType.PLAN_0)
      ) {
        return {
          title: t('subscription.plan.button.subscribe'),
          onClick: () => createCheckout(plan.lemonVariantId),
        };
      }

      if (isCancelled) {
        return {
          title: t('subscription.plan.button.downgrade'),
          onClick: () => {},
          disabled: true,
        };
      }

      return {
        title: t('subscription.plan.button.downgrade'),
        onClick: () => {
          setPlanId(plan.lemonVariantId);
          setMode(NoticeModalType.DOWNGRADE);
        },
        disabled: plan.type === AtiraLinkPlanType.PLAN_0,
      };
    };

    const getUpgradeButtonProps = (): Partial<ButtonProps> => {
      if (
        isInFreeTrial ||
        user?.plan?.type === AtiraLinkPlanType.PLAN_0 ||
        isOnUnpaidPlan
      ) {
        return {
          title: t('subscription.plan.button.subscribe'),
          onClick: () => createCheckout(plan.lemonVariantId),
        };
      }

      if (isCancelled) {
        return {
          title: t('subscription.plan.button.upgrade'),
          onClick: () => {},
          disabled: true,
        };
      }

      return {
        title: t('subscription.plan.button.upgrade'),
        onClick: () => {
          setPlanId(plan.lemonVariantId);
          setMode(NoticeModalType.UPGRADE);
        },
      };
    };

    switch (true) {
      case isCurrentPlan:
        return getCurrentPlanButtonProps();
      case isDowngrade:
        return getDowngradeButtonProps();
      case isUpgrade:
        return getUpgradeButtonProps();
      default:
        return {};
    }
  };

  const noticeModalData: Record<
    NoticeModalType,
    {
      title: string;
      description: string;
      confirmButtonText: string;
      closeButtonText: string;
      onClick: VoidFunction;
    }
  > = {
    [NoticeModalType.CANCEL]: {
      title: t('subscription.plan.noticeModal.cancel.title', {
        plan: user?.plan?.type ? t(getPlanTitle(user?.plan?.type)) : '',
      }),
      description: t('subscription.plan.noticeModal.cancel.description', {
        date: standardDate(user?.plan?.lemonPlanData?.renewsAt!),
      }),
      confirmButtonText: t('subscription.plan.noticeModal.cancel.button.ok'),
      closeButtonText: t('subscription.plan.noticeModal.cancel.button.no'),
      onClick: onCancelPlan,
    },
    [NoticeModalType.RESUME]: {
      title: t('subscription.plan.noticeModal.resume.title', {
        plan: user?.plan?.type ? t(getPlanTitle(user?.plan?.type)) : '',
      }),
      description: t('subscription.plan.noticeModal.resume.description', {
        date: standardDate(user?.plan?.lemonPlanData?.renewsAt!),
      }),
      confirmButtonText: t('common.resume'),
      closeButtonText: t('common.no'),
      onClick: onResumePlan,
    },
    [NoticeModalType.DOWNGRADE]: {
      title: t('subscription.plan.noticeModal.downgrade.title', {
        plan: t(
          getPlanTitle(
            plans?.find(({ lemonVariantId }) => lemonVariantId === planId)!
              ?.type,
          )!,
        ),
      }),
      description: t('subscription.plan.noticeModal.downgrade.description', {
        date: standardDate(user?.plan?.lemonPlanData?.renewsAt!),
        price: toUSD(
          plans?.find(({ lemonVariantId }) => lemonVariantId === planId)
            ?.lemonData?.price || 0,
        ),
      }),
      confirmButtonText: t('subscription.plan.noticeModal.downgrade.button.ok'),
      closeButtonText: t('subscription.plan.noticeModal.downgrade.button.no'),
      onClick: onDowngradePlan,
    },
    [NoticeModalType.UPGRADE]: {
      title: t('subscription.plan.noticeModal.upgrade.title', {
        plan: t(
          getPlanTitle(
            plans?.find(({ lemonVariantId }) => lemonVariantId === planId)
              ?.type!,
          )!,
        ),
      }),
      description: t('subscription.plan.noticeModal.upgrade.description', {
        date: standardDate(user?.plan?.lemonPlanData?.renewsAt!),
      }),
      confirmButtonText: t('common.upgrade'),
      closeButtonText: t('common.no'),
      onClick: onUpgradePlan,
    },
    [NoticeModalType.FREE_TRIAL]: {
      title: t('subscription.plan.noticeModal.freeTrial.title'),
      description: t('subscription.plan.noticeModal.freeTrial.description'),
      confirmButtonText: t('subscription.plan.noticeModal.freeTrial.button.ok'),
      closeButtonText: t('subscription.plan.noticeModal.freeTrial.button.no'),
      onClick: onStartFreeTrial,
    },
  };

  useEffect(() => {
    dispatch(paymentActions.getAllPlans());
  }, [dispatch]);

  useEffect(() => {
    if (localStorage.getItem('processCheckout') === 'true' && user) {
      const plan = localStorage.getItem('plan')
        ? JSON.parse(localStorage.getItem('plan')!)
        : plans?.find(
            ({ lemonVariantId }) =>
              lemonVariantId === localStorage.getItem('processCheckoutId'),
          );

      if (!plan) {
        return console.log(
          'Failed to auto checkout new user, no plan was found.',
        );
      }

      localStorage.setItem('processCheckout', 'false');
      if (
        plan.type === AtiraLinkPlanType.PLAN_0 &&
        user.plan?.freeTrial?.hasUsed !== true
      ) {
        onStartFreeTrial();
      } else {
        createCheckout(plan.lemonVariantId);
      }

      // This is a temp flag sat by signupFlow to avoid multiple API calls;
      // We remove it here
      setTimeout(() => {
        localStorage.removeItem('processCheckout');
        localStorage.removeItem('processCheckoutId');
        localStorage.removeItem('plan');
      }, 3000);
    }
  }, [createCheckout, onStartFreeTrial, plans, user]);

  if (loading && !plans) {
    return <PlansLoading />;
  }

  return (
    <Fragment>
      <AtiraRow
        justify={'center'}
        gutter={[20, 20]}
        style={{ width: '100%', transition: 'all 0.2s' }}
      >
        {plans?.map?.((plan, index) => {
          const isOnFreeTrial =
            plan.type === user?.plan?.freeTrial?.freeTrialPlan &&
            isUserOnFreeTrial(user);
          const isOnUnpaidPlan =
            plan.type === user?.plan?.unpaidPlan?.unpaidPlanType &&
            isUserOnUnpaidPlan(user);

          return (
            <AtiraColumn
              key={plan?._id}
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={8}
              style={{ height: '78rem' }}
            >
              <Card>
                <CardPlanInfoWrapper
                  isSpecial={index === 1}
                  content={t(
                    isOnFreeTrial
                      ? 'subscription.plan.noticeModal.freeTrial.cardRibbon'
                      : 'subscription.plan.noticeModal.unpaidPlan.cardRibbon',
                  )}
                  showFreeTrial={isOnFreeTrial}
                  showUnpaidPlan={isOnUnpaidPlan}
                >
                  <CardPlanInfoInnerWrapper>
                    <Price>
                      {toUSD(plan.lemonData.price || 0)}
                      <sup style={{ fontSize: '0.75rem' }}>$ </sup>
                    </Price>

                    <Text fontSize="l">{t('common.per_year')}</Text>
                  </CardPlanInfoInnerWrapper>
                </CardPlanInfoWrapper>

                <FeaturesWrapper height={'30rem'}>
                  <CardTitle current={plan.type === user?.plan?.type}>
                    {t(getPlanTitle(plan.type))}
                  </CardTitle>

                  <Flex
                    flexDirection="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                    flex={1}
                  >
                    <Flex height="8rem">
                      {isOnFreeTrial || isOnUnpaidPlan ? (
                        <Text align="center" color="main">
                          {t(
                            isOnFreeTrial
                              ? 'subscription.plan.noticeModal.freeTrial.cardRibbon.description'
                              : 'subscription.plan.noticeModal.unpaidPlan.cardRibbon.description',
                            {
                              date: standardDate(
                                isOnFreeTrial
                                  ? user?.plan?.freeTrial.endsAt!
                                  : user?.plan?.unpaidPlan?.endsAt!,
                              ),
                            },
                          )}
                        </Text>
                      ) : !user?.plan?.freeTrial?.endsAt &&
                        plan.type === user?.plan?.type ? (
                        <Text align="center" fontSize="xm" color="main">
                          {t('subscription.plan.current')}
                        </Text>
                      ) : (
                        <Text align="center" fontSize="xm" color="transparent">
                          #
                        </Text>
                      )}
                    </Flex>

                    <CardCallToActionWrapper>
                      {plan.type !== AtiraLinkPlanType.PLAN_0 ||
                      user?.plan?.freeTrial?.hasUsed === false ? (
                        <CardCallToAction
                          height="2.5rem"
                          loading={
                            planId === plan.lemonVariantId && checkoutLoading
                          }
                          disabled={buttonsDisabled}
                          {...getActionButtonProps(plan)}
                        />
                      ) : (
                        <Flex height="2.5rem">
                          <Text></Text>
                        </Flex>
                      )}
                    </CardCallToActionWrapper>

                    <Flex flexDirection="column" gap="s">
                      {Object.entries(plan.perks)?.map(([key, value], i) => (
                        <Flex gap="s" key={key}>
                          <Text fontSize="xm">-</Text>
                          <Text fontSize="xm">
                            {/* @ts-ignore */}
                            {t(translatePerks(key, value))}
                          </Text>
                          {key === 'index' ? (
                            <Tooltip
                              title={
                                <span>
                                  {t('plan.index.info')}
                                  <a
                                    href="https://index.atiralink.com"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    https://index.atiralink.com
                                  </a>
                                </span>
                              }
                            >
                              <span></span>
                              <AtiraIcon icon={faInfoCircle} color="main" />
                            </Tooltip>
                          ) : null}
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                </FeaturesWrapper>
              </Card>
            </AtiraColumn>
          );
        })}
      </AtiraRow>

      <WarningModal
        open={Boolean(mode)}
        title={mode ? noticeModalData[mode]?.title : ''}
        description={mode ? noticeModalData[mode]?.description : ''}
        confirmButtonText={mode ? noticeModalData[mode]?.confirmButtonText : ''}
        closeButtonText={mode ? noticeModalData[mode]?.closeButtonText : ''}
        onClose={() => setMode(null)}
        onConfirm={mode ? noticeModalData[mode]?.onClick : noop}
        loading={Boolean(planId) && Boolean(mode) && buttonsDisabled}
      />
    </Fragment>
  );
};
