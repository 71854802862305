import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faGooglePlay } from '@fortawesome/free-brands-svg-icons/faGooglePlay';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { Tooltip } from 'antd';
import { Footer } from 'antd/es/layout/layout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../assets';
import { AtiraColumn } from '../components/AtiraColumn';
import { AtiraIcon } from '../components/AtiraIcon';
import { AtiraRow } from '../components/AtiraRow';
import { Button } from '../components/Button';
import { Flex } from '../components/Flex';
import { AtiraImage } from '../components/Image';
import { Text } from '../components/Text';
import { Breakpoints } from '../theme/Breakpoints';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';

const StyledFooter = styled(Footer)`
  background: linear-gradient(
    90deg,
    rgb(151, 11, 170) 0%,
    rgb(35, 40, 147) 99%
  );
  padding: ${Spacing.m} ${Spacing.s};
  display: flex;
  flex-direction: column;
  gap: ${Spacing.l};

  @media (min-width: ${Breakpoints.TABLET}) {
    padding: ${Spacing.xl};
  }
`;

const FooterLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.lightTextColor};
  font-size: 1.1rem;
  width: fit-content;

  &:hover {
    color: ${({ theme }) => theme.lightTextColor};
    text-decoration: underline;
    text-underline-offset: 5px;
  }
`;
const DownloadButton = styled(Button)`
  border: 1px solid #fff;
  background-color: transparent;
  width: 15rem;
  border-radius: ${Rounded.xl};
  padding: ${Spacing.s} ${Spacing.m};
  gap: ${Spacing.s};
  align-items: center;
  display: flex;

  &:not(:disabled):hover {
    color: ${({ theme }) => theme.lightMain} !important;
    background-color: ${({ theme }) => theme.sub};

    p {
      color: ${({ theme }) => theme.lightMain} !important;
    }
  }
`;

const BrandPart = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.l};
  align-items: center;

  @media (min-width: ${Breakpoints.TABLET}) {
    gap: ${Spacing.xxl};
  }
`;

const SocialLink = styled.a`
  color: ${({ theme }) => theme.light};

  &:hover {
    color: ${({ theme }) => theme.lightMain};
  }
`;

const LinksList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: ${Spacing.s};
`;

const SocialLinksList = styled.ul`
  display: flex;
  gap: ${Spacing.xl};
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const PublicFooter: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledFooter>
      <AtiraRow gutter={[20, 50]}>
        <AtiraColumn xs={24} sm={24} md={12} lg={6} xl={6}>
          <BrandPart>
            <Flex flexDirection="column" gap="m" alignItems="center">
              <AtiraImage
                src={Images.AtiraLinkSubLogo}
                style={{ maxWidth: '17rem' }}
              />
              <Text
                align="center"
                color="lightTextColor"
                fontSize="l"
                fontWeight={'bold'}
              >
                {t('app.description')}
              </Text>
            </Flex>

            <SocialLinksList>
              <li>
                <SocialLink href={''}>
                  <AtiraIcon icon={faFacebook} size="2x" />
                </SocialLink>
              </li>

              <li>
                <SocialLink href={''}>
                  <AtiraIcon icon={faInstagram} size="2x" />
                </SocialLink>
              </li>

              <li>
                <SocialLink href={''}>
                  <AtiraIcon icon={faTiktok} size="2x" />
                </SocialLink>
              </li>

              <li>
                <SocialLink href={''}>
                  <AtiraIcon icon={faYoutube} size="2x" />
                </SocialLink>
              </li>
            </SocialLinksList>
          </BrandPart>
        </AtiraColumn>

        <AtiraColumn xs={24} sm={24} md={12} lg={6} xl={6}>
          <Flex flexDirection="column" gap="m" alignItems="center">
            <DownloadButton>
              <AtiraIcon icon={faGooglePlay} size="2xl" />

              <Flex flexDirection="column">
                <Text color="light" fontSize="s">
                  {t('common.download_on_the')}
                </Text>
                <Text fontSize="l" color="light" fontWeight={'bold'}>
                  {t('common.google_play')}
                </Text>
              </Flex>
            </DownloadButton>

            <Tooltip title={t('footer.app.comingSoon')}>
              <DownloadButton disabled>
                <AtiraIcon icon={faApple} size="3x" />

                <Flex flexDirection="column">
                  <Text color="light" fontSize="s">
                    {t('common.download_on_the')}
                  </Text>
                  <Text fontSize="l" color="light" fontWeight={'bold'}>
                    {t('common.apple_store')}
                  </Text>
                </Flex>
              </DownloadButton>
            </Tooltip>
          </Flex>
        </AtiraColumn>

        <AtiraColumn xs={24} sm={8} md={8} lg={4} xl={4}>
          <Flex flexDirection="column" gap="m">
            <Text fontSize="l" fontWeight={'bold'} color="lightTextColor">
              {t('common.links')}
            </Text>
            <LinksList>
              <li>
                <FooterLink to={'/new'}>{t('common.whats_new')}</FooterLink>
              </li>

              <li>
                <FooterLink to={'/pricing'}>{t('common.pricing')}</FooterLink>
              </li>

              <li>
                <FooterLink to={'/about'}>{t('common.about')}</FooterLink>
              </li>

              <li>
                <FooterLink to={'/blog'}>{t('common.blog')}</FooterLink>
              </li>

              <li>
                <FooterLink to={'/faqs'}>{t('common.faqs')}</FooterLink>
              </li>
            </LinksList>
          </Flex>
        </AtiraColumn>

        <AtiraColumn xs={24} sm={8} md={8} lg={4} xl={4}>
          <Flex flexDirection="column" gap="m">
            <Text fontSize="l" fontWeight={'bold'} color="lightTextColor">
              {t('common.features')}
            </Text>
            <LinksList>
              <li>
                <FooterLink to={'/https://atiralink.com/'}>
                  {t('common.getting_started')}
                </FooterLink>
              </li>

              <li>
                <FooterLink to={'/templates'}>
                  {t('common.templates')}
                </FooterLink>
              </li>

              <li>
                <FooterLink to={'https://index.atiralink.com'}>
                  {t('common.index')}
                </FooterLink>
              </li>

              <li>
                <FooterLink to={'https://atiralink.com/my-urls'}>
                  {t('subheader.url_shortner')}
                </FooterLink>
              </li>

              <li>
                <FooterLink to={'https://atiralink.com/my-qrs'}>
                  {t('subheader.qr_generator')}
                </FooterLink>
              </li>
            </LinksList>
          </Flex>
        </AtiraColumn>

        <AtiraColumn xs={24} sm={8} md={8} lg={4} xl={4}>
          <Flex flexDirection="column" gap="m">
            <Text fontSize="l" fontWeight={'bold'} color="lightTextColor">
              {t('common.more')}
            </Text>
            <LinksList>
              <li>
                <FooterLink to={'/privacy-policy'}>
                  {t('common.privacy_policy')}
                </FooterLink>
              </li>

              <li>
                <FooterLink to={'/terms-and-conditions'}>
                  {t('common.terms-and-conditions')}
                </FooterLink>
              </li>

              <li>
                <FooterLink to={'/contact-us'}>
                  {t('common.contact_us')}
                </FooterLink>
              </li>

              <li>
                <FooterLink to={'/report-violation'}>
                  {t('common.report_violation')}
                </FooterLink>
              </li>
            </LinksList>
          </Flex>
        </AtiraColumn>
      </AtiraRow>

      <Flex justifyContent="center">
        <Text align="center" fontSize="xm" color="lightTextColor">
          {t('app.all_rights_reserved')}
        </Text>
      </Flex>
    </StyledFooter>
  );
};
