import { ChangeUserPlanDto } from '../../model/plan/payment/dto/ChangeUserPlanDto';
import { CreateCheckoutDto } from '../../model/plan/payment/dto/CreateCheckoutDto';
import { CreateUnpaidPlanDto } from '../../model/plan/payment/dto/CreateUnpaidPlanDto';
import { GetAllPlanResponseDto } from '../../model/plan/payment/dto/GetAllPlansResponseDto';
import { GenericWithUserId } from '../../model/shared/GenericWithUserId';
import { AtiraApi } from '../AxiosClient';

export class PaymentAxios {
  static createCheckout(dto: CreateCheckoutDto) {
    return AtiraApi.post<string>('/payment/checkout', dto).then(
      ({ data }) => data,
    );
  }

  static getAllPlans() {
    return AtiraApi.get<GetAllPlanResponseDto>('/payment/list-plans').then(
      ({ data }) => data,
    );
  }

  static cancelPlan() {
    return AtiraApi.delete<void>('/payment/cancel-user-plan').then(
      ({ data }) => data,
    );
  }

  static resumeCancelledPlan() {
    return AtiraApi.patch<void>('/payment/resume-user-plan').then(
      ({ data }) => data,
    );
  }

  static getUpdatePaymentMethodURL() {
    return AtiraApi.get<string>('/payment/get-update-payment-method-url').then(
      ({ data }) => data,
    );
  }

  static changePlan(dto: ChangeUserPlanDto) {
    return AtiraApi.patch<void>('/payment/change-user-plan', dto).then(
      ({ data }) => data,
    );
  }

  static startFreeTrial() {
    return AtiraApi.post<void>('/payment/start-free-trial').then(
      ({ data }) => data,
    );
  }

  static startUnpaidPlan(dto: CreateUnpaidPlanDto) {
    return AtiraApi.post<void>('/payment/start-unpaid-plan', dto).then(
      ({ data }) => data,
    );
  }

  static closeRestrictedContentBanner(userId: GenericWithUserId) {
    return AtiraApi.patch(`/payment/start-free-trial/dismissed-banner`, {
      userId,
    }).then(({ data }) => data);
  }
}
