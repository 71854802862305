import { AppLangs } from './i18n';

// const preloadFonts = async () => {
//   try {
//     const zainFont = new FontFace('Zain', 'url(./fonts/zain/zain-regular.ttf)');
//     const robotoFont = new FontFace(
//       'Roboto',
//       'url(/src/fonts/roboto/roboto-regular.ttf)',
//     );

//     await Promise.all([zainFont.load(), robotoFont.load()]);

//     document.fonts.add(zainFont);
//     document.fonts.add(robotoFont);
//   } catch (error) {
//     console.error('Error loading fonts:', error);
//   }
// };

export async function setLangugeAndFont() {
  const storedLang = (window.localStorage.getItem('atiraspace_lang') ||
    AppLangs.EN) as AppLangs;
  const direction = storedLang === AppLangs.AR ? 'rtl' : 'ltr';

  const doc = document.documentElement;

  doc.setAttribute('dir', direction);
  doc.setAttribute('lang', storedLang);

  switch (storedLang) {
    case AppLangs.AR: {
      doc.classList.remove('atira-english-font');
      doc.classList.add('atira-arabic-font');
      break;
    }

    case AppLangs.EN:
    case AppLangs.TR:
    default: {
      doc.classList.remove('atira-arabic-font');
      doc.classList.add('atira-english-font');
      break;
    }
  }
}
