import { AtiraLinkPlan } from '../../model/plan/Plan';
import { AtiraLinkPlanBrand } from '../../model/plan/types/AtiraLinkPlanBrand.enum';
import { AtiraLinkPlanDesign } from '../../model/plan/types/AtiraLinkPlanDesign.enum';
import { AtiraLinkPlanEmbed } from '../../model/plan/types/AtiraLinkPlanEmbed.enum';
import { AtiraLinkPlanIndex } from '../../model/plan/types/AtiraLinkPlanIndex.enum';
import { AtiraLinkPlanLinksCount } from '../../model/plan/types/AtiraLinkPlanLinksCount.enum';
import { AtiraLinkPlanMobileApp } from '../../model/plan/types/AtiraLinkPlanMobileApp.enum';
import { AtiraLinkPlanQRCount } from '../../model/plan/types/AtiraLinkPlanQRCount.enum';
import { AtiraLinkPlanShortURLCount } from '../../model/plan/types/AtiraLinkPlanShortURLCount.enum';
import { AtiraLinkPlanStats } from '../../model/plan/types/AtiraLinkPlanStats.enum';
import { AtiraLinkPlanTheme } from '../../model/plan/types/AtiraLinkPlanTheme.enum';
import { AtiraLinkPlanType } from '../../model/plan/types/AtiraLinkPlanType.enum';

export const getPlanTitle = (key: AtiraLinkPlanType) => {
  switch (key) {
    case AtiraLinkPlanType.PLAN_0:
      return 'plan.type.free';
    case AtiraLinkPlanType.PLAN_1:
      return 'plan.type.basic';
    case AtiraLinkPlanType.PLAN_2:
      return 'plan.type.pro';
  }
};

export const translatePerks = (
  key: keyof AtiraLinkPlan['perks'],
  value: ValueOf<AtiraLinkPlan['perks']>,
) => {
  if (key === 'stats') {
    switch (value) {
      case AtiraLinkPlanStats.PLAN_0:
        return 'plan.stats.plan_0';
      case AtiraLinkPlanStats.PLAN_1:
        return 'plan.stats.plan_1';
      case AtiraLinkPlanStats.PLAN_2:
        return 'plan.stats.plan_2';
    }
  }

  if (key === 'themeCount') {
    switch (value) {
      case AtiraLinkPlanTheme.PLAN_0:
        return 'plan.theme.plan_0';
      case AtiraLinkPlanTheme.PLAN_1:
        return 'plan.theme.plan_1';
      case AtiraLinkPlanTheme.PLAN_2:
        return 'plan.theme.plan_2';
    }
  }

  if (key === 'embed') {
    switch (value) {
      case AtiraLinkPlanEmbed.PLAN_0:
        return 'plan.embed.plan_0';
      case AtiraLinkPlanEmbed.PLAN_1:
        return 'plan.embed.plan_1';
      case AtiraLinkPlanEmbed.PLAN_2:
        return 'plan.embed.plan_2';
    }
  }

  if (key === 'index') {
    switch (value) {
      case AtiraLinkPlanIndex.PLAN_0:
        return 'plan.index.plan_0';
      case AtiraLinkPlanIndex.PLAN_1:
        return 'plan.index.plan_1';
      case AtiraLinkPlanIndex.PLAN_2:
        return 'plan.index.plan_2';
    }
  }

  if (key === 'design') {
    switch (value) {
      case AtiraLinkPlanDesign.PLAN_0:
        return 'plan.design.plan_0';
      case AtiraLinkPlanDesign.PLAN_1:
        return 'plan.design.plan_1';
      case AtiraLinkPlanDesign.PLAN_2:
        return 'plan.design.plan_2';
    }
  }

  if (key === 'urlsCount') {
    switch (value) {
      case AtiraLinkPlanShortURLCount.PLAN_0:
        return 'plan.urlsCount.plan_0';
      case AtiraLinkPlanShortURLCount.PLAN_1:
        return 'plan.urlsCount.plan_1';
      case AtiraLinkPlanShortURLCount.PLAN_2:
        return 'plan.urlsCount.plan_2';
    }
  }

  if (key === 'qrCount') {
    switch (value) {
      case AtiraLinkPlanQRCount.PLAN_0:
        return 'plan.qrCount.plan_0';
      case AtiraLinkPlanQRCount.PLAN_1:
        return 'plan.qrCount.plan_1';
      case AtiraLinkPlanQRCount.PLAN_2:
        return 'plan.qrCount.plan_2';
    }
  }

  if (key === 'linkCount') {
    switch (value) {
      case AtiraLinkPlanLinksCount.PLAN_0:
        return 'plan.linkCount.plan_0';
      case AtiraLinkPlanLinksCount.PLAN_1:
        return 'plan.linkCount.plan_1';
      case AtiraLinkPlanLinksCount.PLAN_2:
        return 'plan.linkCount.plan_2';
    }
  }

  if (key === 'mobileApp') {
    switch (value) {
      case AtiraLinkPlanMobileApp.PLAN_0:
        return 'plan.mobileApp.plan_0';
      case AtiraLinkPlanMobileApp.PLAN_1:
        return 'plan.mobileApp.plan_1';
      case AtiraLinkPlanMobileApp.PLAN_2:
        return 'plan.mobileApp.plan_2';
    }
  }

  if (key === 'brand') {
    switch (value) {
      case AtiraLinkPlanBrand.PLAN_0:
        return 'plan.brand.plan_0';
      case AtiraLinkPlanBrand.PLAN_1:
        return 'plan.brand.plan_1';
      case AtiraLinkPlanBrand.PLAN_2:
        return 'plan.brand.plan_2';
    }
  }
};

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const toUSD = (cents: number) => {
  return USDollar.format(cents / 100);
};
