import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { adminSliceSelectors } from '../../../redux/admin/admin.selector';
import { useAppSelector } from '../../../redux/store';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Shadow } from '../../../theme/Shadow';
import { Spacing } from '../../../theme/Spacing';

const Container = styled(Flex)`
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;

  & > div:nth-child(odd) {
    margin-inline-end: ${Spacing.s};
  }
`;

const WidgetWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  min-width: 100%;
  max-width: 100%;
  flex-grow: 1;

  @media (min-width: ${Breakpoints.TABLET}) {
    min-width: 40%;
    max-width: 49%;
  }
`;

const Widget = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  height: 100%;
  flex-grow: 1;
  max-height: 14rem;
  padding: 1rem;
  box-shadow: ${Shadow.SM};
  margin-bottom: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.m};
`;

const Title = styled(Text)`
  font-weight: bold;
  color: ${({ theme }) => theme.textColor};
  padding: 0.2rem 0;
`;

const StyledText = styled(Text)`
  font-size: 1.2rem;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.4rem;
  }
`;

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.subTextColor};
`;

export const AdminStatsTab: React.FC = () => {
  const { t } = useTranslation();

  const stats = useAppSelector(adminSliceSelectors.selectAdminStats);

  return (
    <Container>
      <WidgetWrapper>
        <Title fontSize="l">{t('common.users')}</Title>

        <Widget>
          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faCircle} color="main" size="xs" />
            <StyledText color="main" wordBreak="break-word">
              {`${t('admin.users.total')} `}
              <StyledSpan>{stats?.users?.total ?? 0}</StyledSpan>
            </StyledText>
          </Flex>

          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faCircle} color="main" size="xs" />
            <StyledText color="main" wordBreak="break-word">
              {`${t('admin.users.last_week')} `}
              <StyledSpan>{stats?.users?.lastWeek ?? 0}</StyledSpan>
            </StyledText>
          </Flex>

          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faCircle} color="main" size="xs" />
            <StyledText color="main" wordBreak="break-word">
              {`${t('admin.users.last_month')} `}
              <StyledSpan>{stats?.users?.lastMonth ?? 0}</StyledSpan>
            </StyledText>
          </Flex>
        </Widget>
      </WidgetWrapper>

      <WidgetWrapper>
        <Title fontSize="l">{t('common.atira_links')}</Title>
        <Widget>
          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faCircle} color="main" size="xs" />
            <StyledText color="main" wordBreak="break-word">
              {`${t('admin.atira_link.total')} `}
              <StyledSpan>{stats?.atiraLinks?.total ?? 0}</StyledSpan>
            </StyledText>
          </Flex>

          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faCircle} color="main" size="xs" />
            <StyledText color="main" wordBreak="break-word">
              {`${t('admin.atira_link.last_week')} `}
              <StyledSpan>{stats?.atiraLinks?.lastWeek ?? 0}</StyledSpan>
            </StyledText>
          </Flex>

          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faCircle} color="main" size="xs" />
            <StyledText color="main" wordBreak="break-word">
              {`${t('admin.atira_link.last_month')} `}
              <StyledSpan>{stats?.atiraLinks?.lastMonth ?? 0}</StyledSpan>
            </StyledText>
          </Flex>
        </Widget>
      </WidgetWrapper>

      <WidgetWrapper>
        <Title fontSize="l">{t('common.short_urls')}</Title>
        <Widget>
          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faCircle} color="main" size="xs" />
            <StyledText color="main" wordBreak="break-word">
              {`${t('admin.url.total')} `}
              <StyledSpan>{stats?.QRs?.total ?? 0}</StyledSpan>
            </StyledText>
          </Flex>

          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faCircle} color="main" size="xs" />
            <StyledText color="main" wordBreak="break-word">
              {`${t('admin.url.last_week')} `}
              <StyledSpan>{stats?.QRs?.lastWeek ?? 0}</StyledSpan>
            </StyledText>
          </Flex>

          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faCircle} color="main" size="xs" />
            <StyledText color="main" wordBreak="break-word">
              {`${t('admin.url.last_month')} `}
              <StyledSpan>{stats?.QRs?.lastMonth ?? 0}</StyledSpan>
            </StyledText>
          </Flex>
        </Widget>
      </WidgetWrapper>

      <WidgetWrapper>
        <Title fontSize="l">{t('common.qrs')}</Title>
        <Widget>
          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faCircle} color="main" size="xs" />
            <StyledText color="main" wordBreak="break-word">
              {`${t('admin.qr.total')} `}
              <StyledSpan>{stats?.shortURLs?.total ?? 0}</StyledSpan>
            </StyledText>
          </Flex>

          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faCircle} color="main" size="xs" />
            <StyledText color="main" wordBreak="break-word">
              {`${t('admin.qr.last_week')} `}
              <StyledSpan>{stats?.shortURLs?.lastWeek ?? 0}</StyledSpan>
            </StyledText>
          </Flex>

          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faCircle} color="main" size="xs" />
            <StyledText color="main" wordBreak="break-word">
              {`${t('admin.qr.last_month')} `}
              <StyledSpan>{stats?.shortURLs?.lastMonth ?? 0}</StyledSpan>
            </StyledText>
          </Flex>
        </Widget>
      </WidgetWrapper>
    </Container>
  );
};
