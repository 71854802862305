import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faUpRightFromSquare';
import React from 'react';
import Frame from 'react-frame-component';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Flex } from '../../../components/Flex';
import i18n, { AppLangs } from '../../../i18n';
import { AtiraLink } from '../../../model/atira-link/AtiraLink';
import { AtiraLinkLanguageStrings } from '../../../model/atira-link/AtiraLinkLangStrings';
import { AtiraLinkPlanType } from '../../../model/plan/types/AtiraLinkPlanType.enum';
import { AtiraLinkLang } from '../../../model/settings/types/AtiraLinkLang.enum';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraLinkLeftSide } from '../../my-link/components/partials/AtiraLinkLeftSide';

const Container = styled(Flex)`
  flex-direction: column;
  aspect-ratio: 9 / 16;
  width: 100%;
  border: ${({ theme }) => `2px solid ${theme.sub}`};
  border-radius: ${Rounded.md};
  max-height: 33rem;
  position: relative;
  overflow: hidden;
`;

const BackgroundDiv = styled.div`
  z-index: -1;
`;

const StyledFrame = styled(Frame)`
  height: 100%;
  width: 100%;
  border: 0;
  border-radius: ${Rounded.md} ${Rounded.md} 0 0;
`;

const ProMark = styled(Flex)`
  position: absolute;
  top: 0;
  right: ${i18n.language === AppLangs.AR ? '0' : 'auto'};
  left: ${i18n.language === AppLangs.AR ? 'auto' : '0'};
  background-image: linear-gradient(315deg, #2b2694 0%, #960baa 100%);
  color: ${({ theme }) => theme.sub};
  font-size: 0.6rem;
  font-weight: bold;
  width: 3rem;
  aspect-ratio: 1 / 1;
  clip-path: ${i18n.language === AppLangs.AR
    ? 'polygon(0 0, 100% 0%, 100% 100%)'
    : 'polygon(0 0, 100% 0, 0% 100%)'};

  span {
    rotate: ${i18n.language === AppLangs.AR ? '45deg' : '315deg'};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: ${i18n.language === AppLangs.AR ? '50%' : 'auto'};
    left: ${i18n.language === AppLangs.AR ? 'auto' : '50%'};
    transform: translate(
      ${i18n.language === AppLangs.AR ? '35%' : '-35%'},
      -56%
    );
    font-size: 0.8rem;
  }
`;

const VisitLink = styled.a`
  background: ${({ theme }) => theme.sub};
  color: ${({ theme }) => theme.main};
  padding: ${Spacing.s} ${Spacing.m};
  font-size: 1.2rem;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.s};

  &:hover {
    color: ${({ theme }) => theme.textColor};
    background-color: ${({ theme }) => theme.darkSub};
  }
`;

type LinkCardProps = {
  link: AtiraLink;
};

export const LinkCard: React.FC<LinkCardProps> = ({ link }) => {
  const { t } = useTranslation();

  const linkLang = link?.settings?.lang?.current || AtiraLinkLang.EN;

  const getLang = (linkLang: AtiraLinkLang) => {
    const languages: Record<string, AtiraLinkLanguageStrings> = {
      en: {
        socialsTabTitle: 'Socials',
        embedTabTitle: 'Other',
        copy: 'Copy',
        shareViaWhatsapp: 'Share via Whatsapp',
        shareViaEmail: 'Share via Email',
        shareOnSocialMedia: 'Share on Social Media',
        reportThisLink: 'Report this link',
        downloadAsContactFile: 'Download as contact file (vcf)',
        scanQR: 'Scan this QR code to open the link',
        showFromMobile: 'Show from mobile',
        visitMyWebsite: 'Visit my website',
        sendAnEmail: 'Send an Email',
        makeACall: 'Make a Call',
        myLocation: 'My Location',
        getYourLink: 'Get Your Link @ atiralink.com',
      },
      ar: {
        socialsTabTitle: 'التواصل الاجتماعي',
        embedTabTitle: 'المزيد',
        copy: 'نسخ',
        shareViaWhatsapp: 'مشاركة عبر واتساب',
        shareViaEmail: 'مشاركة عبر البريد الإلكتروني',
        shareOnSocialMedia: 'مشاركة عبر مواقع التواصل الاجتماعي',
        reportThisLink: 'الإبلاغ عن هذا الرابط',
        downloadAsContactFile: 'تحميل كملف جهة اتصال (vcf)',
        scanQR: 'امسح رمز الـ QR لفتح الرابط',
        showFromMobile: 'عرض من موبايل',
        visitMyWebsite: 'قم بزيارة موقعي',
        sendAnEmail: 'ارسل بريدًا إلكترونيًا',
        makeACall: 'اتصل بي',
        myLocation: 'موقعي على الخريطة',
        getYourLink: 'احصل على رابطك @ atiralink.com',
      },
    };

    const lang =
      languages[(linkLang || 'en').toLowerCase() as keyof typeof languages];

    return lang;
  };

  const linkLanguageStrings = getLang(linkLang);

  const onVisitLink = () => {
    window.open(`https://atrl.ink/${link.linkName}`, '_blank');
  };

  return (
    <Container key={link._id} onClick={onVisitLink}>
      {link.user?.plan?.type === AtiraLinkPlanType.PLAN_2 ? (
        <ProMark>
          <span>PRO</span>
        </ProMark>
      ) : null}

      <StyledFrame
        mountTarget={`#root-${link._id}`}
        initialContent={`<!DOCTYPE html>
        <html lang="en" style="height: 100%; overflow: hidden">
        <head>
        ${
          i18n.language === AppLangs.AR
            ? '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.rtl.min.css" integrity="sha384-dpuaG1suU0eT09tx5plTaGMLBsfDLzUCCUXOY2j/LSvXYuG6Bqs43ALlhIqAJVRb" crossOrigin="anonymous" />'
            : '<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossOrigin="anonymous" />'
        }
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css" integrity="sha512-Kc323vGBEqzTmouAECnVceyQqyqdsSiqLQISBL29aUW4U/M7pSPA/gEUZQqv1cwx4OnYxTxve5UMg5GT6L4JJg==" crossorigin="anonymous" referrerpolicy="no-referrer">
          <link rel="stylesheet" type="text/css" href="/style/${link.theme?.toLowerCase()}.css">
        </head>
        <body style="height: 100%;">
          <div id="root-${link._id}" style="height: 100%;"></div>
        </body>
        </html>`}
      >
        <div className="atira-section py-5 d-flex align-items-center justify-content-center">
          <BackgroundDiv className="atira-link-bg position-absolute top-0 start-0 bottom-0 end-0"></BackgroundDiv>
          <div className="container">
            <div className="row justify-content-center">
              <div className={`col-12 d-flex flex-column gy-3`}>
                <AtiraLinkLeftSide
                  atiraLink={link}
                  linkLanguageStrings={linkLanguageStrings}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-10">
                <div className={`text-center`}>
                  <span className="footer-link py-2 px-3 text-decoration-none d-block">
                    {linkLanguageStrings.getYourLink}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledFrame>

      <VisitLink href={`https://atrl.ink/${link.linkName}`} target="_blank">
        {t('common.visit')}

        <AtiraIcon icon={faUpRightFromSquare} size="sm" />
      </VisitLink>
    </Container>
  );
};
