import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';

import { Flex } from '../components/Flex';
import { PublicFooter } from './PublicFooter';
import { PublicHeader } from './PublicHeader';

export const PublicLayout: React.FC = () => {
  const location = useLocation();

  const hideFooter =
    location.pathname.includes('forgot-password') ||
    location.pathname.includes('login') ||
    location.pathname.includes('signup');

  return (
    <Flex flexDirection="column" flexGrow={1}>
      <PublicHeader />
      <Content>
        <Outlet />
      </Content>
      <ScrollRestoration />
      {!hideFooter ? <PublicFooter /> : null}
    </Flex>
  );
};
