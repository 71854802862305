import { faEllipsis } from '@fortawesome/free-solid-svg-icons/faEllipsis';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons/faPenToSquare';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { Dropdown, MenuProps, TableProps } from 'antd';
import { TFunction } from 'i18next';
import capitalize from 'lodash/capitalize';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { TextWithTruncated } from '../../../components/TextWithTruncated';
import { AtiraLinkCategory } from '../../../model/atira-link/AtiraLinkCategory';
import { Spacing } from '../../../theme/Spacing';

const MenuButton = styled(Button)`
  margin: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
`;

type FieldFunctions = {
  t: TFunction;
  delete: ATVoidFunction<AtiraLinkCategory>;
  edit: ATVoidFunction<AtiraLinkCategory>;
};

const StyledActionButton = styled(Button)`
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: ${Spacing.s};
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
`;

const getFieldWidth = (field: keyof AtiraLinkCategory | 'actions') => {
  switch (field) {
    case 'actions':
      return '5%';
    case 'name':
      return 150;
    case 'description':
      return 200;

    default:
      return 100;
  }
};

const getFieldInfo = (
  column: keyof AtiraLinkCategory | 'actions',
  functions: FieldFunctions,
  indexes: AtiraLinkCategory[],
) => {
  const { t, edit, delete: deleteFn } = functions;

  switch (column) {
    case 'actions':
      return {
        title: t('common.actions'),
        key: 'table-actions',
        data: column,
        render: (category: AtiraLinkCategory) => {
          const items: MenuProps['items'] = [
            {
              key: 'table-g1',
              type: 'group',
              children: [
                {
                  key: '1',
                  label: (
                    <StyledActionButton onClick={() => edit(category)}>
                      <Flex gap="s" alignItems="center">
                        <AtiraIcon icon={faPenToSquare} color="main" />
                        <Text color="main">{t('common.edit')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                },

                {
                  key: 'table-g7',
                  label: (
                    <StyledActionButton
                      onClick={() => deleteFn(category)}
                      disabled
                    >
                      <Flex gap="s" alignItems="center">
                        <AtiraIcon icon={faTrashCan} color="main" />
                        <Text color="main">{t('common.delete')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                },
              ],
            },
          ];

          return (
            <Dropdown menu={{ items }} trigger={['click']}>
              <MenuButton icon={faEllipsis} />
            </Dropdown>
          );
        },
        fixed: 'left',
        width: getFieldWidth(column),
      };

    case 'name':
      return {
        title: t('common.name'),
        key: 'table-name',
        data: column,
        render: (index: AtiraLinkCategory) => {
          return (
            <TextWithTruncated length={40}>
              {capitalize(index.name)}
            </TextWithTruncated>
          );
        },
        width: getFieldWidth(column),
      };

    case 'availableTo':
      return {
        title: t('common.availableTo'),
        key: 'table-availableTo',
        data: column,
        render: (index: AtiraLinkCategory) => {
          return <Text>{index.availableTo.map((v) => v).join(' - ')}</Text>;
        },
        width: getFieldWidth(column),
      };

    case 'description':
      return {
        title: t('common.description'),
        key: 'table-description',
        data: column,
        render: (index: AtiraLinkCategory) => {
          return (
            <TextWithTruncated length={30}>
              {index.description || ''}
            </TextWithTruncated>
          );
        },
        width: getFieldWidth(column),
      };

    case 'parentCategoryId':
      return {
        title: t('admin.index.table.parent-category'),
        key: 'table-parentCategoryId',
        data: column,
        render: (index: AtiraLinkCategory) => {
          return index.parentCategoryId ? (
            <Text>
              {indexes.find(({ _id }) => _id === index.parentCategoryId)?.name}
            </Text>
          ) : null;
        },
        width: getFieldWidth(column),
      };

    case 'subCategories':
      return {
        title: t('admin.index.table.sub-category.count'),
        key: 'table-subCategories',
        data: column,
        render: (index: AtiraLinkCategory) => {
          return index.subCategories?.length ? (
            <Text>{index.subCategories?.length}</Text>
          ) : null;
        },
        width: getFieldWidth(column),
      };
  }
};

export function createAdminIndexTableColumns(
  indexes: AtiraLinkCategory[],
  functions: FieldFunctions,
) {
  const tableColumnsKeys: (keyof AtiraLinkCategory | 'actions')[] = [
    'actions',
    'name',
    'availableTo',
    'description',
    'parentCategoryId',
    'subCategories',
  ];

  return tableColumnsKeys.map((c) =>
    getFieldInfo(c, functions, indexes),
  ) as TableProps<AtiraLinkCategory>['columns'];
}
