import { Fragment } from 'react';

import { LandingPageFeaturesSection } from './sections/LandingPageFeaturesSection';
import { LandingPageHeroSection } from './sections/LandingPageHeroSection';
import { LandingPageLatestUsers } from './sections/LandingPageLatestUsersSection';

export const LandingPage: React.FC = () => {
  return (
    <Fragment>
      <LandingPageHeroSection />
      <LandingPageFeaturesSection />
      <LandingPageLatestUsers />
    </Fragment>
  );
};
