import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { Tooltip, TooltipProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ThemeType } from '../theme/Theme';
import { AtiraToast } from '../utils/AtiraToast';
import { Button } from './Button';

type CopyButtonProps = {
  copy: string;
  text?: string;
  icon?: IconProp;
  iconSize?: SizeProp;
  color?: keyof ThemeType;
  showText?: boolean;
} & Partial<TooltipProps>;

export const CopyButton: React.FC<CopyButtonProps> = ({
  copy,
  text,
  icon,
  iconSize,
  color,
  showText = false,
  ...props
}) => {
  const { t } = useTranslation();

  const onCopy = () => {
    navigator.clipboard.writeText(copy);
    AtiraToast.success(t('shorten_url.copy.success'));
  };

  return (
    <Tooltip title={text || t('common.copy')} {...props}>
      <Button
        onClick={onCopy}
        backgroundColor="transparent"
        icon={icon || faCopy}
        iconWidth={iconSize || '2x'}
        color={color || 'subTextColor'}
      >
        {showText ? t('common.copy') : null}
      </Button>
    </Tooltip>
  );
};
