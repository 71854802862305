import React from 'react';
import styled from 'styled-components';

import { Breakpoints } from '../theme/Breakpoints';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';
import { AtiraColumn } from './AtiraColumn';
import { AtiraRow } from './AtiraRow';
import { Button } from './Button';
import { Flex } from './Flex';
import { AtiraImage } from './Image';
import { Text } from './Text';

const Container = styled.section`
  display: flex;
  background: linear-gradient(90deg, #2b2694 0%, #960baa 100%);
  padding-top: ${Spacing.m};
  padding-inline: ${Spacing.s};

  @media (min-width: ${Breakpoints.TABLET}) {
    padding-top: ${Spacing.l};
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
  }
`;

const CallToAction = styled(Button)`
  color: ${(props) => props.theme.main};
  background-color: ${(props) => props.theme.sub};
  width: fit-content;
  border-radius: ${Rounded.md};
  text-align: center;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.25rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.darkSub};
    color: ${({ theme }) => theme.textColor};
  }
`;

const ImageWrapper = styled(Flex)<{
  imageMaxWidth: React.CSSProperties['maxWidth'];
}>`
  margin-inline-start: auto;
  margin-bottom: -${Spacing.xl};

  .ant-image {
    width: 100% !important;
  }

  @media (min-width: ${Breakpoints.TABLET}) {
    max-width: ${({ imageMaxWidth }) => imageMaxWidth};
  }
`;

type PageHeroSectionProps = {
  title: string;
  subTitle?: string;
  description: string;
  moreDescription?: string;
  image: string;
  onClick?: VoidFunction;
  c2aTitle?: string;
  imageMaxWidth?: React.CSSProperties['maxWidth'];
};

export const PageHeroSection: React.FC<PageHeroSectionProps> = ({
  title,
  subTitle,
  description,
  moreDescription,
  image,
  onClick,
  c2aTitle,
  imageMaxWidth = '70%',
}) => {
  return (
    <Container>
      <AtiraRow justify={'center'}>
        <AtiraColumn xs={24} sm={24} md={22} lg={20} xl={20}>
          <AtiraRow justify={'space-between'} align={'middle'} gutter={[0, 30]}>
            <AtiraColumn xs={24} sm={24} md={12} lg={12} xl={12}>
              <Flex flexDirection="column" gap="l" padding="s">
                <Text fontSize="xxl" fontWeight={'bold'} color="sub" as={'h1'}>
                  {title}
                </Text>

                {subTitle ? (
                  <Text fontSize="xl" fontWeight={'bold'} color="sub" as={'h2'}>
                    {subTitle}
                  </Text>
                ) : null}

                <Text fontSize="xm" color="sub" lineHeight={'1.75rem'}>
                  {description}
                </Text>

                <Text fontSize="xm" color="sub" lineHeight={'1.75rem'}>
                  {moreDescription}
                </Text>

                {onClick && c2aTitle ? (
                  <CallToAction onClick={onClick}>{c2aTitle}</CallToAction>
                ) : null}
              </Flex>
            </AtiraColumn>

            <AtiraColumn xs={24} sm={24} md={12} lg={12} xl={12}>
              <ImageWrapper imageMaxWidth={imageMaxWidth || '70%'}>
                <AtiraImage src={image} />
              </ImageWrapper>
            </AtiraColumn>
          </AtiraRow>
        </AtiraColumn>
      </AtiraRow>
    </Container>
  );
};
