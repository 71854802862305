import { faCircleUser } from '@fortawesome/free-solid-svg-icons/faCircleUser';
import isString from 'lodash/isString';
import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import i18n, { AppLangs } from '../../../i18n';
import { AtiraLink } from '../../../model/atira-link/AtiraLink';
import { AtiraLinkLanguageStrings } from '../../../model/atira-link/AtiraLinkLangStrings';
import { CreateAtiraLinkDto } from '../../../model/atira-link/dto/CreateAtiraLinkDto';
import { EditAtiraLinkDto } from '../../../model/atira-link/dto/EditAtiraLinkDto';
import { AtiraLinkLang } from '../../../model/settings/types/AtiraLinkLang.enum';
import { getMediaUrl } from '../../../utils/Media';
import { TemplatePreviewMode } from '../../shared/model/TemplatePreviewMode.enum';
import { AtiraLinkLeftSide } from './partials/AtiraLinkLeftSide';
import { AtiraLinkRightSide } from './partials/AtiraLinkRightSide';
import { AtiraLinkSideMenu } from './partials/AtiraLinkSideMenu';

const PhotoWrapper = styled.div`
  svg {
    width: 100%;
    height: 100%;
  }
`;

const BackgroundDiv = styled.div`
  z-index: -1;
`;

const FooterLink = styled.a<{ previewMode?: TemplatePreviewMode }>`
  width: ${({ previewMode }) =>
    previewMode === TemplatePreviewMode.MOBILE ? '100%' : 'fit-content'};
`;

const getLang = (linkLang: AtiraLinkLang) => {
  const languages: Record<string, AtiraLinkLanguageStrings> = {
    en: {
      socialsTabTitle: 'Socials',
      embedTabTitle: 'Other',
      copy: 'Copy',
      shareViaWhatsapp: 'Share via Whatsapp',
      shareViaEmail: 'Share via Email',
      shareOnSocialMedia: 'Share on Social Media',
      reportThisLink: 'Report this link',
      downloadAsContactFile: 'Download as contact file (vcf)',
      scanQR: 'Scan this QR code to open the link',
      showFromMobile: 'Show from mobile',
      visitMyWebsite: 'Visit my website',
      sendAnEmail: 'Send an Email',
      makeACall: 'Make a Call',
      myLocation: 'My Location',
      getYourLink: 'Get Your Link @ atiralink.com',
    },
    ar: {
      socialsTabTitle: 'التواصل الاجتماعي',
      embedTabTitle: 'المزيد',
      copy: 'نسخ',
      shareViaWhatsapp: 'مشاركة عبر واتساب',
      shareViaEmail: 'مشاركة عبر البريد الإلكتروني',
      shareOnSocialMedia: 'مشاركة عبر مواقع التواصل الاجتماعي',
      reportThisLink: 'الإبلاغ عن هذا الرابط',
      downloadAsContactFile: 'تحميل كملف جهة اتصال (vcf)',
      scanQR: 'امسح رمز الـ QR لفتح الرابط',
      showFromMobile: 'عرض من موبايل',
      visitMyWebsite: 'قم بزيارة موقعي',
      sendAnEmail: 'ارسل بريدًا إلكترونيًا',
      makeACall: 'اتصل بي',
      myLocation: 'موقعي على الخريطة',
      getYourLink: 'احصل على رابطك @ atiralink.com',
    },
  };

  const lang =
    languages[(linkLang || 'en').toLowerCase() as keyof typeof languages];

  return lang;
};

type AtiraLinkPreviewPhoneEJSProps = {
  atiraLink?:
    | AtiraLink
    | CreateAtiraLinkDto
    | EditAtiraLinkDto
    | Partial<AtiraLink>;
  currentLinkLang?: AtiraLinkLang;
  previewMode?: TemplatePreviewMode;
  isPreviewMode?: boolean;
};

export const AtiraLinkPreviewPhoneEJS: React.FC<
  AtiraLinkPreviewPhoneEJSProps
> = ({
  atiraLink,
  currentLinkLang,
  previewMode,
  isPreviewMode = TemplatePreviewMode.MOBILE,
}) => {
  const previewBodyRef = useRef<HTMLDivElement>(null);
  const previewSidemenuRef = useRef<HTMLDivElement>(null);

  const linkLang =
    currentLinkLang || atiraLink?.settings?.lang?.current || AtiraLinkLang.EN;

  const isArabic = linkLang === AtiraLinkLang.AR;

  const linkLanguageStrings = getLang(linkLang);

  const shouldFlip = i18n.language === AppLangs.AR && !isArabic;

  const getCardColumnSize = () => {
    switch (previewMode) {
      case TemplatePreviewMode.TABLET:
        return 'col-6';
      case TemplatePreviewMode.DESKTOP:
        return 'col-5';
      case TemplatePreviewMode.MOBILE:
      default:
        return 'col-12';
    }
  };

  const openDrawer = (sideMenuRef: React.RefObject<HTMLDivElement>) => {
    const sideMenu = sideMenuRef.current;

    if (!sideMenu) {
      return;
    }

    if (previewBodyRef.current) {
      previewBodyRef.current.classList.remove('overflow-y-auto');
      previewBodyRef.current.classList.remove('overflow-x-hidden');
    }

    sideMenu.setAttribute('aria-hidden', 'false');
    sideMenu.style.visibility = 'visible';
    sideMenu.parentElement?.style.setProperty('pointer-events', 'auto');
    sideMenu.parentElement?.style.setProperty(
      'background-color',
      'rgba(0, 0, 0, 0.5)',
    );

    sideMenu.style.transform = 'translateX(0%)';
  };

  const closeDrawer = (sideMenuRef: React.RefObject<HTMLDivElement>) => {
    const sideMenu = sideMenuRef.current;

    if (!sideMenu) {
      return;
    }

    if (previewBodyRef.current) {
      previewBodyRef.current.classList.add('overflow-y-auto');
      previewBodyRef.current.classList.add('overflow-x-hidden');
    }

    sideMenu.setAttribute('aria-hidden', 'true');
    sideMenu.parentElement?.style.setProperty('pointer-events', 'none');
    sideMenu.parentElement?.style.setProperty(
      'background-color',
      'transparent',
    );

    sideMenu.style.transform = 'translateX(100%)';
  };

  const toggleDrawerVisible = () => {
    if (previewBodyRef.current) {
      if (previewSidemenuRef.current) {
        const isSidemenuOpen =
          previewSidemenuRef.current.getAttribute('aria-hidden') === 'false';

        if (isSidemenuOpen) {
          closeDrawer(previewSidemenuRef);
          return;
        }

        openDrawer(previewSidemenuRef);
      }
    }
  };

  return (
    <div
      dir={
        linkLang?.toLowerCase() === AtiraLinkLang.AR.toLowerCase()
          ? 'rtl'
          : 'ltr'
      }
      className="h-100"
    >
      <Helmet>
        {isArabic || i18n.language === AppLangs.AR ? (
          <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.rtl.min.css"
            integrity="sha384-dpuaG1suU0eT09tx5plTaGMLBsfDLzUCCUXOY2j/LSvXYuG6Bqs43ALlhIqAJVRb"
            crossOrigin="anonymous"
          ></link>
        ) : (
          <link
            href={`https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css`}
            rel="stylesheet"
            integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH"
            crossOrigin="anonymous"
          />
        )}

        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css"
          integrity="sha512-Kc323vGBEqzTmouAECnVceyQqyqdsSiqLQISBL29aUW4U/M7pSPA/gEUZQqv1cwx4OnYxTxve5UMg5GT6L4JJg=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href={`/style/${atiraLink?.theme?.toLowerCase() || 'basic'}.css`}
        />
      </Helmet>
      <div
        ref={previewBodyRef}
        style={{ maxHeight: !isPreviewMode ? '70vh' : '' }}
        className={`atira-link-body bg-white position-relative overflow-x-hidden overflow-y-auto h-100`}
      >
        <header className="py-3 shadow-sm sticky-top zindex-5 bg-white">
          <div className="container">
            <div className="row justify-content-center">
              <div
                className={
                  previewMode === TemplatePreviewMode.DESKTOP
                    ? 'col-10'
                    : 'col-12'
                }
              >
                <div className={`d-flex`}>
                  <div className={`d-flex w-75 align-items-center gap-3`}>
                    {atiraLink?.image ? (
                      <img
                        src={
                          isString(atiraLink.image)
                            ? getMediaUrl(atiraLink.image! as string)
                            : URL.createObjectURL(atiraLink.image! as any)
                        }
                        className="header-photo object-fit-cover"
                        alt={atiraLink.title}
                      />
                    ) : (
                      <PhotoWrapper className="header-photo">
                        <AtiraIcon icon={faCircleUser} />
                      </PhotoWrapper>
                    )}
                    <span className="header-title text-truncate">
                      {atiraLink?.title}
                    </span>
                  </div>
                  <button
                    className={`navbar-toggler ${shouldFlip ? 'me-auto' : 'ms-auto'}`}
                    onClick={toggleDrawerVisible}
                  >
                    <i className="fa-solid fa-bars fs-5"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>

        <main className="atira-section py-5 d-flex align-items-center justify-content-center">
          <BackgroundDiv className="atira-link-bg position-absolute top-0 start-0 bottom-0 end-0"></BackgroundDiv>
          <div className="container">
            <div className="row justify-content-center">
              <div className={`${getCardColumnSize()} d-flex flex-column gy-3`}>
                <AtiraLinkLeftSide
                  previewMode={previewMode}
                  atiraLink={atiraLink}
                  linkLanguageStrings={linkLanguageStrings}
                />
              </div>
              {atiraLink?.socials?.length || atiraLink?.embed?.length ? (
                <AtiraLinkRightSide
                  linkLanuageStrings={linkLanguageStrings}
                  socials={atiraLink.socials}
                  embed={atiraLink.embed}
                  previewMode={previewMode}
                />
              ) : null}
            </div>
          </div>
        </main>

        <footer className="py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-10">
                <div
                  className={`${previewMode === TemplatePreviewMode.MOBILE ? 'text-center' : 'text-end'}`}
                >
                  <FooterLink
                    previewMode={previewMode}
                    href="https://www.atiralink.com"
                    className="footer-link py-2 px-3 text-decoration-none d-block"
                  >
                    {linkLanguageStrings.getYourLink}
                  </FooterLink>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <AtiraLinkSideMenu
          isArabic={isArabic}
          linkLanguageStrings={linkLanguageStrings}
          ref={previewSidemenuRef}
          title={atiraLink?.title}
          linkName={(atiraLink as AtiraLink)?.linkName}
          onClose={() => closeDrawer(previewSidemenuRef)}
        />

        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz"
          crossOrigin="anonymous"
          defer
        ></script>
        <script src="/public/atira-link/script.js" defer></script>
      </div>
    </div>
  );
};
