import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../../assets';
import { AtiraCollapse } from '../../components/AtiraCollapse';
import { AtiraColumn } from '../../components/AtiraColumn';
import { AtiraRow } from '../../components/AtiraRow';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { PageHeroSection } from '../../components/PageHeroSection';
import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';
import { Text } from '../../components/Text';
import { AtiraLinkPlanType } from '../../model/plan/types/AtiraLinkPlanType.enum';
import { LoginWithGoogleReturnPath } from '../../model/user/types/LoginWithGoogleReturnPath.enum';
import { AtiraLinkActions } from '../../redux/atira-link/atira-link.slice';
import { paymentActions } from '../../redux/payment/payment.slice';
import { useAppDispatch } from '../../redux/store';
import { userActions } from '../../redux/user/user.slice';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';
import { AtiraToast } from '../../utils/AtiraToast';
import { PublicPricingPlans } from '../shared/components/PublicPricingPlans';

const FAQItems = [
  {
    key: '1',
    label: 'Question 1',
    children:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod.',
  },
  {
    key: '2',
    label: 'Question 2',
    children:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod.',
  },
  {
    key: '3',
    label: 'Question 3',
    children:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod.',
  },
  {
    key: '4',
    label: 'Question 4',
    children:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod.',
  },
];

const HeroSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: ${Spacing.l};
  justify-content: center;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${Images.TransparentColoredBackground});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.2;
    z-index: -1;
  }
`;

const PlansSection = styled(HeroSection)`
  padding-top: ${Spacing.xxl};
  padding-bottom: ${Spacing.m};

  @media (min-width: ${Breakpoints.TABLET}) {
    padding-inline: ${Spacing.m};
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    padding-inline: 0;
  }
`;

const Container = styled(Flex)`
  flex: 1;
  flex-direction: column;
`;

const PaidPlansSection = styled(HeroSection)`
  padding-block: ${Spacing.m};
  margin-bottom: 0;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    padding-inline: 0;
  }

  @media (min-width: ${Breakpoints.TABLET}) {
    padding-inline: ${Spacing.m};
  }
`;

const StyledCollapse = styled(AtiraCollapse)`
  background-color: ${({ theme }) => theme.sub};

  .ant-collapse-header {
    color: ${({ theme }) => theme.main} !important;
    font-size: 1.1rem;
  }

  .ant-collapse-item {
    border-color: ${({ theme }) => theme.main} !important;
  }

  .ant-collapse-content {
    border-color: transparent !important;
  }

  .ant-collapse-expand-icon svg {
    fill: ${({ theme }) => theme.main} !important;
  }
`;

const SectionTitle = styled(Text)`
  font-weight: bold;
  color: ${({ theme }) => theme.textColor};
  text-align: center;
  font-size: 1.4rem;
  height: fit-content;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.75rem;
  }
`;

const AbsoluteContainer = styled(Flex)`
  z-index: 9999;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.darkerSub};
  opacity: 0.7;
`;

export const Pricing: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams, _] = useSearchParams();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // This only runs after a google login redirect
    const verifyAndLogUser = async (code: string) => {
      try {
        setLoading(true);
        const { user } = await dispatch(
          userActions.loginWithGoogle({
            code,
            returnPath: LoginWithGoogleReturnPath.PRICING,
          }),
        ).unwrap();
        const plans = await dispatch(paymentActions.getAllPlans()).unwrap();

        if (user) {
          await dispatch(
            AtiraLinkActions.getUserAtiraLinks({ userId: user._id }),
          ).unwrap();

          localStorage.setItem(
            'processCheckout',
            JSON.stringify(
              (user?.plan?.freeTrial?.hasUsed &&
                user.plan?.freeTrial?.endsAt !== null) ||
                user.plan?.type === AtiraLinkPlanType.PLAN_0,
            ),
          );
          localStorage.setItem(
            'plan',
            JSON.stringify(
              plans.find(
                ({ lemonVariantId }) =>
                  lemonVariantId === localStorage.getItem('processCheckoutId')!,
              ),
            ),
          );

          navigate('/subscription');
        }
      } catch (error) {
        console.log(error);
        AtiraToast.error(t('common.wrong_contact_support'));
      } finally {
        setLoading(false);
      }
    };

    const verificationCode = searchParams.get('code');
    if (verificationCode) {
      searchParams.set('code', '');
      verifyAndLogUser(verificationCode);
    }
  }, [dispatch, navigate, searchParams, t]);

  useEffect(() => {
    const targetSection = searchParams.get('targetSection');
    if (targetSection) {
      const el = document.getElementById(targetSection);
      if (el) {
        const headerOffset = 75;
        const elementPosition = el.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [searchParams]);

  return (
    <Container>
      {loading ? (
        <AbsoluteContainer>
          <SpinnerFullScreen />
        </AbsoluteContainer>
      ) : null}

      <PageHeroSection
        title={t('pricing.hero.title')}
        subTitle={t('pricing.hero.description')}
        description={t('pricing.hero.paragraph')}
        image={Images.PricingHeroImage}
        c2aTitle={t('common.signupForFreeNow')}
        onClick={() => navigate('/signup')}
        imageMaxWidth={'85%'}
      />

      <PlansSection id="plans-section">
        <SectionTitle as="h2">{t('pricing.plans.title')}</SectionTitle>

        <AtiraRow justify={'center'}>
          <AtiraColumn xs={24} sm={24} md={24} lg={22} xl={22}>
            <PublicPricingPlans />
          </AtiraColumn>
        </AtiraRow>
      </PlansSection>

      <PaidPlansSection>
        <SectionTitle as={'h2'}>{t('pricing.faq.title')}</SectionTitle>

        <AtiraRow align={'middle'} justify={'center'} gutter={[20, 20]}>
          <AtiraColumn sm={24} md={10} lg={10} xl={10}>
            <AtiraImage src={Images.PricingFAQImage} width={'100%'} />
          </AtiraColumn>

          <AtiraColumn sm={24} md={12} lg={12} xl={12}>
            <Flex flexDirection="column" gap="m">
              <StyledCollapse bordered items={FAQItems} accordion />
            </Flex>
          </AtiraColumn>
        </AtiraRow>
      </PaidPlansSection>
    </Container>
  );
};
