import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import VerificationInput from 'react-verification-input';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { ForgotPasswordTokenDto } from '../../../model/user/dto/ForgotPasswordTokenDto';
import { AtiraLinkActions } from '../../../redux/atira-link/atira-link.slice';
import { useAppDispatch } from '../../../redux/store';
import { userActions } from '../../../redux/user/user.slice';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { onNavigateWithRedirect } from '../../../utils/OnNavigateWithRedirect';

const InputWrapper = styled(Flex)<{ valid: boolean }>`
  width: 100%;
  margin-top: ${Spacing.xl};

  .vi__container {
    width: 100% !important;
    direction: ltr;
  }

  .vi__character {
    border: ${({ theme, valid }) =>
      `2px solid ${valid ? theme.darkSub : theme.red}`} !important;
    background-color: ${({ theme }) => theme.transparent} !important;
    border-radius: ${Rounded.lg} !important;
  }

  .vi__character--filled {
    border-color: ${({ theme }) => theme.green} !important;
  }
`;

const StyledButton = styled(Button)`
  height: 3.5rem;
  width: 100%;
  background-color: ${({ theme }) => theme.main};
  font-size: 1rem;
  transition: background-color 0.2s;
  margin: 0;
  margin-top: ${Spacing.m};

  &:hover {
    background-color: ${(props) => props.theme.lightMain};
  }
`;

export const SignupToken: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, getValues } =
    useFormContext<ForgotPasswordTokenDto>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onNext = async () => {
    try {
      setLoading(true);
      const token = getValues('token');

      const { user } = await dispatch(
        userActions.verifyUserAccount({ token }),
      ).unwrap();
      await dispatch(
        AtiraLinkActions.getUserAtiraLinks({ userId: user?._id }),
      ).unwrap();

      onNavigateWithRedirect(navigate);
      AtiraToast.success(t('welcome_message'));
    } catch (e: any) {
      AtiraToast.apiError(e, { autoClose: 6000 });
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width={'100%'}
      gap="s"
    >
      <Text color="textColor">{t('signup.verification.token')}</Text>
      <Controller
        name="token"
        control={control}
        rules={{ required: true, minLength: 6 }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <InputWrapper valid={!errors.token}>
            <VerificationInput
              placeholder={''}
              onChange={onChange}
              value={value}
            />
          </InputWrapper>
        )}
      />
      <StyledButton onClick={handleSubmit(onNext)} loading={loading}>
        {t('signup.verification.button')}
      </StyledButton>
    </Flex>
  );
};
