import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Images } from '../../assets';
import { AtiraColumn } from '../../components/AtiraColumn';
import { AtiraRow } from '../../components/AtiraRow';
import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';

const Container = styled(Flex)`
  flex: 1;
  flex-direction: column;
  background-color: ${({ theme }) => theme.sub};

  @media (min-width: ${Breakpoints.TABLET}) {
    padding-inline: ${Spacing.m};
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    padding-inline: 0;
  }
`;

const Section = styled(Flex)`
  padding: ${Spacing.l} 0;
`;

const HeroSection = styled(Section)`
  position: relative;
  z-index: 1;
  min-height: 90vh;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${Images.TransparentColoredBackground});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.2;
    z-index: -1;
  }
`;

const ContentWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};
`;

const HeroContentWrapper = styled(ContentWrapper)`
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    max-width: 50rem;
  }
`;

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.s};
  margin-inline-start: ${Spacing.m};
`;

export const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'privacyPolicy' });

  return (
    <Container>
      <HeroSection>
        <AtiraRow justify={'center'} gutter={[20, 20]}>
          <AtiraColumn xs={24} sm={24} md={24} lg={22} xl={22}>
            <HeroContentWrapper>
              <Text fontSize="xxxl" fontWeight={'bold'} align="center">
                {t('hero.title')}
              </Text>

              <Text align="center" fontSize="xm">
                {t('hero.paragraph')}
              </Text>
            </HeroContentWrapper>
          </AtiraColumn>
        </AtiraRow>
      </HeroSection>

      <Section>
        <AtiraRow justify={'center'} gutter={[20, 20]}>
          <AtiraColumn xs={24} sm={24} md={24} lg={22} xl={22}>
            <ContentWrapper>
              <Text fontSize="xl" fontWeight={'bold'}>
                {t('infoCollect.title')}
              </Text>

              <Text fontSize="xm">{t('infoCollect.paragraph')}</Text>

              <StyledList>
                <li>
                  <Text>
                    <Trans
                      i18nKey={'privacyPolicy.infoCollect.list.1'}
                      values={{ title: t('infoCollect.list.1.title') }}
                      components={[<strong />]}
                    />
                  </Text>
                </li>

                <li>
                  <Text>
                    <Trans
                      i18nKey={'privacyPolicy.infoCollect.list.2'}
                      values={{ title: t('infoCollect.list.2.title') }}
                      components={[<strong />]}
                    />
                  </Text>
                </li>

                <li>
                  <Text>
                    <Trans
                      i18nKey={'privacyPolicy.infoCollect.list.3'}
                      values={{ title: t('infoCollect.list.3.title') }}
                      components={[<strong />]}
                    />
                  </Text>
                </li>
              </StyledList>
            </ContentWrapper>
          </AtiraColumn>
        </AtiraRow>
      </Section>

      <Section>
        <AtiraRow justify={'center'} gutter={[20, 20]}>
          <AtiraColumn xs={24} sm={24} md={24} lg={22} xl={22}>
            <ContentWrapper>
              <Text fontSize="xl" fontWeight={'bold'}>
                {t('infoUse.title')}
              </Text>

              <Text fontSize="xm">{t('infoUse.paragraph')}</Text>

              <StyledList>
                <li>
                  <Text>{t('infoUse.list.1')}</Text>
                </li>

                <li>
                  <Text>{t('infoUse.list.2')}</Text>
                </li>

                <li>
                  <Text>{t('infoUse.list.3')}</Text>
                </li>

                <li>
                  <Text>{t('infoUse.list.4')}</Text>
                </li>
              </StyledList>
            </ContentWrapper>
          </AtiraColumn>
        </AtiraRow>
      </Section>

      <Section>
        <AtiraRow justify={'center'} gutter={[20, 20]}>
          <AtiraColumn xs={24} sm={24} md={24} lg={22} xl={22}>
            <ContentWrapper>
              <Text fontSize="xl" fontWeight={'bold'}>
                {t('infoShare.title')}
              </Text>

              <Text fontSize="xm">{t('infoShare.paragraph')}</Text>

              <StyledList>
                <li>
                  <Text>{t('infoShare.list.1')}</Text>
                </li>

                <li>
                  <Text>{t('infoShare.list.2')}</Text>
                </li>
              </StyledList>
            </ContentWrapper>
          </AtiraColumn>
        </AtiraRow>
      </Section>

      <Section>
        <AtiraRow justify={'center'} gutter={[20, 20]}>
          <AtiraColumn xs={24} sm={24} md={24} lg={22} xl={22}>
            <ContentWrapper>
              <Text fontSize="xl" fontWeight={'bold'}>
                {t('rights.title')}
              </Text>

              <Text fontSize="xm">{t('rights.paragraph')}</Text>

              <StyledList>
                <li>
                  <Text>{t('rights.list.1')}</Text>
                </li>

                <li>
                  <Text>{t('rights.list.2')}</Text>
                </li>

                <li>
                  <Text>{t('rights.list.3')}</Text>
                </li>
              </StyledList>
            </ContentWrapper>
          </AtiraColumn>
        </AtiraRow>
      </Section>

      <Section>
        <AtiraRow justify={'center'} gutter={[20, 20]}>
          <AtiraColumn xs={24} sm={24} md={24} lg={22} xl={22}>
            <ContentWrapper>
              <Text fontSize="xl" fontWeight={'bold'}>
                {t('dataSecurity.title')}
              </Text>

              <Text fontSize="xm">{t('dataSecurity.paragraph')}</Text>
            </ContentWrapper>
          </AtiraColumn>
        </AtiraRow>
      </Section>

      <Section>
        <AtiraRow justify={'center'} gutter={[20, 20]}>
          <AtiraColumn xs={24} sm={24} md={24} lg={22} xl={22}>
            <ContentWrapper>
              <Text fontSize="xl" fontWeight={'bold'}>
                {t('thirdPartyLinks.title')}
              </Text>

              <Text fontSize="xm">{t('thirdPartyLinks.paragraph.1')}</Text>

              <Text fontSize="xm">{t('thirdPartyLinks.paragraph.2')}</Text>
            </ContentWrapper>
          </AtiraColumn>
        </AtiraRow>
      </Section>

      <Section>
        <AtiraRow justify={'center'} gutter={[20, 20]}>
          <AtiraColumn xs={24} sm={24} md={24} lg={22} xl={22}>
            <ContentWrapper>
              <Text fontSize="xl" fontWeight={'bold'}>
                {t('changes.title')}
              </Text>

              <Text fontSize="xm">{t('changes.paragraph.1')}</Text>

              <Text fontSize="xm">{t('changes.paragraph.2')}</Text>
            </ContentWrapper>
          </AtiraColumn>
        </AtiraRow>
      </Section>

      <Section>
        <AtiraRow justify={'center'} gutter={[20, 20]}>
          <AtiraColumn xs={24} sm={24} md={24} lg={22} xl={22}>
            <ContentWrapper>
              <Text fontSize="xl" fontWeight={'bold'}>
                {t('accountDeletion.title')}
              </Text>

              <Text fontSize="xm">
                <Trans
                  i18nKey={'privacyPolicy.accountDeletion.paragraph.1'}
                  components={[
                    <a href="mailto:support@atiragrup.com">
                      support@atiragrup.com
                    </a>,
                  ]}
                />
              </Text>

              <Text fontSize="xm">{t('accountDeletion.paragraph.2')}</Text>
            </ContentWrapper>
          </AtiraColumn>
        </AtiraRow>
      </Section>

      <Section>
        <AtiraRow justify={'center'} gutter={[20, 20]}>
          <AtiraColumn xs={24} sm={24} md={24} lg={22} xl={22}>
            <ContentWrapper>
              <Text fontSize="xl" fontWeight={'bold'}>
                {t('contact.title')}
              </Text>

              <Text fontSize="xm">
                <Trans
                  i18nKey={'privacyPolicy.contact.paragraph'}
                  components={[
                    <a href="mailto:info@atiragrup.com">info@atiragrup.com</a>,
                  ]}
                />
              </Text>
            </ContentWrapper>
          </AtiraColumn>
        </AtiraRow>
      </Section>
    </Container>
  );
};
