import React from 'react';
import styled from 'styled-components';

import i18n, { AppLangs } from '../../../../i18n';
import { AtiraLinkLanguageStrings } from '../../../../model/atira-link/AtiraLinkLangStrings';

const Container = styled.div`
  z-index: 10000;
  pointer-events: none;
  transition: all 0.2s ease-in-out;

  .offcanvas-end {
    left: auto !important;
    right: 0;
  }
`;

const QRplaceholder = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.black};
  background-color: ${({ theme }) => theme.white};
  font-size: 1.2rem;
  font-weight: bold;
`;

const LinkInput = styled.input`
  direction: ltr;
`;

interface AtiraLinkProps {
  title?: string;
  linkName?: string;
  onClose: VoidFunction;
  linkLanguageStrings: AtiraLinkLanguageStrings;
  isArabic: boolean;
}

export const AtiraLinkSideMenu = React.forwardRef<
  HTMLDivElement,
  AtiraLinkProps
>(({ title, linkName, onClose, linkLanguageStrings, isArabic }, ref) => {
  const qr = linkName
    ? `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(`https://atrl.ink/${linkName}`)}&size=200x200`
    : null;

  const linkToCopy = `https://atrl.ink/${linkName || 'yourname'}`;

  const shouldFlip = i18n.language === AppLangs.AR && !isArabic;

  return (
    <Container className="w-100 position-absolute top-0 left-0 bottom-0 h-100">
      <div
        ref={ref}
        tabIndex={-1}
        className={`offcanvas offcanvas-end position-absolute transform-1 h-100 sidebar`}
        style={{ transform: 'translateX(100%)' }}
      >
        <div className="offcanvas-header border-bottom">
          <h5
            className="sidebar-title w-75 text-truncate"
            id="offcanvasNavbarLabel"
          >
            {title}
          </h5>
          <button
            className={`sidebar-close-btn border-0 ${shouldFlip ? 'me-auto' : 'ms-auto'}`}
            onClick={onClose}
          >
            <i className="fa-solid fa-x fs-5 p-1 d-block"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="d-flex flex-column gap-3">
            <div className="input-group-text gap-2">
              <LinkInput
                type="text"
                className="form-control"
                readOnly
                id="atira-link-input"
                value={linkToCopy}
              />
              <button className="copy-btn" id="copy-btn">
                {linkLanguageStrings.copy}
              </button>
            </div>

            <div className="d-flex flex-column gap-2">
              <a
                id="share-wtsp"
                href=""
                className="sidebar-share-link py-2 px-3 d-flex justify-content-center align-items-center gap-2 text-decoration-none"
              >
                <i className="fa-brands fa-whatsapp"></i>
                <span>{linkLanguageStrings.shareViaWhatsapp}</span>
              </a>

              <a
                id="share-email"
                href="#"
                className="sidebar-share-link py-2 px-3 d-flex justify-content-center align-items-center gap-2 text-decoration-none"
              >
                <i className="fa-regular fa-envelope"></i>
                <span>{linkLanguageStrings.shareViaEmail}</span>
              </a>
            </div>

            <div className={`d-flex flex-column`}>
              <p className={`sidebar-titles fs-6`}>
                {linkLanguageStrings.shareOnSocialMedia}
              </p>
              <div className="d-none align-items-center gap-2">
                <a
                  id="share-facebook"
                  href="#"
                  className="sidebar-share-link-with-icon p-2 d-flex align-items-center justify-content-center text-decoration-none"
                >
                  <i className="fa-brands fa-facebook fs-5"></i>
                </a>
                <a
                  id="share-linkedin"
                  href="#"
                  className="sidebar-share-link-with-icon p-2 d-flex align-items-center justify-content-center text-decoration-none"
                >
                  <i className="fa-brands fa-linkedin fs-5"></i>
                </a>
                <a
                  id="share-twitter"
                  href="#"
                  className="sidebar-share-link-with-icon p-2 d-flex align-items-center justify-content-center text-decoration-none"
                >
                  <i className="fa-brands fa-x-twitter fs-5"></i>
                </a>
                <a
                  id="share-pinterest"
                  href="#"
                  className="sidebar-share-link-with-icon p-2 d-flex align-items-center justify-content-center text-decoration-none"
                >
                  <i className="fa-brands fa-pinterest fs-5"></i>
                </a>
              </div>
              <button
                id="mobile-share-btn"
                className="sidebar-share-link-with-icon p-2 d-flex align-items-center justify-content-center"
              >
                <i className="fa-solid fa-share-nodes fs-5"></i>
              </button>
            </div>

            <a
              href="#"
              className={`sidebar-report-link border-top border-bottom px-2 py-3 d-flex align-items-center gap-2 text-decoration-none`}
            >
              <i className="fa-regular fa-comments"></i>
              <span className="fs-6">{linkLanguageStrings.reportThisLink}</span>
            </a>

            <div className="d-flex flex-column">
              <div className="d-flex align-items-center gap-2 px-2 sidebar-report-link pb-3">
                <i className="fa-solid fa-download"></i>
                <a
                  className="fs-6 text-decoration-none"
                  id="atira-link-cvf-link"
                  style={{ color: 'inherit' }}
                >
                  {linkLanguageStrings.downloadAsContactFile}
                </a>
              </div>
              <hr
                className="bg-light opacity-100 w-100 m-0 border-0"
                style={{ height: '1.5px' }}
              />
            </div>

            <div className="d-flex flex-column gap-1 align-items-center justify-content-center px-2">
              <p className="sidebar-titles text-center">
                {linkLanguageStrings.scanQR}
              </p>
              {qr ? (
                <img
                  id="qr-code"
                  src={qr}
                  alt={`QR Code for ${title}`}
                  width="200"
                  height="200"
                />
              ) : (
                <QRplaceholder>QR Code</QRplaceholder>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
});
