import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from './components/modal/Modal';
import { PrivatePricingPlans } from './routes/shared/components/PrivatePricingPlans';

type PlansContextType = {
  plansModalVisible: boolean;
  setPlansModalVisible: ATVoidFunction<boolean>;
};

const PlansContext = createContext<PlansContextType | undefined>(undefined);

export const usePlansContext = () => {
  const context = useContext(PlansContext);
  if (!context) {
    throw new Error('usePlansContext must be used within a PlansModalProvider');
  }
  return context;
};

type PlansModalProviderProps = {
  children: React.ReactNode;
};

export const PlansModalProvider: React.FC<PlansModalProviderProps> = ({
  children,
}) => {
  const [plansModalVisible, setPlansModalVisible] = React.useState(false);

  const { t } = useTranslation();

  return (
    <PlansContext.Provider
      value={{
        plansModalVisible,
        setPlansModalVisible,
      }}
    >
      {children}

      <Modal
        title={t('common.plans')}
        open={plansModalVisible}
        onClose={() => setPlansModalVisible(false)}
        width={1200}
      >
        <PrivatePricingPlans />
      </Modal>
    </PlansContext.Provider>
  );
};
