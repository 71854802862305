import { faDotCircle } from '@fortawesome/free-solid-svg-icons/faDotCircle';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons/faEllipsis';
import { faRepeat } from '@fortawesome/free-solid-svg-icons/faRepeat';
import { Dropdown, MenuProps, TableProps } from 'antd';
import { TFunction } from 'i18next';
import { replace } from 'lodash';
import capitalize from 'lodash/capitalize';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { TextWithTruncated } from '../../../components/TextWithTruncated';
import { LemonWebhook } from '../../../model/plan/LemonWebhook';
import { LemonWebhookStatus } from '../../../model/plan/types/LemonWebhookStatus.enum';
import { Spacing } from '../../../theme/Spacing';
import { ThemeType } from '../../../theme/Theme';

const MenuButton = styled(Button)`
  margin: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
`;

const StatusToColor: Record<LemonWebhookStatus, keyof ThemeType> = {
  [LemonWebhookStatus.SUCCESS]: 'main',
  [LemonWebhookStatus.FAILED]: 'danger',
  [LemonWebhookStatus.PENDING]: 'darkSub',
  [LemonWebhookStatus.REJECTED]: 'danger',
  [LemonWebhookStatus.UNHANDLED]: 'dark',
};

type ColumnKey =
  | 'name'
  | 'actions'
  | 'user'
  | 'status'
  | 'plan'
  | 'retryAttempts';

type FieldFunctions = {
  t: TFunction;
  onRetry: ATVoidFunction<string>;
};

const StyledActionButton = styled(Button)`
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: ${Spacing.s};
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
`;

const getFieldWidth = (field: ColumnKey) => {
  switch (field) {
    case 'actions':
      return '5%';
    default:
      return 100;
  }
};

const getFieldInfo = (
  column: ColumnKey,
  functions: FieldFunctions,
  data: LemonWebhook[],
) => {
  const { t } = functions;

  switch (column) {
    case 'actions':
      return {
        title: t('common.actions'),
        key: 'table-actions',
        data: column,
        render: (webhook: LemonWebhook) => {
          const items: MenuProps['items'] = [
            {
              key: 'table-g1',
              type: 'group',
              children: [
                {
                  key: '1',
                  label: (
                    <StyledActionButton
                      disabled={
                        webhook.retryAttempts >= 3 ||
                        webhook.status !== LemonWebhookStatus.FAILED
                      }
                      onClick={() => functions.onRetry(webhook._id)}
                    >
                      <Flex gap="s" alignItems="center">
                        <AtiraIcon icon={faRepeat} color="main" />
                        <Text color="main">{t('common.retry')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                },
              ],
            },
          ];

          return (
            <Dropdown menu={{ items }} trigger={['click']}>
              <MenuButton icon={faEllipsis} />
            </Dropdown>
          );
        },
        fixed: 'left',
        width: getFieldWidth(column),
      };

    case 'name':
      return {
        title: t('common.name'),
        key: 'table-name',
        data: column,
        render: (webhook: LemonWebhook) => {
          return (
            <TextWithTruncated length={40}>
              {replace(webhook.data.meta.event_name!, new RegExp('_', 'g'), ' ')
                .split(' ')
                .map(capitalize)
                .join(' ')}
            </TextWithTruncated>
          );
        },
        width: getFieldWidth(column),
      };

    case 'status':
      return {
        title: t('common.status'),
        key: 'table-name',
        data: column,
        render: (webhook: LemonWebhook) => {
          return (
            <Flex alignItems="center" gap="s">
              <Flex width={82}>
                <TextWithTruncated length={40}>
                  {t(`payment.webhook.status.${webhook.status.toLowerCase()}`)}
                </TextWithTruncated>
              </Flex>
              <AtiraIcon
                icon={faDotCircle}
                color={StatusToColor[webhook.status]}
              />
            </Flex>
          );
        },
        width: getFieldWidth(column),
      };

    case 'user':
      return {
        title: t('common.user'),
        key: 'table-user',
        data: column,
        render: (webhook: LemonWebhook) => {
          return (
            <TextWithTruncated length={40}>
              {webhook.user?.name!}
            </TextWithTruncated>
          );
        },
        width: getFieldWidth(column),
      };

    case 'retryAttempts':
      return {
        title: t('common.retryAttempts'),
        key: 'table-retryAttempts',
        data: column,
        render: (webhook: LemonWebhook) => {
          return (
            <TextWithTruncated length={40}>
              {webhook.retryAttempts.toString()}
            </TextWithTruncated>
          );
        },
        width: getFieldWidth(column),
      };

    case 'plan':
      return {
        title: t('common.plan'),
        key: 'table-plan',
        data: column,
        render: (webhook: LemonWebhook) => {
          return (
            <TextWithTruncated length={40}>
              {webhook.data.data?.attributes?.variant_name ||
                webhook.user?.plan?.lemonPlanData?.variantName}
            </TextWithTruncated>
          );
        },
        width: getFieldWidth(column),
      };
  }
};

export function createAdminWebhookTableColumns(
  data: LemonWebhook[],
  functions: FieldFunctions,
) {
  const tableColumnsKeys: ColumnKey[] = [
    'actions',
    'name',
    'user',
    'status',
    'retryAttempts',
    'plan',
  ];

  return tableColumnsKeys.map((c) =>
    getFieldInfo(c, functions, data),
  ) as TableProps<LemonWebhook>['columns'];
}
