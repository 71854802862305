import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { Tooltip } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraDropdown } from '../../../components/AtiraDropdown';
import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { CopyButton } from '../../../components/CopyButton';
import { Flex } from '../../../components/Flex';
import { PageHeader } from '../../../components/PageHeader';
import { Spinner } from '../../../components/Spinner';
import { Text } from '../../../components/Text';
import { TextWithTruncated } from '../../../components/TextWithTruncated';
import { UserHeader } from '../../../components/UserHeader';
import i18n, { AppLangs } from '../../../i18n';
import { atiraLinkSliceSelectors } from '../../../redux/atira-link/atira-link.selector';
import { useAppSelector } from '../../../redux/store';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { getUserLinkUrl } from '../../../utils/AtiraLink';

const StyledPageHeader = styled(PageHeader)`
  .header-right-component {
    display: none;
  }

  @media (min-width: ${Breakpoints.TABLET}) {
    .header-right-component {
      display: flex;
    }
  }
`;

const UserHeaderWrapper = styled(Flex)`
  display: none;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: flex;
  }
`;

const InputSection = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  align-items: center;
  width: auto;
  gap: ${Spacing.s};
  height: 3rem;
  padding: ${Spacing.s};

  @media (max-width: ${Breakpoints.TABLET}) {
    flex-direction: column;
    align-items: start;
  }
`;

const FullWidthOnMobile = styled(Flex)`
  width: 100%;
  gap: ${Spacing.s};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: fit-content;
  }
`;

const LinkSection = styled(InputSection)`
  flex-direction: row;
  align-items: center;
  width: 100%;

  p {
    font-size: 1rem;
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    p {
      font-size: 1.1rem;
    }
  }

  @media (min-width: ${Breakpoints.LARGE_DESKTOP}) {
    p {
      font-size: 1.5rem;
    }
  }
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  height: 3rem;
  padding: ${Spacing.s};
  margin: 0rem;
  @media (max-width: ${Breakpoints.TABLET}) {
    display: none;
  }
`;

const ActionsButton = styled(Button)`
  background-color: ${({ theme }) => theme.light};
  padding: 0 ${Spacing.m};
  color: ${({ theme }) => theme.subTextColor};
  margin-inline-start: auto;
`;

const ArrowIconWrapper = styled(Flex)`
  svg {
    rotate: ${i18n.language === AppLangs.AR ? '180deg' : '0deg'};
  }
`;

export const DashboardHeader: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const selectedAtiraLink = useAppSelector(
    atiraLinkSliceSelectors.selectSelectedAtiraLink,
  );
  const atiraLinkLoading = useAppSelector(
    atiraLinkSliceSelectors.selectUserAtiraLinkLoading,
  );

  const userLinkUrl = getUserLinkUrl(selectedAtiraLink?.linkName!);

  const linkActionItems = [
    {
      key: 'preview',
      title: '',
      label: (
        <Button
          icon={faEye}
          color="subTextColor"
          backgroundColor="transparent"
          onClick={() => window.open(userLinkUrl, '_blank')}
        >
          {t('common.preview')}
        </Button>
      ),
    },
    {
      key: 'copy',
      title: '',
      label: (
        <CopyButton copy={userLinkUrl} iconSize="1x" showText open={false} />
      ),
    },
  ];

  return (
    <StyledPageHeader
      leftComponent={
        !isEmpty(selectedAtiraLink) ? (
          <FullWidthOnMobile>
            <LinkSection>
              <Text color="main" fontSize="l" fontWeight={'bolder'}>
                {t('common.your_link')}:
              </Text>
              <TextWithTruncated
                textProps={{ direction: 'ltr', fontSize: 'l' }}
                length={25}
              >
                {userLinkUrl}
              </TextWithTruncated>

              <AtiraDropdown items={linkActionItems} trigger={['click']}>
                <ActionsButton icon={faAngleDown} />
              </AtiraDropdown>
            </LinkSection>
          </FullWidthOnMobile>
        ) : (
          <InputSection
            width={'17rem'}
            cursor="pointer"
            gap="l"
            flexDirection="row"
            onClick={() => navigate('/my-link')}
          >
            {atiraLinkLoading ? (
              <Spinner />
            ) : (
              <Text color="main" fontSize="l" fontWeight={'bolder'}>
                {t('dashboard.create_link')}
              </Text>
            )}

            <ArrowIconWrapper>
              <AtiraIcon size="2x" icon={faArrowRight} color="textColor" />
            </ArrowIconWrapper>
          </InputSection>
        )
      }
      rightComponent={
        <UserHeaderWrapper>
          <UserHeader />
        </UserHeaderWrapper>
      }
    />
  );
};
