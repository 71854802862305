import { faCrown } from '@fortawesome/free-solid-svg-icons/faCrown';
import { Image } from 'antd';
import isString from 'lodash/isString';
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { CreateAtiraLinkDto } from '../../../model/atira-link/dto/CreateAtiraLinkDto';
import { AtiraLinkThemes } from '../../../model/atira-link/types/AtiraLinkThemes.enum';
import { AtiraLinkPlanType } from '../../../model/plan/types/AtiraLinkPlanType.enum';
import { AtiraLinkTheme } from '../../../model/theme/AtiraLinkTheme';
import { AtiraLinkPlanThemeTier } from '../../../model/theme/type/AtiraLinkPlanThemeTier';
import { usePlansContext } from '../../../plans-context';
import { atiraLinkSliceSelectors } from '../../../redux/atira-link/atira-link.selector';
import { AtiraLinkActions } from '../../../redux/atira-link/atira-link.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { getMediaUrl } from '../../../utils/Media';

const getImageSrc = (image?: any) => {
  return isString(image) ? getMediaUrl(image!) : URL.createObjectURL(image!);
};

const SectionWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  width: 100%;
`;

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  gap: ${Spacing.m};
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const CustomTemplate = styled(Flex)`
  background-color: ${({ theme }) => theme.darkerSub};
  border-radius: ${Rounded.xl};
  width: 10rem;
  height: 17rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const ThemeName = styled(Text)`
  color: transparent;
  background-clip: text;
  background-image: linear-gradient(#960baa 25%, #2b2694);
  font-size: 1.25rem;
  font-weight: bold;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.5rem;
  }
`;

const StyledButton = styled(Button)<{
  selected: boolean;
  isAvailable: boolean;
}>`
  background-color: ${({ theme, selected, isAvailable }) =>
    selected || isAvailable ? theme.main : theme.transparent};
  color: ${({ theme, selected, isAvailable }) =>
    selected || isAvailable ? theme.light : theme.main};
  border: ${({ theme }) => `1px solid ${theme.main}`};

  &:hover {
    background-color: ${({ theme }) => theme.main};
    color: ${({ theme }) => theme.light};
  }
`;

const PlanThemeAccess: Record<AtiraLinkPlanType, AtiraLinkPlanThemeTier[]> = {
  [AtiraLinkPlanType.PLAN_0]: [AtiraLinkPlanThemeTier.PLAN_0],
  [AtiraLinkPlanType.PLAN_1]: [
    AtiraLinkPlanThemeTier.PLAN_0,
    AtiraLinkPlanThemeTier.PLAN_1,
  ],
  [AtiraLinkPlanType.PLAN_2]: [
    AtiraLinkPlanThemeTier.PLAN_0,
    AtiraLinkPlanThemeTier.PLAN_1,
    AtiraLinkPlanThemeTier.PLAN_2,
  ],
};

export const AtiraLinkTemplatesTab: React.FC = () => {
  const { setValue } = useFormContext<CreateAtiraLinkDto>();
  const selectedTheme = useWatch({ name: 'theme' });

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { setPlansModalVisible } = usePlansContext();
  const user = useAppSelector(userSliceSelectors.selectLoggedInUser)!;
  const themes = useAppSelector(atiraLinkSliceSelectors.selectThemes);
  const atiraLink = useAppSelector(
    atiraLinkSliceSelectors.selectSelectedAtiraLink,
  );

  const getIsThemeAvailableForUserPlan = (theme: AtiraLinkTheme) => {
    const userFreeTrialPlan =
      user?.plan?.freeTrial?.hasUsed === true &&
      user?.plan?.freeTrial?.endsAt !== null
        ? user?.plan?.freeTrial?.freeTrialPlan
        : null;
    const userPlanType = userFreeTrialPlan || user?.plan?.type!;

    const themeTier = theme.tier;

    if (!PlanThemeAccess[userPlanType].includes(themeTier)) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    dispatch(AtiraLinkActions.getThemes());
  }, [dispatch]);

  return (
    <Flex width={'100%'} gap="l">
      <SectionWrapper>
        <Text fontWeight="bold" fontSize="xm" color="textColor">
          {t('common.templates')}
        </Text>
        <Text>({t('atira_link.create.templates.template_title')})</Text>

        <Section>
          {themes.map((theme, i) => {
            const themeAvailable = getIsThemeAvailableForUserPlan(theme);
            const canUseTheme =
              themeAvailable || atiraLink?.theme === theme.name;

            return (
              <Flex
                flexDirection="column"
                alignItems="center"
                gap="s"
                width="fit-content"
                key={theme._id}
              >
                <Flex flexDirection="column" alignItems="center">
                  <ThemeName>{theme.name}</ThemeName>
                  <CustomTemplate>
                    <Image.PreviewGroup>
                      <Image
                        src={getImageSrc(theme?.images?.mini)}
                        alt="mini"
                      />
                      <Image
                        style={{ display: 'none' }}
                        src={getImageSrc(theme?.images?.full)}
                        alt="full"
                      />
                      <Image
                        style={{ display: 'none' }}
                        src={getImageSrc(theme?.images?.side)}
                        alt="side"
                      />
                    </Image.PreviewGroup>
                  </CustomTemplate>
                </Flex>
                <StyledButton
                  isAvailable={!themeAvailable}
                  onClick={() =>
                    canUseTheme
                      ? setValue('theme', theme.name as AtiraLinkThemes)
                      : setPlansModalVisible(true)
                  }
                  selected={selectedTheme === (theme.name as AtiraLinkThemes)}
                  icon={canUseTheme ? undefined : faCrown}
                >
                  {canUseTheme
                    ? `${theme.name.replaceAll('_', ' ')} ${selectedTheme === theme.name ? ' ✓' : ''}`
                    : t('common.upgrade')}
                </StyledButton>
              </Flex>
            );
          })}
        </Section>
      </SectionWrapper>
    </Flex>
  );
};
