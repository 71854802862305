/* eslint-disable no-nested-ternary */
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { diff } from 'deep-object-diff';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import i18n, { AppLangs } from '../../../i18n';
import { AtiraLink } from '../../../model/atira-link/AtiraLink';
import { CreateAtiraLinkDto } from '../../../model/atira-link/dto/CreateAtiraLinkDto';
import { EditAtiraLinkDto } from '../../../model/atira-link/dto/EditAtiraLinkDto';
import { AtiraLinkLang } from '../../../model/settings/types/AtiraLinkLang.enum';
import { atiraLinkSliceSelectors } from '../../../redux/atira-link/atira-link.selector';
import { AtiraLinkActions } from '../../../redux/atira-link/atira-link.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { TemplatePreviewMode } from '../../shared/model/TemplatePreviewMode.enum';
import { AtiraLinkPreviewPhoneEJS } from './AtiraLinkPreviewPhoneEJS';

const Container = styled(Flex)<{
  isPreviewMode: boolean;
  previewMode: TemplatePreviewMode;
}>`
  flex-direction: column;
  align-items: center;
  gap: ${Spacing.l};
  position: sticky;
  height: ${({ isPreviewMode, previewMode }) =>
    isPreviewMode
      ? previewMode === TemplatePreviewMode.MOBILE
        ? '640px'
        : '800px'
      : 'fit-content'};
  width: ${({ previewMode }) =>
    previewMode === TemplatePreviewMode.MOBILE
      ? '340px'
      : previewMode === TemplatePreviewMode.TABLET
        ? '748px'
        : '1200px'};
  top: ${Spacing.m};
  margin-inline: ${({ isPreviewMode }) => (!isPreviewMode ? Spacing.s : '')};

  ${({ isPreviewMode }) =>
    isPreviewMode
      ? `

    @media (max-width: ${Breakpoints.TABLET}) {      
      .atira-link-body {
        min-height: 70vh;
      }
    }
  `
      : `
      `}
`;

const PhoneWrapper = styled(Flex)<{
  isPreviewMode: boolean;
  previewMode: TemplatePreviewMode;
}>`
  border: ${({ theme }) => `6px solid ${theme.dark}`};
  flex-direction: column;
  box-shadow: ${({ isPreviewMode }) =>
    `${!isPreviewMode ? 'rgba(0, 0, 0, 0.45)' : ''}`};
  background-color: ${({ theme }) => theme.light};
  overflow: hidden;
  background-color: white;
  border-radius: ${({ previewMode }) =>
    previewMode === TemplatePreviewMode.DESKTOP ? '0' : '2.5rem'};
  width: 100%;
  height: 100%;
  display: ${({ isPreviewMode }) => (!isPreviewMode ? 'none' : 'flex')};

  @media (min-width: ${Breakpoints.TABLET}) {
    ${({ isPreviewMode, theme }) =>
      isPreviewMode ? `border: ${`6px solid ${theme.dark}`}` : ''}
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: flex;
  }
`;

const ButtonsWrapper = styled(Flex)`
  position: fixed;
  bottom: 0;
  right: ${i18n.language === AppLangs.AR ? '50px' : '0'};
  left: ${i18n.language === AppLangs.AR ? '0' : '50px'};
  background-color: ${({ theme }) => theme.light};
  z-index: 100;
  justify-content: center;
  align-items: center;
  padding: ${Spacing.s};
  gap: ${Spacing.s};
  width: calc(100% - 50px);
  box-shadow: 0px 0px 7px rgba(50, 50, 50, 0.75);

  @media (min-width: ${Breakpoints.DESKTOP}) {
    position: static;
    background-color: ${({ theme }) => theme.transparent};
    box-shadow: none;
    width: 90%;
  }
`;

const SaveButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.s};
  border-radius: ${Rounded.pill};
  text-decoration: none;
  background-color: ${({ theme }) => theme.main};
  color: ${({ theme }) => theme.lightTextColor};
  font-size: 1.1rem;
  padding: 0.6rem;
  height: 2.4rem;
  width: 10rem;

  &:hover {
    color: ${({ theme }) => theme.lightTextColor};
  }

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.3rem;
  }
`;

const PreviewButton = styled(Button)`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.s};
  border-radius: ${Rounded.pill};
  font-size: 1.1rem;
  padding: 0.6rem;
  height: 2.4rem;
  width: 10rem;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.3rem;
  }
`;

interface AtiraLinkPreviewPhoneProps {
  values: (
    | CreateAtiraLinkDto
    | EditAtiraLinkDto
    | AtiraLink
    | Partial<AtiraLink>
  ) & {
    linkName: string;
  };
  currentLinkLang?: AtiraLinkLang;
  loading?: boolean;
  setLoading?: ATVoidFunction<boolean>;
  showActions?: boolean;
  isPreviewMode?: boolean;
  previewMode?: TemplatePreviewMode;
}

export const AtiraLinkPreviewPhone: React.FC<AtiraLinkPreviewPhoneProps> = ({
  values,
  currentLinkLang,
  loading,
  setLoading,
  showActions = false,
  isPreviewMode = false,
  previewMode = TemplatePreviewMode.MOBILE,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectedAtiraLink = useAppSelector(
    atiraLinkSliceSelectors.selectSelectedAtiraLink,
  );

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const editMode = Boolean(selectedAtiraLink?._id);

  const onSave = async () => {
    try {
      setLoading?.(true);

      if (editMode) {
        await edit();
      } else {
        await create();
        localStorage.removeItem('selectedThemeFromTemplates');
      }

      await dispatch(AtiraLinkActions.getUserAtiraLinks({ userId })).unwrap();

      AtiraToast.success(
        t(editMode ? 'atira_link.edit.success' : 'atira_link.create.success'),
      );

      if (!editMode) {
        navigate('/dashboard');
      }
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setLoading?.(false);
    }
  };

  const create = async () => {
    const dto = omit(values, ['customLinks', 'socials']);

    const cleanedDto = omitBy(
      diff(omit(selectedAtiraLink, ['customLinks', 'socials'])!, dto),
      isNil,
    ) as CreateAtiraLinkDto;

    const customLinks = values.customLinks;
    const customLinksAreEmpty = !customLinks?.filter(
      ({ label, value }) => label.length && value.length,
    ).length;

    if (!customLinksAreEmpty) {
      cleanedDto.customLinks = customLinks?.filter(
        ({ label, value }) => label.length && value.length,
      );
    } else {
      delete cleanedDto.customLinks;
    }

    const socials = values.socials;

    if (socials?.length) {
      cleanedDto.socials = socials;
    } else {
      delete cleanedDto.socials;
    }

    cleanedDto.userId = userId;

    return dispatch(AtiraLinkActions.createAtiraLink(cleanedDto)).unwrap();
  };

  const edit = async () => {
    const dto = omit(values, ['customLinks', 'socials']);

    const cleanedDto = omitBy(
      diff(omit(selectedAtiraLink, ['customLinks', 'socials'])!, dto),
      isNil,
    ) as CreateAtiraLinkDto;

    const customLinks = values.customLinks;
    if (
      customLinks?.length !== selectedAtiraLink?.customLinks.length ||
      customLinks?.some(
        (l, i) => !isEqual(l, selectedAtiraLink?.customLinks[i]),
      )
    ) {
      cleanedDto.customLinks = customLinks;
    }

    const socials = values.socials;
    if (
      socials?.length !== selectedAtiraLink?.socials?.length ||
      socials?.some((l, i) => !isEqual(l, selectedAtiraLink?.socials?.[i]))
    ) {
      cleanedDto.socials = socials;
    }

    cleanedDto.userId = userId;

    return dispatch(
      AtiraLinkActions.editAtiraLink({
        atiraLinkId: selectedAtiraLink!._id,
        ...cleanedDto,
      } as EditAtiraLinkDto),
    ).unwrap();
  };

  const onPreview = () => {
    window.open(`https://atrl.ink/${selectedAtiraLink?.linkName}`, '_blank');
  };

  return (
    <Container isPreviewMode={isPreviewMode} previewMode={previewMode}>
      <PhoneWrapper isPreviewMode={isPreviewMode} previewMode={previewMode}>
        <AtiraLinkPreviewPhoneEJS
          isPreviewMode={isPreviewMode}
          atiraLink={values}
          currentLinkLang={currentLinkLang}
          previewMode={previewMode}
        />
      </PhoneWrapper>

      {showActions ? (
        <ButtonsWrapper>
          <SaveButton
            disabled={
              (values.title?.length || 0) < 3 || values.linkName?.length < 3
            }
            onClick={onSave}
            loading={loading}
          >
            <AtiraIcon icon={editMode ? faPen : faCheck} />

            {editMode ? t('common.update') : t('common.save')}
          </SaveButton>

          <PreviewButton onClick={onPreview} disabled={!values.linkName}>
            <AtiraIcon icon={faEye} />
            {t('common.preview')}
          </PreviewButton>
        </ButtonsWrapper>
      ) : null}
    </Container>
  );
};
