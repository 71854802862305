import { createSlice } from '@reduxjs/toolkit';

import { AtiraLinkAxios } from '../../axios/atira-link/atira-link.axios';
import { AtiraLink } from '../../model/atira-link/AtiraLink';
import { AtiraLinkStatsResponseDto } from '../../model/atira-link/dto/AtiraLinkStatsResponseDto';
import { CreateAtiraLinkDto } from '../../model/atira-link/dto/CreateAtiraLinkDto';
import { EditAtiraLinkDto } from '../../model/atira-link/dto/EditAtiraLinkDto';
import { GetUserAtiraLinkResponseDto } from '../../model/atira-link/dto/GetUserAtiraLinksResponseDto';
import { AtiraLinkSettings } from '../../model/settings/types/AtiraLinkSettings';
import { GenericWithUserId } from '../../model/shared/GenericWithUserId';
import { AtiraLinkTheme } from '../../model/theme/AtiraLinkTheme';
import { AtiraLinkPlanThemeTier } from '../../model/theme/type/AtiraLinkPlanThemeTier';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from '../user/user.slice';
import { DeleteAtiraLinkDto } from './dto/DeleteAtiraLinkDto';
import { GenericDisableEnableAtiraLinkDto } from './dto/GenericDisableEnableAtiraLinkDto';

interface AtiraLinkReducer {
  atiraLinks?: AtiraLink[];
  atiraLinkLoading?: boolean;
  selectedAtiraLinkStats?: AtiraLinkStatsResponseDto;
  settings?: AtiraLinkSettings[];
  //
  themes: AtiraLinkTheme[];
  themesLoading?: boolean;
  selectedAtiraLink?: AtiraLink;
}

const initialState = Object.freeze<AtiraLinkReducer>({
  atiraLinks: [],
  atiraLinkLoading: false,
  selectedAtiraLinkStats: undefined,
  settings: undefined,
  themes: [],
  themesLoading: false,
  selectedAtiraLink: undefined,
});

const createAtiraLink = AtiraThunk<void, CreateAtiraLinkDto>(
  `/atira-link/create`,
  (dto) => AtiraLinkAxios.createAtiraLink(dto),
);

const editAtiraLink = AtiraThunk<void, EditAtiraLinkDto>(
  `/atira-link/edit`,
  (dto) => AtiraLinkAxios.editAtiraLinkDto(dto),
);

const getUserAtiraLinks = AtiraThunk<
  GetUserAtiraLinkResponseDto,
  GenericWithUserId
>(`/atira-link/get`, (dto) => AtiraLinkAxios.getUserAtiraLinks(dto));

const deleteUserAtiraLink = AtiraThunk<void, DeleteAtiraLinkDto>(
  `/atira-link/delete`,
  (dto) => AtiraLinkAxios.deleteAtiraLink(dto),
);

const disableUserAtiraLink = AtiraThunk<void, GenericDisableEnableAtiraLinkDto>(
  `/atira-link/disable`,
  (dto) => AtiraLinkAxios.disableAtiraLink(dto),
);

const enableUserAtiraLink = AtiraThunk<void, GenericDisableEnableAtiraLinkDto>(
  `/atira-link/enable`,
  (dto) => AtiraLinkAxios.enableAtiraLink(dto),
);

const getThemes = AtiraThunk<
  AtiraLinkTheme[],
  AtiraLinkPlanThemeTier | undefined
>(`/atira-link/themes`, (dto) => AtiraLinkAxios.getThemes(dto));

const AtiraLinkSlice = createSlice({
  name: 'atiraLinkSlice',
  initialState,
  reducers: {
    setSelectedAtiraLink(state, action) {
      state.selectedAtiraLink = action.payload;
    },

    resetSelectedAtiraLink(state) {
      state.selectedAtiraLink = undefined;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getUserAtiraLinks.pending, (state) => {
      state.atiraLinkLoading = true;
    });
    addCase(getUserAtiraLinks.fulfilled, (state, action) => {
      state.atiraLinks = action.payload.links;
      state.selectedAtiraLink =
        action.payload.links.find(
          (link) => link._id === state.selectedAtiraLink?._id,
        ) || action.payload.links[0];
      state.selectedAtiraLinkStats = action.payload.mappedStats.find(
        (stats) => stats.linkId === state.selectedAtiraLink?._id,
      );
      state.atiraLinkLoading = false;
    });
    addCase(getUserAtiraLinks.rejected, (state) => {
      state.atiraLinkLoading = false;
    });

    addCase(getThemes.pending, (state) => {
      state.themesLoading = true;
    });
    addCase(getThemes.fulfilled, (state, action) => {
      state.themes = action.payload;
      state.themesLoading = false;
    });
    addCase(getThemes.rejected, (state) => {
      state.themesLoading = false;
    });

    //@ts-ignore
    addCase(userActions.logout.fulfilled, (state: AtiraLinkReducer) => {
      return (state = initialState);
    });
  },
});

export const AtiraLinkActions = {
  ...AtiraLinkSlice.actions,
  createAtiraLink,
  editAtiraLink,
  getUserAtiraLinks,
  deleteUserAtiraLink,
  disableUserAtiraLink,
  enableUserAtiraLink,
  getThemes,
};

export default AtiraLinkSlice.reducer;
