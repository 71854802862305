/* eslint-disable no-nested-ternary */
import { faCrown } from '@fortawesome/free-solid-svg-icons/faCrown';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { usePlansContext } from '../plans-context';
import { Spacing } from '../theme/Spacing';
import { Button } from './Button';

const StyledButton = styled(Button)<{
  insideTooltip: boolean;
  asIcon: boolean;
}>`
  background: ${({ insideTooltip, asIcon }) =>
    insideTooltip || asIcon
      ? 'transparent'
      : 'linear-gradient(0deg, #2b2694 0%, #960baa 100%)'};
  color: ${({ theme, insideTooltip, asIcon }) =>
    theme[insideTooltip || asIcon ? 'lightMain' : 'lightTextColor']};
  border-bottom: ${({ theme, insideTooltip }) =>
    insideTooltip ? `1px solid ${theme.lightMain}` : '0'};
  padding: ${({ insideTooltip, asIcon }) =>
    asIcon
      ? '0'
      : insideTooltip
        ? '0 0 0.2rem 0'
        : `${Spacing.s} ${Spacing.l}`};
  margin: 0;
  width: fit-content;
`;

type UpgradeButtonProps = {
  insideTooltip?: boolean;
  asIcon?: boolean;
  postClick?: VoidFunction;
};

export const UpgradeButton: React.FC<UpgradeButtonProps> = ({
  insideTooltip = false,
  asIcon = false,
  postClick,
}) => {
  const { t } = useTranslation();

  const { setPlansModalVisible } = usePlansContext();

  return (
    <StyledButton
      icon={faCrown}
      onClick={() => {
        setPlansModalVisible(true);
        postClick?.();
      }}
      insideTooltip={insideTooltip}
      asIcon={asIcon}
      iconWidth={asIcon ? 'xl' : '1x'}
    >
      {!asIcon ? t('common.upgrade') : null}
    </StyledButton>
  );
};
