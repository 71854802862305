import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Image, Tooltip } from 'antd';
import isString from 'lodash/isString';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { TextWithTruncated } from '../../../components/TextWithTruncated';
import { AtiraLinkTheme } from '../../../model/theme/AtiraLinkTheme';
import { AtiraLinkThemeStatus } from '../../../model/theme/type/AtiraLinkThemeStatus.enum';
import { Rounded } from '../../../theme/Rounded';
import { Shadow } from '../../../theme/Shadow';
import { Spacing } from '../../../theme/Spacing';
import { ThemeType } from '../../../theme/Theme';
import { getMediaUrl } from '../../../utils/Media';

const getImageSrc = (image?: any) => {
  if (!image) {
    return;
  }

  return isString(image)
    ? getMediaUrl(image!)
    : URL.createObjectURL(image! as any);
};

const getPreviewItems = (images: AtiraLinkTheme['images']) => {
  const sortedImages = Object.entries(images || {}).sort(([a], [b]) => {
    const order = ['mini', 'side', 'full'];
    return order.indexOf(a) - order.indexOf(b);
  });

  const items = sortedImages.map(([key, image]) =>
    isString(image) ? getMediaUrl(image!) : URL.createObjectURL(image! as any),
  );

  return items;
};

const StatusContainer = styled(Flex)`
  border-radius: ${Rounded.md};
  padding: ${Spacing.s};
  border: 1px solid ${({ theme }) => theme.light};
  justify-content: center;
  align-items: center;
  gap: ${Spacing.s};
`;

const Container = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  max-height: 25rem;
  height: 25rem;
  background: linear-gradient(0deg, #2b2694, #960baa);
  padding: ${Spacing.s};
  border-radius: ${Rounded.md};
  box-shadow: ${Shadow.MD};
`;

const ImagesWrapper = styled(Flex)`
  aspect-ratio: 9 / 16;
  border-radius: ${Rounded.md};
  overflow: hidden;

  .ant-image {
    width: 100%;
    height: 100%;
  }

  .ant-image img {
    height: 100%;
    object-fit: cover;
  }
`;

const ThemeName = styled(Text)`
  text-align: center;
  font-weight: bold;
`;

const StatusToColor: Record<AtiraLinkThemeStatus, keyof ThemeType> = {
  [AtiraLinkThemeStatus.PENDING]: 'lightAccent',
  [AtiraLinkThemeStatus.ACTIVE]: 'main',
  [AtiraLinkThemeStatus.INACTIVE]: 'danger',
};

const StatusToText: Record<AtiraLinkThemeStatus, string> = {
  [AtiraLinkThemeStatus.PENDING]: 'admin.theme.status.pending',
  [AtiraLinkThemeStatus.INACTIVE]: 'admin.theme.status.inactive',
  [AtiraLinkThemeStatus.ACTIVE]: '',
};

type AdminThemeCardProps = {
  theme: AtiraLinkTheme;
};

export const AdminThemeCard: React.FC<AdminThemeCardProps> = ({ theme }) => {
  const { t } = useTranslation();

  const { name, description, images, status } = theme;

  return (
    <Flex flexDirection="column" gap="s">
      <Container>
        <ThemeName fontSize="xl">{name}</ThemeName>
        {description ? (
          <TextWithTruncated
            textProps={{ color: 'lightTextColor', align: 'center' }}
            length={20}
          >
            {description}
          </TextWithTruncated>
        ) : null}

        <ImagesWrapper>
          <Image.PreviewGroup items={getPreviewItems(images)}>
            <Image src={getImageSrc(images?.mini)} />
          </Image.PreviewGroup>
        </ImagesWrapper>
      </Container>
      <Tooltip title={t(StatusToText[status])}>
        <StatusContainer>
          <Text
            fontWeight="bold"
            align="center"
            color={StatusToColor[status] as keyof ThemeType}
          >
            {status}
          </Text>
          {status !== AtiraLinkThemeStatus.ACTIVE ? (
            <AtiraIcon icon={faCircleExclamation} />
          ) : null}
        </StatusContainer>
      </Tooltip>
    </Flex>
  );
};
