import { Empty } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { Spacing } from '../theme/Spacing';
import { Button } from './Button';
import { Text } from './Text';

const StyledButton = styled(Button)`
  font-size: 1.5rem;
  padding: ${Spacing.s} ${Spacing.xxxl};

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
  }
`;

const StyledEmpty = styled(Empty)`
  margin: auto;

  .ant-empty-image {
    display: none;
  }
`;

type AtiraEmptyWithActionProps = {
  description: string;
  buttonTitle: string;
  onClick: VoidFunction;
};
export const AtiraEmptyWithAction: React.FC<AtiraEmptyWithActionProps> = ({
  buttonTitle,
  description,
  onClick,
}) => {
  return (
    <StyledEmpty
      image={null}
      description={
        <Text color="main" fontSize="xl" align="center">
          {description}
        </Text>
      }
    >
      <StyledButton onClick={onClick}>{buttonTitle}</StyledButton>
    </StyledEmpty>
  );
};
