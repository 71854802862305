import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraTabs } from '../../components/AtiraTabs';
import { Flex } from '../../components/Flex';
import { UserHeader } from '../../components/UserHeader';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { shortenUrlActions } from '../../redux/url/url.slice';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';
import { URLShortner } from './URLShortner';
import { UserURLs } from './UserURLs';

const HeaderWrapper = styled(Flex)`
  display: none;
  margin-top: ${Spacing.s};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: flex;
  }
`;

enum TabsKeys {
  USER_URLS = 'USER_URLS',
  SHORTEN_URL = 'SHORTEN_URL',
}

export const URLRoute: React.FC = () => {
  const location = useLocation();

  const [defaultActiveKey, _] = useState<TabsKeys>(
    location?.state?.targetedTab || TabsKeys.USER_URLS,
  );

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useDocumentTitle(`${t('app.name').toUpperCase()} - ${t('common.my_urls')}`);

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;

  useEffect(() => {
    dispatch(shortenUrlActions.getUserShortURLs({ userId: loggedInUserId }));
  }, [dispatch, loggedInUserId]);

  return (
    <AtiraTabs
      items={[
        {
          key: TabsKeys.USER_URLS,
          label: t('side_menu.my_urls'),
          children: <UserURLs />,
        },
        {
          key: TabsKeys.SHORTEN_URL,
          label: t('common.create'),
          children: <URLShortner />,
        },
      ]}
      sideComponent={
        <HeaderWrapper>
          <UserHeader />
        </HeaderWrapper>
      }
      defaultActiveKey={defaultActiveKey}
    />
  );
};
