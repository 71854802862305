import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { Select, SelectProps, Tooltip } from 'antd';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { createGlobalStyle } from 'styled-components';

import { useTheme } from '../ThemeContext';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';
import { AtiraIcon } from './AtiraIcon';
import { Flex } from './Flex';
import { Text } from './Text';

const StyledSelectContainer = styled(Flex)<{
  height?: string;
  disabled?: boolean;
}>`
  flex-direction: column;
  gap: ${Spacing.s};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  label {
    color: ${({ theme }) => theme.textColor};
  }

  .ant-select {
    height: 100%;
  }

  .ant-select-selector:hover {
    border: 1px solid ${({ theme }) => theme.subTextColor} !important;
  }

  .ant-select-selector {
    border: 1px solid ${({ theme }) => theme.textColor} !important;
    background-color: ${({ theme }) => theme.light} !important;
    height: ${({ height }) => height || '2.3rem'} !important;
  }

  .ant-select-selection-item {
    color: ${({ theme }) => theme.textColor} !important;
  }

  svg {
    fill: ${({ theme }) => theme.textColor};
  }

  .ant-select-selection-placeholder {
    color: ${({ theme }) => theme.textColor};
  }

  .ant-select-clear {
    background-color: ${({ theme }) => theme.textColor};
    border-radius: ${Rounded.circle};
  }
`;

const Required = styled.span`
  color: ${({ theme }) => theme.danger};
`;

const MenuThemedStyle = createGlobalStyle`
  .ant-select-dropdown {
    background-color: ${({ theme }) => theme.light};
  }

  .ant-select-item-option-content { 
    color: ${({ theme }) => theme.textColor};
  }

  .ant-select-item-option-selected {
    background-color: ${({ theme }) => theme.sub} !important;
  }
`;

interface AtiraSelectProps extends SelectProps {
  width?: string;
  height?: string;
  borderRadius?: string;
  title?: string;
  info?: string;
  containerStyle?: CSSProperties;
  required?: boolean;
  valid?: boolean;
  errorMessage?: string;
}

export const AtiraSelect: React.FC<AtiraSelectProps> = ({
  onChange,
  options,
  value,
  width,
  height,
  title,
  info,
  borderRadius,
  containerStyle,
  required = false,
  valid,
  errorMessage,
  ...props
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <StyledSelectContainer
      style={containerStyle}
      height={height}
      disabled={props.disabled}
    >
      {title ? (
        <label>
          {info ? (
            <Tooltip title={info}>
              <AtiraIcon icon={faInfoCircle} />
              {/* don't delete this span */}
              <span style={{ marginInlineEnd: '0.2rem' }}></span>
            </Tooltip>
          ) : null}

          {title}
          {required ? <Required> *</Required> : null}
        </label>
      ) : null}
      <Select
        onChange={onChange}
        value={value}
        options={options}
        placeholder={props.placeholder || t('common.dropdown.select')}
        {...props}
      />
      {valid === false && errorMessage ? (
        <Text color="danger" fontSize="s">
          {errorMessage}
        </Text>
      ) : null}
      <MenuThemedStyle theme={theme} />
    </StyledSelectContainer>
  );
};
