import merge from 'lodash/merge';
import React, { InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useTheme } from '../ThemeContext';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';
import { Flex } from './Flex';
import { Text } from './Text';

const StyledTextarea = styled.textarea`
  min-height: 4rem;
  max-width: 100%;
  min-width: 100%;
  min-height: 4rem;
  outline: none;
  border-radius: ${Rounded.md};
  color: ${({ theme }) => theme.textColor};
  background-color: ${({ theme }) => theme.light};
  font-family: inherit;
`;

const StyledLabel = styled.label`
  cursor: pointer;
  color: ${({ theme }) => theme.textColor};
`;

const DangerSpan = styled.span`
  color: ${({ theme }) => theme.danger};
`;

const OptionalSpan = styled.span`
  color: ${({ theme }) => theme.subTextColor};
`;

interface TextareaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  title?: string;
  id?: string;
  width?: string;
  height?: string;
  maxHeight?: string;
  rows?: number;
  border?: string;
  borderRadius?: keyof typeof Rounded;
  placeholder?: string;
  padding?: string;
  margin?: string;
  color?: string;
  valid?: boolean;
  errorMessage?: string;
}

export const Textarea: React.FC<TextareaProps> = (props) => {
  const { theme } = useTheme();

  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" gap="s" width={props.width || '100%'}>
      {props.title ? (
        <StyledLabel htmlFor={props.id}>
          {props.title}
          {props.required ? (
            <DangerSpan> *</DangerSpan>
          ) : (
            <OptionalSpan>{` (${t('common.optional')})`}</OptionalSpan>
          )}
        </StyledLabel>
      ) : null}
      <Flex position="relative" width="100%">
        <StyledTextarea
          style={merge(props.style, props.disabled ? { opacity: '0.2' } : {}, {
            border: props.border || `1px solid ${theme.darkSub}`,
            borderRadius: props.borderRadius,
            placeholder: props.placeholder,
            padding: props.padding || Spacing.s,
            margin: props.margin,
            maxHeight: props.maxHeight,
            valid:
              props.valid === false
                ? { border: `1px solid ${theme.danger}` }
                : {},
          })}
          rows={props.rows}
          {...props}
        />

        {!props.valid && props.errorMessage ? (
          <Text color="danger" fontSize="s">
            {props.errorMessage}
          </Text>
        ) : null}
      </Flex>
    </Flex>
  );
};
