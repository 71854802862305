import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraColumn } from '../../../components/AtiraColumn';
import { AtiraRow } from '../../../components/AtiraRow';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { atiraLinkSliceSelectors } from '../../../redux/atira-link/atira-link.selector';
import { useAppSelector } from '../../../redux/store';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.m};
`;

const SectionTitle = styled(Text)`
  color: ${({ theme }) => theme.textColor};
  font-weight: bold;
  font-size: 1.3rem;
`;

export const DashboardCountriesStats: React.FC = () => {
  const { t } = useTranslation();

  const stats = useAppSelector(
    atiraLinkSliceSelectors.selectSelectedAtiraLinkStats,
  );

  if (!stats?.COUNTRIES.length) {
    return null;
  }

  return (
    <Section>
      <SectionTitle>{t('common.country_and_visits')}</SectionTitle>
      <Flex flexDirection="column" gap="m">
        <AtiraRow justify={'space-between'}>
          <AtiraColumn>
            <Flex gap="s">
              <Text fontWeight={'bolder'}>
                {stats?.COUNTRIES.map((country) => (
                  <Flex key={`${country.country}-${country.count}`}>
                    {country.country}: {country.count}
                  </Flex>
                ))}
              </Text>
            </Flex>
          </AtiraColumn>
        </AtiraRow>
      </Flex>
    </Section>
  );
};
