import { faCrown } from '@fortawesome/free-solid-svg-icons/faCrown';
import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Breakpoints } from '../theme/Breakpoints';
import { AtiraIcon } from './AtiraIcon';
import { Flex } from './Flex';
import { Text } from './Text';
import { UpgradeButton } from './UpgradeButton';

const ResponsiveContainer = styled(Flex)`
  align-items: center;
  @media (max-width: ${Breakpoints.DESKTOP}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

type PaidFeatureNoticeProps = {
  asPopup?: boolean;
  text?: string;
  children?: React.ReactNode;
};

export const PaidFeatureNotice: React.FC<PaidFeatureNoticeProps> = ({
  asPopup,
  text,
  children,
}) => {
  const { t } = useTranslation();

  if (asPopup) {
    return (
      <Tooltip title={text || t('paidPlanNotice.description')}>
        {children}
      </Tooltip>
    );
  }

  return (
    <Flex flexDirection="column">
      <Flex alignItems="center" gap="s">
        <Text color="subTextColor" fontWeight={'bold'}>
          {t('paidPlanNotice.title')}
        </Text>

        <AtiraIcon icon={faCrown} color="main" size="2x" />
      </Flex>
      <ResponsiveContainer gap="s">
        <Text color="subTextColor">{t('paidPlanNotice.description')}</Text>

        <UpgradeButton />
      </ResponsiveContainer>
    </Flex>
  );
};
