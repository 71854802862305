import { DefaultOptionType } from 'antd/es/select';
import { TFunction } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraSelect } from '../../../components/AtiraSelect';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { EditAtiraLinkDto } from '../../../model/atira-link/dto/EditAtiraLinkDto';
import { AtiraLinkLang } from '../../../model/settings/types/AtiraLinkLang.enum';
import { atiraLinkSliceSelectors } from '../../../redux/atira-link/atira-link.selector';
import { AtiraLinkActions } from '../../../redux/atira-link/atira-link.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { AtiraLinkPreviewPhone } from '../../my-link/components/AtiraLinkPreviewPhone';

const ContentWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};
  width: 100%;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: 80%;
  }
`;

const SectionTitle = styled(Text)`
  color: ${({ theme }) => theme.textColor};
  font-weight: bold;
  font-size: 1.3rem;
`;

const SectionWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  width: 100%;
`;

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.s};
`;

const StyledButton = styled(Button)`
  height: 2.3rem;
  width: 10rem;
  margin-top: ${Spacing.l};
`;

const PreviewPhoneWrapper = styled(Flex)`
  display: none;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: flex;
  }
`;

const languageOptions = (t: TFunction) =>
  [
    { label: t('common.arabic'), value: AtiraLinkLang.AR },
    { label: t('common.english'), value: AtiraLinkLang.EN },
  ] as DefaultOptionType[];

export const SettingsLanguage: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const selectedAtiraLink = useAppSelector(
    atiraLinkSliceSelectors.selectSelectedAtiraLink,
  );
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const formMethods = useForm<EditAtiraLinkDto>();

  const { getValues, handleSubmit, control, watch, reset } = formMethods;

  const dispatch = useAppDispatch();

  const currentLinkLang = watch('settings.lang.current');

  const onUpdate = async () => {
    try {
      setLoading(true);

      const dto = getValues();

      if (
        dto.settings?.lang?.current !==
        selectedAtiraLink?.settings?.lang?.current
      ) {
        await dispatch(AtiraLinkActions.editAtiraLink(dto)).unwrap();
        await dispatch(AtiraLinkActions.getUserAtiraLinks({ userId })).unwrap();
      }

      AtiraToast.success(t('settings.language.update.success'));
    } catch (e) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reset({
      atiraLinkId: selectedAtiraLink?._id,
      userId: userId,
      settings: {
        lang: {
          current:
            selectedAtiraLink?.settings?.lang?.current || AtiraLinkLang.EN,
        },
      },
    });
  }, [reset, selectedAtiraLink, userId]);

  return (
    <FormProvider {...formMethods}>
      <Flex justifyContent="space-between" gap="m">
        <ContentWrapper>
          <SectionWrapper>
            <SectionTitle>{t('common.language')}</SectionTitle>

            <Section>
              <Text color="subTextColor">
                {t('settings.language.description')}
              </Text>

              <Controller
                control={control}
                name="settings.lang.current"
                render={({ field: { value, onChange } }) => (
                  <AtiraSelect
                    value={value}
                    onChange={onChange}
                    options={languageOptions(t)}
                    title={t('common.language')}
                    containerStyle={{ width: '15rem' }}
                  />
                )}
              />

              <StyledButton loading={loading} onClick={handleSubmit(onUpdate)}>
                {t('common.update')}
              </StyledButton>
            </Section>
          </SectionWrapper>
        </ContentWrapper>

        <PreviewPhoneWrapper>
          <AtiraLinkPreviewPhone
            currentLinkLang={currentLinkLang}
            values={selectedAtiraLink!}
          />
        </PreviewPhoneWrapper>
      </Flex>
    </FormProvider>
  );
};
