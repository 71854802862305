import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../../../assets';
import { AtiraColumn } from '../../../components/AtiraColumn';
import { AtiraRow } from '../../../components/AtiraRow';
import { Flex } from '../../../components/Flex';
import { AtiraImage } from '../../../components/Image';
import { Text } from '../../../components/Text';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';

const Container = styled.section`
  display: flex;
  padding: ${Spacing.m} ${Spacing.s};
  background: linear-gradient(90deg, #2b2694 0%, #960baa 100%);
  padding-bottom: ${Spacing.xxl};
`;

const LTRRow = styled(AtiraRow)`
  direction: ltr;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media (min-width: ${Breakpoints.TABLET}) {
    &::after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-image: url(${Images.LandingPageHeroBackground});
      background-position: center;
      background-repeat: no-repeat;
      background-size: 60%;
      opacity: 0.3;
      z-index: -1;
    }
  }
`;

const ContentWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${Spacing.l};
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.sub};
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.75rem;
  }
`;

const CallToAction = styled(Link)`
  text-decoration: none;
  transition: all 0.3s;
  color: ${(props) => props.theme.main};
  background-color: ${(props) => props.theme.light};
  padding: 0.75rem 1rem;
  width: fit-content;
  border-radius: ${Rounded.md};
  text-align: center;
  margin: 0 auto;
  display: block;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.25rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.darkSub};
    color: ${({ theme }) => theme.textColor};
  }
`;

const MobileCallToAction = styled(CallToAction)`
  display: block;
  width: 100%;

  @media (min-width: ${Breakpoints.TABLET}) {
    display: none;
  }
`;

const ContentRow = styled(AtiraRow)`
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${Breakpoints.TABLET}) {
    .ant-col {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
`;

export const LandingPageHeroSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <LTRRow>
        <AtiraColumn xs={24} sm={24} md={22} lg={20} xl={20}>
          <ContentRow gutter={[10, 20]}>
            <AtiraColumn
              xs={{ order: 2, span: 12 }}
              sm={{ order: 2, span: 12 }}
              md={{ order: 1, span: 5 }}
              lg={{ order: 1, span: 5 }}
              xl={{ order: 1, span: 5 }}
            >
              <AtiraImage src={Images.LandingPageHeroLeftPhone} />
            </AtiraColumn>

            <AtiraColumn
              xs={{ order: 1, span: 24 }}
              sm={{ order: 1, span: 24 }}
              md={{ order: 2, span: 12 }}
              lg={{ order: 2, span: 12 }}
              xl={{ order: 2, span: 12 }}
            >
              <ContentWrapper>
                <Title>{t('landingPage.hero.title')}</Title>

                <Text fontSize="l" color="sub" align="center">
                  {t('landingPage.hero.paragraph')}
                </Text>

                <MobileCallToAction to={'/signup'}>
                  {t('common.signupForFreeNow')}
                </MobileCallToAction>
              </ContentWrapper>
            </AtiraColumn>

            <AtiraColumn
              xs={{ order: 3, span: 12 }}
              sm={{ order: 3, span: 12 }}
              md={{ order: 3, span: 5 }}
              lg={{ order: 3, span: 5 }}
              xl={{ order: 3, span: 5 }}
            >
              <AtiraImage src={Images.LandingPageHeroRightPhone} />
            </AtiraColumn>
          </ContentRow>
        </AtiraColumn>

        <AtiraColumn xs={0} sm={0} md={22} lg={20} xl={20}>
          <CallToAction to={'/signup'}>
            {t('common.signupForFreeNow')}
          </CallToAction>
        </AtiraColumn>
      </LTRRow>
    </Container>
  );
};
