import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraColumn } from '../../../components/AtiraColumn';
import { AtiraRow } from '../../../components/AtiraRow';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { atiraLinkSliceSelectors } from '../../../redux/atira-link/atira-link.selector';
import { useAppSelector } from '../../../redux/store';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.m};
`;

const SectionTitle = styled(Text)`
  color: ${({ theme }) => theme.textColor};
  font-weight: bold;
  font-size: 1.3rem;
`;

const SectionSecondTitle = styled(Text)`
  color: ${({ theme }) => theme.textColor};
  font-weight: bold;
  font-size: 1rem;
`;

const StyledNumber = styled(Text)`
  color: ${({ theme }) => theme.textColor};
`;

const Responsive = styled(Flex)`
  width: 100%;
  height: fit-content;

  @media (max-width: ${Breakpoints.TABLET}) {
    gap: ${Spacing.m};
    flex-direction: column;
  }
`;

const TextsWrapper = styled(Flex)`
  gap: ${Spacing.s};

  flex-wrap: wrap;
`;

export const DashboardDeviceStats: React.FC = () => {
  const { t } = useTranslation();

  const stats = useAppSelector(
    atiraLinkSliceSelectors.selectSelectedAtiraLinkStats,
  );

  return (
    <Section>
      <SectionTitle>{t('common.device')}</SectionTitle>
      <Flex flexDirection="column" gap="m">
        <SectionSecondTitle>{t('common.mobile')}</SectionSecondTitle>

        <AtiraRow justify={'space-between'}>
          <Responsive>
            <AtiraColumn>
              <TextsWrapper>
                <Text fontWeight={'bolder'}>{stats?.MOBILE.view || 0}</Text>
                <StyledNumber>{t('common.views')}</StyledNumber>
                <Text>/</Text>
                <Text fontWeight={'bolder'}>
                  {stats?.MOBILE.uniqueView || 0}
                </Text>
                <StyledNumber>{t('common.unique_views')}</StyledNumber>
              </TextsWrapper>
            </AtiraColumn>

            <AtiraColumn>
              <TextsWrapper>
                <Text fontWeight={'bolder'}>{stats?.MOBILE.click || 0}</Text>
                <StyledNumber>{t('common.clicks')}</StyledNumber>
                <Text>/</Text>
                <Text fontWeight={'bolder'}>
                  {stats?.MOBILE.uniqueClick || 0}
                </Text>
                <StyledNumber>{t('common.unique_clicks')}</StyledNumber>
              </TextsWrapper>
            </AtiraColumn>

            <AtiraColumn xl={4}>
              <TextsWrapper>
                <Text fontWeight={'bolder'}>{stats?.MOBILE.ctr || 0}</Text>
                <StyledNumber fontWeight={'bolder'}>
                  {t('common.ctr')}
                </StyledNumber>
              </TextsWrapper>
            </AtiraColumn>
          </Responsive>
        </AtiraRow>

        <SectionSecondTitle>{t('common.desktop')}</SectionSecondTitle>
        <AtiraRow justify={'space-between'}>
          <Responsive>
            <AtiraColumn>
              <TextsWrapper>
                <Text fontWeight={'bolder'}>{stats?.DESKTOP.view || 0}</Text>
                <StyledNumber>{t('common.views')}</StyledNumber>
                <Text>/</Text>
                <Text fontWeight={'bolder'}>
                  {stats?.DESKTOP.uniqueView || 0}
                </Text>
                <StyledNumber>{t('common.unique_views')}</StyledNumber>
              </TextsWrapper>
            </AtiraColumn>

            <AtiraColumn>
              <TextsWrapper>
                <Text fontWeight={'bolder'}>{stats?.DESKTOP.click || 0}</Text>
                <StyledNumber>{t('common.clicks')}</StyledNumber>
                <Text>/</Text>
                <Text fontWeight={'bolder'}>
                  {stats?.DESKTOP.uniqueClick || 0}
                </Text>
                <StyledNumber>{t('common.unique_clicks')}</StyledNumber>
              </TextsWrapper>
            </AtiraColumn>

            <AtiraColumn xl={4}>
              <TextsWrapper>
                <Text fontWeight={'bolder'}>{stats?.DESKTOP.ctr || 0}</Text>
                <StyledNumber fontWeight={'bolder'}>
                  {t('common.ctr')}
                </StyledNumber>
              </TextsWrapper>
            </AtiraColumn>
          </Responsive>
        </AtiraRow>
      </Flex>
    </Section>
  );
};
