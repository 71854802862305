import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';
import { DashboardActivityStats } from './components/DashboardActivityStats';
import { DashboardCountriesStats } from './components/DashboardCountriesStats';
import { DashboardDeviceStats } from './components/DashboardDeviceStats';
import { DashboardHeader } from './components/DashboardHeader';
import { DashboardSocialMediaStats } from './components/DashboardSocialMediaStats';

const Container = styled(Flex)`
  margin: ${Spacing.s} ${Spacing.m};
  gap: ${Spacing.m};
  flex-direction: column;
  flex: 1 1 0%;
`;

const SectionWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  width: 100%;
`;

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.m};
`;

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  useDocumentTitle(
    `${t('app.name').toUpperCase()} - ${t('side_menu.dashboard')}`,
  );

  return (
    <Container>
      <DashboardHeader />

      <SectionWrapper>
        <DashboardActivityStats />
        <DashboardDeviceStats />
        <DashboardSocialMediaStats />
        <DashboardCountriesStats />

        {process.env.APP_ENV === 'staging' ? (
          <Section>
            <Text fontSize="l" align="center" color={'danger'}>
              /\/\ STAGING /\/\
            </Text>
          </Section>
        ) : null}
      </SectionWrapper>
    </Container>
  );
};
