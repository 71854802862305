import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Banner } from '../components/Banner';
import { Text } from '../components/Text';
import { UpgradeButton } from '../components/UpgradeButton';
import { paymentActions } from '../redux/payment/payment.slice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { userSliceSelectors } from '../redux/user/user.selector';
import { userActions } from '../redux/user/user.slice';

export const RestrictedContentBanner: React.FC = () => {
  const [visible, setVisible] = useState(true);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const onCloseBanner = async () => {
    setVisible(false);
    await dispatch(
      paymentActions.closeRestrictedContentBanner({ userId }),
    ).unwrap();
    dispatch(userActions.getLoggedInUser({ userId }));
  };

  return (
    <Banner open={visible} onClose={onCloseBanner}>
      <Text fontSize="xm">{t('restrictedContentBanner.text')}</Text>

      <UpgradeButton />
    </Banner>
  );
};
