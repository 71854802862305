import React, { Suspense } from 'react';

import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';

const Analytics = React.lazy(() => import('./AnalyticsRoute'));

export const AnalyticsRouteWithLazy: React.FC = () => {
  return (
    <Suspense fallback={<SpinnerFullScreen />}>
      <Analytics />
    </Suspense>
  );
};
