import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraTable } from '../../../components/AtiraTable';
import { PageMeta } from '../../../model/meta/PageMeta';
import { LemonWebhook } from '../../../model/plan/LemonWebhook';
import { adminSliceSelectors } from '../../../redux/admin/admin.selector';
import { adminActions } from '../../../redux/admin/admin.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';
import { createAdminWebhookTableColumns } from '../components/createAdminWebhooksTableColumns';

const Wrapper = styled(AtiraTable<LemonWebhook>)`
  li.ant-pagination-item {
    margin: 0 0.3rem !important;
  }
`;

export const AdminPaymentTab: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const webhooks = useAppSelector(adminSliceSelectors.selectAllWebhooks);
  const pageSize = useAppSelector(
    adminSliceSelectors.selectWebhooksTablePageSize,
  );
  const page = useAppSelector(adminSliceSelectors.selectWebhooksTablePage);
  const meta = useAppSelector(adminSliceSelectors.selectWebhooksMeta);
  const loading = useAppSelector(adminSliceSelectors.selectWebhooksLoading);

  const onRetryWebhook = async (webhookId: string) => {
    try {
      await dispatch(adminActions.retryWebhook({ userId, webhookId })).unwrap();
      dispatch(
        adminActions.getWebhooks({
          userId,
          meta: PageMeta.create({
            count: pageSize,
            page: page - 1,
          }),
        }),
      );
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    }
  };

  const columns = createAdminWebhookTableColumns(webhooks, {
    onRetry: onRetryWebhook,
    t,
  });

  const onTableShowSize = (curr: number, newSize: number) => {
    dispatch(adminActions.setEntriesTablePageSize(newSize));
    onPageChange(1, newSize);
  };

  const onPageChange = async (page: number, pageSize: number) => {
    dispatch(adminActions.setEntriesTablePage(page));
  };

  useEffect(() => {
    dispatch(
      adminActions.getWebhooks({
        userId,
        meta: PageMeta.create({
          count: pageSize,
          page: page - 1,
        }),
      }),
    );
  }, [dispatch, page, pageSize, userId]);

  return (
    <Wrapper
      key={JSON.stringify(columns)}
      columns={columns}
      data={webhooks}
      loading={loading}
      rowSelection={undefined}
      size="small"
      scroll={{ x: 'max-content' }}
      pagination={{
        pageSizeOptions: ['5', '10', '20', '50', '100'],
        pageSize: pageSize,
        current: page,
        total: meta.total,
        onChange: onPageChange,
        showSizeChanger: true,
        onShowSizeChange: onTableShowSize,
      }}
    />
  );
};
