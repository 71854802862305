import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const adminState = (state: RootState) => state.admin;

const selectAdminStats = createSelector(adminState, (state) => state.stats);

const selectSearchUsers = createSelector(
  adminState,
  (state) => state.searchUsers || [],
);

const selectSearchUsersMeta = createSelector(
  adminState,
  (state) => state.searchUsersMeta || { count: 10, page: 0 },
);

const searchUsersLoading = createSelector(
  adminState,
  (state) => state.searchUsersLoading,
);

const selectReports = createSelector(
  adminState,
  (state) => state.reports || [],
);

const selectReportsTablePageSize = createSelector(
  adminState,
  (state) => state.reportsTablePageSize || 10,
);

const selectReportsTablePage = createSelector(
  adminState,
  (state) => state.reportsTablePage || 1,
);

const selectUsersTablePageSize = createSelector(
  adminState,
  (state) => state.usersTablePageSize || 10,
);

const selectUsersTablePage = createSelector(
  adminState,
  (state) => state.usersTablePage || 1,
);

const selectReportsLoading = createSelector(
  adminState,
  (state) => state.reportsLoading,
);

const selectReportsMeta = createSelector(
  adminState,
  (state) => state.reportsMeta || { count: 10, page: 0 },
);

const selectAdminMessages = createSelector(
  adminState,
  (state) => state.adminMessages || [],
);

const selectAdminMessagesLoading = createSelector(
  adminState,
  (state) => state.adminMessagesLoading,
);

const selectAdminMessagesPage = createSelector(
  adminState,
  (state) => state.adminMessagesPage || 1,
);

const selectAdminMessagesPageSize = createSelector(
  adminState,
  (state) => state.adminMessagesPageSize || 20,
);

const selectAllThemes = createSelector(
  adminState,
  (state) => state.themes || [],
);

const selectThemesLoading = createSelector(
  adminState,
  (state) => state.themesLoading,
);

const selectAllWebhooks = createSelector(
  adminState,
  (state) => state.webhooks || [],
);

const selectWebhooksTablePageSize = createSelector(
  adminState,
  (state) => state.webhooksPageSize || 10,
);

const selectWebhooksTablePage = createSelector(
  adminState,
  (state) => state.webhooksPage || 1,
);

const selectWebhooksMeta = createSelector(
  adminState,
  (state) => state.webhooksMeta || { count: 10, page: 0 },
);

const selectWebhooksLoading = createSelector(
  adminState,
  (state) => state.webhooksLoadig,
);

export const adminSliceSelectors = {
  selectAdminStats,
  searchUsersLoading,
  selectSearchUsersMeta,
  selectSearchUsers,
  selectReports,
  selectReportsMeta,
  selectReportsLoading,
  selectReportsTablePage,
  selectReportsTablePageSize,
  selectUsersTablePage,
  selectUsersTablePageSize,
  selectAdminMessages,
  selectAdminMessagesLoading,
  selectAdminMessagesPage,
  selectAdminMessagesPageSize,
  selectThemesLoading,
  selectAllThemes,
  selectAllWebhooks,
  selectWebhooksTablePageSize,
  selectWebhooksTablePage,
  selectWebhooksMeta,
  selectWebhooksLoading,
};
