import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons/faXmarkCircle';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import repeat from 'lodash/repeat';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraSelect } from '../../../components/AtiraSelect';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { ImageCreateEdit } from '../../../components/ImageCreateEdit';
import { ImageUpload } from '../../../components/ImageUpload';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { Modal } from '../../../components/modal/Modal';
import { AtiraLinkCategory } from '../../../model/atira-link/AtiraLinkCategory';
import { CreateCategoryDto } from '../../../model/atira-link/dto/CreateCategoryDto';
import { AtiraLinkCategoryAvailableLangs } from '../../../model/atira-link/types/AtiraLinkCategoryAvailableLangs.enum';
import { AtiraLinkCategoryType } from '../../../model/atira-link/types/AtiraLinkCategoryType.enum';
import { Lengths } from '../../../model/shared/enum/Lengths.enum';
import { atiraLinkIndexSliceSelectors } from '../../../redux/atira-link/atira-link-index.selector';
import { AtiraLinkIndexActions } from '../../../redux/atira-link/atira-link-index.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';

const Title = styled(Text)`
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: ${Spacing.l};
`;

const TranslationsTitle = styled(Title)`
  border-top: 1px solid ${({ theme }) => theme.textColor};
  padding-top: ${Spacing.s};
`;

const AddTranslationButton = styled(Button)`
  width: fit-content;
  margin-bottom: ${Spacing.l};
`;

const ExistTranslationsTabsWrapper = styled(Flex)`
  align-items: center;
  gap: ${Spacing.l};
  overflow-x: auto;
  width: 100%;
`;

const ExistTranslationsTab = styled(Button)<{ active: boolean }>`
  background-color: ${({ theme, active }) =>
    active ? theme.main : 'transparent'};
  color: ${({ theme, active }) => (active ? theme.light : theme.main)};
  border: 1px solid ${({ theme }) => theme.main};
`;

const DeleteTranslationButton = styled(Button)`
  margin: 0;
  padding: 0;
  border-radius: ${Rounded.circle};
  color: ${({ theme }) => theme.main};
  background-color: transparent;
`;

type AdminCreateCategoryModalProps = {
  open: boolean;
  onClose: VoidFunction;
};

export const AdminCreateCategoryModal: React.FC<
  AdminCreateCategoryModalProps
> = ({ open = false, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [selectedLang, setSelectedLang] =
    useState<AtiraLinkCategoryAvailableLangs | null>(null);
  const [categoryName, setCategoryName] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const categories = useAppSelector(
    atiraLinkIndexSliceSelectors.selectAllCategories,
  );

  const { control, handleSubmit, getValues, reset, setValue, watch } =
    useForm<CreateCategoryDto>({
      defaultValues: {
        name: '',
        description: '',
        image: undefined,
        availableTo: undefined,
        parentCategoryId: undefined,
      },
    });

  const existingTranslations = watch('locale');

  const onAddTranslation = () => {
    setValue('locale', {
      ...getValues('locale'),
      [selectedLang!]: {
        name: categoryName.trim(),
        description: categoryDescription.trim(),
      },
    });
    setSelectedLang(null);
    setCategoryName('');
    setCategoryDescription('');
  };

  const onClickTab = (lang: AtiraLinkCategoryAvailableLangs) => {
    setSelectedLang(lang);
    setCategoryName(existingTranslations?.[lang]?.name || '');
    setCategoryDescription(existingTranslations?.[lang]?.description || '');
  };

  const onDeleteTranslation = (lang: AtiraLinkCategoryAvailableLangs) => {
    setValue('locale', {
      ...getValues('locale'),
      [lang]: {
        name: '',
        description: '',
      },
    });

    setSelectedLang(null);
    setCategoryName('');
    setCategoryDescription('');
  };

  const getCategoryDropDownItems = () => {
    const processCategory = (
      category: AtiraLinkCategory,
      prefix = '',
    ): { label: string; value: string }[] => {
      const mainCategory = {
        label: `${prefix}${category.name}`,
        value: category._id,
      };

      const subCategories =
        category.subCategories?.flatMap((sub) =>
          processCategory(
            sub,
            `${repeat(String.fromCharCode(160), prefix.split(' ').length * 2)}↳ `,
          ),
        ) || [];

      return [mainCategory, ...subCategories];
    };

    return categories.flatMap((category) => processCategory(category));
  };
  const memoizedCategoryDropDownItems = useMemo(getCategoryDropDownItems, [
    categories,
  ]);

  const onCreate = async () => {
    try {
      setLoading(true);

      await dispatch(
        AtiraLinkIndexActions.createCatagory(
          omitBy(getValues(), isNil) as CreateCategoryDto,
        ),
      ).unwrap();
      await dispatch(AtiraLinkIndexActions.getAllCategoriesFlat()).unwrap();
      AtiraToast.success(t('admin.create.index.create.onSuccess'));
      reset({
        name: '',
        description: '',
        image: undefined,
        availableTo: undefined,
        parentCategoryId: undefined,
        locale: {},
      });
      onClose();
    } catch (e: any) {
      console.error(e);
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(AtiraLinkIndexActions.getAllCategories());
  }, [dispatch]);

  return (
    <Modal open={open} onClose={onClose} title={t('admin.create.index_modal')}>
      <Flex flexDirection="column" gap="m" width={'100%'}>
        <Title>{t('admin.category.info.title')}</Title>
        <Controller
          control={control}
          name="name"
          rules={{
            required: {
              value: true,
              message: t('common.this_field_required'),
            },
            maxLength: {
              value: Lengths.NAME,
              message: t('error.length.less', {
                name: t('common.name'),
                length: Lengths.NAME,
              }),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              maxLength={Lengths.NAME}
              value={value}
              onChange={(e) => onChange(e.target.value.toUpperCase())}
              required
              title={t('common.name')}
              placeholder={t('admin.index.create.modal.name.placeholder')}
              valid={!error?.message}
              errorMessage={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="availableTo"
          rules={{
            required: {
              value: true,
              message: t('common.this_field_required'),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <AtiraSelect
              valid={!error?.message}
              errorMessage={error?.message}
              onChange={onChange}
              value={value}
              required
              info={t('admin.category.type-info')}
              title={t('admin.category.type')}
              options={[
                {
                  label: t('admin.category.type.both'),
                  value: AtiraLinkCategoryType.BOTH,
                },
                {
                  label: t('admin.category.type.corporate'),
                  value: AtiraLinkCategoryType.CORPORATE,
                },
                {
                  label: t('admin.category.type.personal'),
                  value: AtiraLinkCategoryType.PERSONAL,
                },
              ]}
            />
          )}
        />

        <Controller
          control={control}
          name="parentCategoryId"
          render={({ field: { value, onChange } }) => (
            <AtiraSelect
              info={t('admin.category.parent-info')}
              title={t('admin.category.parent')}
              options={memoizedCategoryDropDownItems}
              value={value}
              labelRender={(props) => (
                <span className="ant-select-selection-item">
                  {(props.label as any)?.trim()}
                </span>
              )}
              onChange={onChange}
              allowClear
            />
          )}
        />

        <Controller
          control={control}
          name="description"
          rules={{
            maxLength: {
              value: Lengths.DESCRIPTION,
              message: t('error.length.less', {
                name: t('common.description'),
                length: Lengths.DESCRIPTION,
              }),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              value={value}
              onChange={onChange}
              errorMessage={error?.message}
              valid={!error?.message}
              title={t('common.description')}
            />
          )}
        />

        <Controller
          control={control}
          name="image"
          render={({ field: { onChange } }) => (
            <Flex flexDirection="column" gap="m">
              <Text>{t('common.image')}</Text>
              <ImageCreateEdit src={getValues('image')} />
              <ImageUpload
                maxCount={1}
                onChange={onChange}
                showUploadList={false}
              />
            </Flex>
          )}
        />

        <TranslationsTitle>
          {t('admin.category.translations.title')}
        </TranslationsTitle>

        <AtiraSelect
          title={t('common.language')}
          options={Object.values(AtiraLinkCategoryAvailableLangs)
            .filter(
              (lang) =>
                !existingTranslations?.[lang]?.name &&
                !existingTranslations?.[lang]?.description,
            )
            .map((lang) => ({
              label: t(`common.${lang.toLowerCase()}`),
              value: lang,
            }))}
          value={selectedLang || null}
          onChange={setSelectedLang}
        />

        {Object.keys(existingTranslations || {}).length ? (
          <ExistTranslationsTabsWrapper>
            {Object.entries(existingTranslations || {})
              .filter(([key, value]) => value?.name || value?.description)
              .map(([key, value]) => (
                <Flex alignItems="center" gap="s" key={key}>
                  <ExistTranslationsTab
                    key={key}
                    active={key.toLowerCase() === selectedLang?.toLowerCase()}
                    onClick={() =>
                      onClickTab(key as AtiraLinkCategoryAvailableLangs)
                    }
                  >
                    {t(`common.${key.toLowerCase()}`)}
                  </ExistTranslationsTab>

                  <DeleteTranslationButton
                    icon={faXmarkCircle}
                    onClick={() =>
                      onDeleteTranslation(
                        key as AtiraLinkCategoryAvailableLangs,
                      )
                    }
                  />
                </Flex>
              ))}
          </ExistTranslationsTabsWrapper>
        ) : null}

        {selectedLang ? (
          <>
            <Input
              title={t('common.title')}
              value={categoryName}
              onChange={(e) => setCategoryName(e.currentTarget.value)}
            />

            <Input
              title={t('common.description')}
              value={categoryDescription}
              onChange={(e) => setCategoryDescription(e.currentTarget.value)}
            />

            <AddTranslationButton
              onClick={onAddTranslation}
              disabled={!categoryName.length}
            >
              {t(
                `admin.category.translations.${existingTranslations?.[selectedLang]?.description || existingTranslations?.[selectedLang]?.name ? 'edit' : 'add'}`,
              )}
            </AddTranslationButton>
          </>
        ) : null}

        <Button
          height="2rem"
          title={t('common.submit')}
          onClick={handleSubmit(onCreate)}
          loading={loading}
        />
      </Flex>
    </Modal>
  );
};
