import isString from 'lodash/isString';

import { CreateAtiraLinkDto } from '../../model/atira-link/dto/CreateAtiraLinkDto';
import { EditAtiraLinkDto } from '../../model/atira-link/dto/EditAtiraLinkDto';
import { GenericWithUserId } from '../../model/shared/GenericWithUserId';
import { ThemeGetResponseDto } from '../../model/theme/dto/ThemeGetResponseDto';
import { AtiraLinkPlanThemeTier } from '../../model/theme/type/AtiraLinkPlanThemeTier';
import { DeleteAtiraLinkDto } from '../../redux/atira-link/dto/DeleteAtiraLinkDto';
import { GenericDisableEnableAtiraLinkDto } from '../../redux/atira-link/dto/GenericDisableEnableAtiraLinkDto';
import { AtiraApi } from '../AxiosClient';

export class AtiraLinkAxios {
  static async createAtiraLink(dto: CreateAtiraLinkDto) {
    const formData = new FormData();

    Object.entries(dto).forEach(([key, value]) => {
      formData.append(
        key,
        key === 'image' || isString(value) ? value : JSON.stringify(value),
      );
    });

    return AtiraApi.post<void>('/atira-link/create', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(({ data }) => data);
  }

  static async editAtiraLinkDto(dto: EditAtiraLinkDto) {
    const formData = new FormData();
    Object.entries(dto).forEach(([key, value]) => {
      formData.append(
        key,
        ['image', 'ogImage'].includes(key) || isString(value)
          ? value
          : JSON.stringify(value),
      );
    });

    return AtiraApi.patch<void>(
      `/atira-link/${dto.atiraLinkId}/edit`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    ).then(({ data }) => data);
  }

  static getUserAtiraLinks(dto: GenericWithUserId) {
    return AtiraApi.post(`/atira-link/get`, dto).then(({ data }) => data);
  }

  static deleteAtiraLink(dto: DeleteAtiraLinkDto) {
    return AtiraApi.post(`/atira-link/delete`, dto).then(({ data }) => data);
  }

  static disableAtiraLink(dto: GenericDisableEnableAtiraLinkDto) {
    return AtiraApi.post(`/atira-link/disable`, dto).then(({ data }) => data);
  }

  static enableAtiraLink(dto: GenericDisableEnableAtiraLinkDto) {
    return AtiraApi.post(`/atira-link/enable`, dto).then(({ data }) => data);
  }

  static getThemes(tier?: AtiraLinkPlanThemeTier) {
    return AtiraApi.get<ThemeGetResponseDto>(`/atira-link/theme`, {
      params: { tier },
    }).then(({ data }) => data);
  }
}
