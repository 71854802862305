import { faExternalLink } from '@fortawesome/free-solid-svg-icons/faExternalLink';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraColumn } from '../../components/AtiraColumn';
import { AtiraRow } from '../../components/AtiraRow';
import { Banner } from '../../components/Banner';
import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import { LemonSubscriptionStatus } from '../../model/plan/types/LemonSubscriptionStatus.enum';
import { useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Spacing } from '../../theme/Spacing';
import { PrivatePricingPlans } from '../shared/components/PrivatePricingPlans';

const Container = styled(Flex)`
  flex-wrap: wrap;
  gap: ${Spacing.xxl};
  justify-content: center;
  margin-bottom: ${Spacing.xxl};
  width: 100%;
`;

export const SubscriptionPlans: React.FC = () => {
  const { t } = useTranslation();
  const user = useAppSelector(userSliceSelectors.selectLoggedInUser)!;

  const displayBanner = [
    LemonSubscriptionStatus.PAST_DUE,
    LemonSubscriptionStatus.UNPAID,
  ].includes(user?.plan?.lemonPlanData?.status as LemonSubscriptionStatus);

  return (
    <Flex flex={1}>
      <Container>
        <AtiraRow justify={'center'}>
          <AtiraColumn sm={24} md={24} lg={24} xl={24}>
            <Flex flexDirection="column" gap="s">
              <Flex flexDirection="column">
                <Text fontWeight={'bold'} fontSize="l">
                  {t('subscription.page.paymentInfo.title')}
                </Text>
                <Text fontSize="m">
                  {t('subscription.page.paymentInfo.description')}
                </Text>
              </Flex>
              <PrivatePricingPlans />
            </Flex>
          </AtiraColumn>
        </AtiraRow>
      </Container>

      <Banner position="bottom" open={displayBanner}>
        <Flex flexDirection="column" gap="m" alignItems="center">
          <Text>{t('subscription.page.paymentInfo.banner.title')}</Text>
          <Flex justifyContent="center" gap="s" alignItems="center">
            <Text color="darkerSub">
              {t('subscription.page.paymentInfo.banner.description')}
            </Text>
            <Button
              icon={faExternalLink}
              hover
              onClick={() =>
                window.open(
                  user.plan?.lemonSubscriptionData?.subscription?.urls
                    ?.customer_portal_update_subscription,
                  '_blank',
                )
              }
            ></Button>
          </Flex>
        </Flex>
      </Banner>
    </Flex>
  );
};
