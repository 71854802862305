import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { Button, Upload, message } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex } from './Flex';
import { ImageCreateEdit } from './ImageCreateEdit';
import { Text } from './Text';

type UploadListType = 'text' | 'picture' | 'picture-card' | 'picture-circle';

const StyledUpload = styled(Upload)<{ valid: boolean }>`
  .ant-upload-select {
    display: block;
  }

  button {
    border: ${({ theme, valid }) =>
      `1px solid ${valid ? theme.darkSub : theme.danger}`} !important;
  }
`;

const StyledTitle = styled(Text)`
  font-size: initial;
`;

const RequiredSpan = styled.span`
  color: ${({ theme }) => theme.danger};
`;

const OptionalSpan = styled.span`
  color: ${({ theme }) => theme.subTextColor};
`;

const StyledImage = styled(ImageCreateEdit)`
  max-height: 20rem;
  aspect-ratio: initial;

  object-fit: contain !important;
`;

const uploadProps: UploadProps = {
  name: 'file',
  defaultFileList: [],
  beforeUpload: (file) => false,
  onChange(info) {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

type ImageUplaodProps = {
  onChange: (file: UploadFile) => void;
  defaultFiles?: UploadFile[];
  listType?: UploadListType;
  button?: React.ReactNode;
  showUploadList?: boolean;
  title?: string;
  required?: boolean;
  errorMessage?: string;
  valid?: boolean;
  displayImage?: boolean;
  displayImageSrc?: string;
} & UploadProps;

export const ImageUpload: React.FC<ImageUplaodProps> = ({
  onChange,
  defaultFiles,
  listType,
  button,
  showUploadList = true,
  required = false,
  valid = true,
  title,
  errorMessage,
  displayImage = false,
  displayImageSrc,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" gap="s">
      {title ? (
        <StyledTitle>
          {title}
          {required ? (
            <RequiredSpan> *</RequiredSpan>
          ) : (
            <OptionalSpan> ({t('common.optional')})</OptionalSpan>
          )}
        </StyledTitle>
      ) : null}

      {displayImage && displayImageSrc ? (
        <StyledImage src={displayImageSrc} />
      ) : null}

      <StyledUpload
        valid={valid}
        showUploadList={showUploadList}
        listType={listType}
        {...uploadProps}
        maxCount={1}
        defaultFileList={defaultFiles}
        onChange={(info) => {
          uploadProps.onChange!(info);
          if (info.file.status !== 'removed') {
            onChange(info.file);
          }
        }}
        {...props}
      >
        {button || (
          <Button disabled={props.disabled} icon={<UploadOutlined />}>
            {t('common.click_upload')}
          </Button>
        )}
      </StyledUpload>

      {errorMessage && !valid ? (
        <Text color="danger">{errorMessage}</Text>
      ) : null}
    </Flex>
  );
};
