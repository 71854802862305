import { createSlice } from '@reduxjs/toolkit';

import { AdminAxios } from '../../axios/admin/admin.axios';
import { AdminMessage } from '../../model/admin/AdminMessage';
import { AdminStats } from '../../model/admin/AdminStats';
import { AdminGetUserMessagesDto } from '../../model/admin/dto/AdminGetUserMessagesDto';
import { AdminGetUserMessagesResponseDto } from '../../model/admin/dto/AdminGetUserMessagesResponseDto';
import { AdminMessageUserDto } from '../../model/admin/dto/AdminMessageUserDto';
import { CreateThemeDto } from '../../model/admin/dto/CreateThemeDto';
import { EditThemeDto } from '../../model/admin/dto/EditThemeDto';
import { GetAdminStatsDto } from '../../model/admin/dto/GetAdminStatsDto';
import { GetReportsResponseDto } from '../../model/admin/dto/GetReportResponseDto';
import { GetReportsDto } from '../../model/admin/dto/GetReportsDto';
import { SearchUsersDto } from '../../model/admin/dto/SearchUsersDto';
import { SearchUsersResponseDto } from '../../model/admin/dto/SearchUsersResponseDto';
import { ToggleUserStatusDto } from '../../model/admin/dto/ToggleUserStatusDto';
import { PageDto } from '../../model/meta/PageDto';
import { GetWebhooksDto } from '../../model/plan/payment/dto/GetWebhooksDto';
import { GetWebhooksResponseDto } from '../../model/plan/payment/dto/GetWebhooksResponseDto';
import { RetryWebhookDto } from '../../model/plan/payment/dto/RetryWebhookDto';
import { GenericWithUserId } from '../../model/shared/dto/GenericWithUserId';
import { ThemeGetResponseDto } from '../../model/theme/dto/ThemeGetResponseDto';
import { SignupDto } from '../../model/user/dto/SignupDto';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from '../user/user.slice';

interface AdminReducer {
  stats: AdminStats;
  searchUsers: SearchUsersResponseDto['data'];
  searchUsersLoading: boolean;
  searchUsersMeta: PageDto;
  reports: GetReportsResponseDto['data'];
  reportsLoading: boolean;
  reportsTablePageSize: number;
  reportsTablePage: number;
  usersTablePageSize: number;
  usersTablePage: number;
  reportsMeta: GetReportsResponseDto['meta'];
  //
  adminMessages: AdminMessage[];
  adminMessagesLoading: boolean;
  adminMessagesPage: number;
  adminMessagesPageSize: number;
  //
  themes: ThemeGetResponseDto;
  themesLoading: boolean;
  //
  webhooks: GetWebhooksResponseDto['data'];
  webhooksPageSize: number;
  webhooksPage: number;
  webhooksMeta: GetWebhooksResponseDto['meta'];
  webhooksLoadig: boolean;
}

const initialState = Object.freeze<AdminReducer>({
  stats: {
    users: { lastMonth: 0, lastWeek: 0, total: 0 },
    atiraLinks: { lastMonth: 0, lastWeek: 0, total: 0 },
    QRs: { lastMonth: 0, lastWeek: 0, total: 0 },
    shortURLs: { lastMonth: 0, lastWeek: 0, total: 0 },
  },
  searchUsers: [],
  searchUsersLoading: false,
  searchUsersMeta: { count: 10, page: 0 },
  reportsMeta: { count: 10, page: 0 },
  reports: [],
  reportsLoading: false,
  reportsTablePage: 1,
  reportsTablePageSize: 0,
  usersTablePage: 1,
  usersTablePageSize: 0,
  adminMessages: [],
  adminMessagesLoading: false,
  adminMessagesPage: 1,
  adminMessagesPageSize: 20,
  themes: [],
  themesLoading: false,
  webhooks: [],
  webhooksMeta: { count: 10, page: 0 },
  webhooksPageSize: 0,
  webhooksPage: 1,
  webhooksLoadig: false,
});

const getAdminStats = AtiraThunk<AdminStats, GetAdminStatsDto>(
  '/admin/stats',
  AdminAxios.getAdminStats,
);

const searchUsers = AtiraThunk<SearchUsersResponseDto, SearchUsersDto>(
  '/admin/user/search',
  AdminAxios.searchUsers,
);

const getReports = AtiraThunk<GetReportsResponseDto, GetReportsDto>(
  '/report',
  AdminAxios.getReports,
);

const toggleUserStatus = AtiraThunk<void, ToggleUserStatusDto>(
  '/enable/:id/user',
  AdminAxios.toggleUserStatus,
);

const sendMessageToUser = AtiraThunk<void, AdminMessageUserDto>(
  '/admin/user/:id/message',
  AdminAxios.sendMessageToUser,
);

const getAdminMessages = AtiraThunk<
  AdminGetUserMessagesResponseDto,
  AdminGetUserMessagesDto
>('/admin/messages', AdminAxios.getAdminMessages);

const markAdminMessagesAsRead = AtiraThunk<void, GenericWithUserId>(
  '/admin/messages/mark-as-read',
  AdminAxios.markAdminMessagesAsRead,
);

const createTheme = AtiraThunk<void, CreateThemeDto>(
  '/admin/theme',
  AdminAxios.createTheme,
);

const getAllThemes = AtiraThunk<ThemeGetResponseDto, void>(
  '/theme',
  AdminAxios.getAllThemes,
);

const getWebhooks = AtiraThunk<GetWebhooksResponseDto, GetWebhooksDto>(
  'payment/getWebhooks',
  AdminAxios.getWebhooks,
);

const editTheme = AtiraThunk<void, EditThemeDto>(
  '/admin/theme/:id',
  AdminAxios.editTheme,
);

const retryWebhook = AtiraThunk<void, RetryWebhookDto>(
  '/payment/retry',
  AdminAxios.retryWebhook,
);

const createUser = AtiraThunk<void, SignupDto>(
  '/admin/user',
  AdminAxios.createUser,
);

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setReportsTablePageSize: (state, action) => {
      state.reportsTablePageSize = action.payload;
    },

    setReportsTablePage: (state, action) => {
      state.reportsTablePage = action.payload;
    },

    setUsersTablePageSize: (state, action) => {
      state.usersTablePageSize = action.payload;
    },

    setUsersTablePage: (state, action) => {
      state.usersTablePage = action.payload;
    },

    setEntriesTablePageSize: (state, action) => {
      state.webhooksPageSize = action.payload;
    },

    setEntriesTablePage: (state, action) => {
      state.webhooksPage = action.payload;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getAdminStats.fulfilled, (state, action) => {
      state.stats = action.payload;
    });

    addCase(searchUsers.pending, (state) => {
      state.searchUsersLoading = true;
    });

    addCase(searchUsers.fulfilled, (state, action) => {
      state.searchUsers = action.payload.data || [];
      state.searchUsersMeta = action.payload.meta;
      state.searchUsersLoading = false;
    });

    addCase(searchUsers.rejected, (state) => {
      state.searchUsersLoading = false;
    });

    addCase(getReports.pending, (state) => {
      state.reportsLoading = true;
    });

    addCase(getReports.rejected, (state) => {
      state.reportsLoading = false;
    });

    addCase(getReports.fulfilled, (state, action) => {
      state.reportsLoading = false;
      state.reportsMeta = action.payload.meta;
      state.reports = action.payload.data || [];
    });

    addCase(getAdminMessages.pending, (state) => {
      state.adminMessagesLoading = true;
    });
    addCase(getAdminMessages.fulfilled, (state, action) => {
      state.adminMessages = action.payload.data;
      state.adminMessagesLoading = false;
      state.adminMessagesPageSize = action.payload.meta.count;
      state.adminMessagesPage = action.payload.meta.page;
    });
    addCase(getAdminMessages.rejected, (state) => {
      state.adminMessagesLoading = false;
    });

    addCase(getAllThemes.pending, (state) => {
      state.themesLoading = true;
    });

    addCase(getAllThemes.fulfilled, (state, action) => {
      state.themes = action.payload;
      state.themesLoading = false;
    });

    addCase(getAllThemes.rejected, (state) => {
      state.themesLoading = false;
    });

    addCase(getWebhooks.pending, (state) => {
      state.webhooksLoadig = true;
    });
    addCase(getWebhooks.fulfilled, (state, action) => {
      state.webhooks = action.payload.data;
      state.webhooksMeta = action.payload.meta;
      state.webhooksLoadig = false;
    });
    addCase(getWebhooks.rejected, (state) => {
      state.webhooksLoadig = false;
    });

    addCase(userActions.logout.fulfilled, (state) => {
      return (state = initialState);
    });
  },
});

export const adminActions = {
  ...adminSlice.actions,
  getAdminStats,
  searchUsers,
  getReports,
  getAdminMessages,
  toggleUserStatus,
  sendMessageToUser,
  markAdminMessagesAsRead,
  createTheme,
  getAllThemes,
  editTheme,
  getWebhooks,
  retryWebhook,
  createUser,
};

export default adminSlice.reducer;
