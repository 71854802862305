import { Content } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Spacing } from '../theme/Spacing';
import { AtiraEmpty } from './AtiraEmpty';
import { Flex } from './Flex';

const StyledContent = styled(Content)`
  background: linear-gradient(
    90deg,
    rgba(35, 40, 147, 1) 41%,
    rgba(151, 11, 170, 1) 76%
  );
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BlurFlex = styled(Flex)`
  background-color: rgba(0, 0, 0, 0.2);
  padding: ${Spacing.xl};
  border-radius: ${Spacing.xl};
`;

export const EmptyPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledContent>
      <BlurFlex>
        <AtiraEmpty description={t('common.feature.coming_soon')} />
      </BlurFlex>
    </StyledContent>
  );
};
