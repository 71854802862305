import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { CopyButton } from '../../../components/CopyButton';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { Modal } from '../../../components/modal/Modal';
import { EditAtiraLinkDto } from '../../../model/atira-link/dto/EditAtiraLinkDto';
import { AtiraLinkActions } from '../../../redux/atira-link/atira-link.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { Regexes } from '../../../utils/String';

const VerificationTokenWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.darkSub};
  padding: ${Spacing.s};
  border-radius: ${Rounded.md};
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const StyledCopyButton = styled(CopyButton)`
  padding: 0;
`;

type SelfHostingVerificationTokenModalProps = {
  open: boolean;
  onClose: VoidFunction;
};

export const SelfHostingVerificationTokenModal: React.FC<
  SelfHostingVerificationTokenModalProps
> = ({ open = false, onClose }) => {
  const [submitLoading, setSubmitLoading] = useState(false);

  const { t } = useTranslation();

  const { control, getValues, handleSubmit } = useForm<EditAtiraLinkDto>();

  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const onSubmit = async () => {
    try {
      setSubmitLoading(true);

      const dto = getValues();

      await dispatch(AtiraLinkActions.editAtiraLink(dto)).unwrap();

      await dispatch(AtiraLinkActions.getUserAtiraLinks({ userId })).unwrap();

      AtiraToast.success(t('settings.self_hosting.domain.update.success'));
    } catch (e) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Modal
      title={t('settings.self_hosting.verification_token.modal.title')}
      open={open}
      onClose={onClose}
    >
      <Flex width={'100%'} flexDirection="column" gap="m">
        <VerificationTokenWrapper>
          <Text>ldnvkfdvnn</Text>

          <StyledCopyButton copy="knfdkjv" />
        </VerificationTokenWrapper>

        <Flex flexDirection="column" gap="m">
          <Text>{t('common.note')}</Text>
        </Flex>

        <Controller
          control={control}
          //@ts-ignore
          name="domain"
          rules={{
            required: true,
            pattern: {
              value: Regexes.DOMAIN_REGEX,
              message: t('settings.self_hosting.domain.error'),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              //@ts-ignore
              value={value}
              onChange={onChange}
              title={t('settings.self_hosting.domain.title')}
              valid={!error}
              errorMessage={error?.message}
              required
            />
          )}
        />

        <Button
          loading={submitLoading}
          onClick={handleSubmit(onSubmit)}
          height="2.5rem"
        >
          {t('common.submit')}
        </Button>
      </Flex>
    </Modal>
  );
};
