import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { Tooltip } from 'antd';
import { updatedDiff } from 'deep-object-diff';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraSelect } from '../../../components/AtiraSelect';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { ImageUpload } from '../../../components/ImageUpload';
import { Textarea } from '../../../components/Textarea';
import { Modal } from '../../../components/modal/Modal';
import { EditThemeDto } from '../../../model/admin/dto/EditThemeDto';
import { AtiraLinkThemes } from '../../../model/atira-link/types/AtiraLinkThemes.enum';
import { AtiraLinkPlanType } from '../../../model/plan/types/AtiraLinkPlanType.enum';
import { Lengths } from '../../../model/shared/enum/Lengths.enum';
import { AtiraLinkPlanThemeTier } from '../../../model/theme/type/AtiraLinkPlanThemeTier';
import { AtiraLinkThemeStatus } from '../../../model/theme/type/AtiraLinkThemeStatus.enum';
import { adminSliceSelectors } from '../../../redux/admin/admin.selector';
import { adminActions } from '../../../redux/admin/admin.slice';
import { paymentSliceSelectors } from '../../../redux/payment/payment.selector';
import { paymentActions } from '../../../redux/payment/payment.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { getPlanTitle } from '../../pricing/pricingUtils';
import { TemplatePreviewModal } from '../../shared/components/TemplatePreviewModal';

const Wrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: ${Spacing.m};
`;

const StyledButton = styled(Button)`
  margin: 0;
  margin-top: ${Spacing.m};
  height: 2.3rem;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
  }
`;

const StyledImageUpload = styled(ImageUpload)`
  padding-bottom: ${Spacing.l};
  border-bottom: ${({ theme }) => `2px solid ${theme.textColor}`};
`;

const PreviewButton = styled(Button)`
  margin: 0;
  margin-top: auto;
  padding: ${Spacing.s};
  height: 2.3rem;
`;

const StyledTextarea = styled(Textarea)`
  border: ${({ theme }) => `1px solid ${theme.textColor}`} !important;
`;

type AdminThemeManageModalProps = {
  open: boolean;
  onClose: VoidFunction;
};

export const AdminThemeManageModal: React.FC<AdminThemeManageModalProps> = ({
  open = false,
  onClose,
}) => {
  const themes = useAppSelector(adminSliceSelectors.selectAllThemes);

  const [loading, setLoading] = useState(false);
  const [previewModalVisible, setPreviewModalVisible] = useState(false);

  const { t } = useTranslation();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const plans = useAppSelector(paymentSliceSelectors.selectPlans);

  const { control, handleSubmit, getValues, reset, watch } =
    useForm<EditThemeDto>();

  const dispatch = useAppDispatch();

  const [themeId, themeName] = watch(['themeId', 'name']);

  const themesOptions = useMemo(() => {
    return themes.map((theme) => ({
      label: theme.name,
      value: theme._id,
    }));
  }, [themes]);

  const onReset = (newValues: Record<string, any>) => {
    reset(newValues);
  };

  const onEdit = async () => {
    try {
      setLoading(true);

      const dto = getValues();

      const selectedTheme = themes.find((theme) => theme._id === dto.themeId);

      const payload = updatedDiff(
        {
          imageMini: selectedTheme?.images.mini,
          imageSide: selectedTheme?.images.side,
          imageFull: selectedTheme?.images.full,
          themeId: selectedTheme?._id,
          name: selectedTheme?.name,
          description: selectedTheme?.description,
          userId: selectedTheme?.userId,
          status: selectedTheme?.status,
          tier: selectedTheme?.tier,
        },
        dto,
      ) as EditThemeDto;

      if (Object.keys(payload).length) {
        payload.userId = userId;
        payload.themeId = selectedTheme?._id!;

        await dispatch(adminActions.editTheme(payload)).unwrap();
        await dispatch(adminActions.getAllThemes()).unwrap();
      }

      AtiraToast.success(t('admin.themes.manage.success'));

      onReset({
        name: '',
        description: '',
        imageMini: undefined,
        imageSide: undefined,
        imageFull: undefined,
        status: undefined,
        availableTo: [],
      });
      onClose();
    } catch (e) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
    }
  };

  const onSelectTheme = (themeId: string) => {
    const selectedTheme = themes?.find((theme) => theme._id === themeId)!;

    onReset({
      name: selectedTheme?.name || '',
      description: selectedTheme?.description || '',
      imageMini: (selectedTheme?.images?.mini as any) || undefined,
      imageSide: (selectedTheme?.images?.side as any) || undefined,
      imageFull: (selectedTheme?.images?.full as any) || undefined,
      themeId: selectedTheme?._id || '',
      userId: selectedTheme?.userId || '',
      status: selectedTheme?.status || '',
      tier: selectedTheme?.tier || [],
    });
  };

  const onLocalClose = () => {
    onReset({
      name: '',
      description: '',
      imageMini: undefined,
      imageSide: undefined,
      imageFull: undefined,
      status: undefined,
      availableTo: [],
    });
    onClose();
  };

  useEffect(() => {
    dispatch(paymentActions.getAllPlans());
  }, [dispatch]);

  return (
    <Modal
      open={open}
      onClose={onLocalClose}
      title={t('admin.themes.manage.modal.title')}
    >
      <Wrapper>
        <Flex gap="s">
          <Controller
            control={control}
            name="name"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <AtiraSelect
                value={value ? value : null}
                onChange={(v) => {
                  onChange(v);
                  onSelectTheme(v);
                }}
                options={themesOptions}
                valid={!error}
                errorMessage={error?.message}
                title={t('common.theme')}
                required
                showSearch
                filterOption={(input, option) =>
                  (option?.label as string)
                    ?.toLowerCase()
                    .includes(input.toLowerCase())
                }
                allowClear
                containerStyle={{ flexGrow: 1 }}
                style={{ height: '2.3rem' }}
              />
            )}
          />

          <Tooltip title={t('admin.themes.preview_theme')}>
            <PreviewButton
              onClick={() => setPreviewModalVisible(true)}
              disabled={!themeId}
              icon={faEye}
            />
          </Tooltip>
        </Flex>

        <Controller
          control={control}
          name="tier"
          rules={{
            required: {
              value: true,
              message: t('common.this_field_required'),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <AtiraSelect
              value={value}
              onChange={onChange}
              options={Object.entries(AtiraLinkPlanThemeTier).map(
                ([key, tier]) => ({
                  label: t(getPlanTitle(key as AtiraLinkPlanType)),
                  value: tier,
                }),
              )}
              valid={!error}
              errorMessage={error?.message}
              title={t('admin.themes.manage.modal.availableTo.title')}
              info={t('admin.themes.manage.modal.availableTo.info')}
              required
              disabled={!themeId}
              containerStyle={{ flexGrow: 1 }}
              style={{ height: '2.3rem' }}
            />
          )}
        />

        <Controller
          control={control}
          name="status"
          rules={{
            required: {
              value: true,
              message: t('common.this_field_required'),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <AtiraSelect
              value={value || null}
              onChange={onChange}
              options={Object.keys(AtiraLinkThemeStatus).map((key) => ({
                label: t(`admin.theme.status.title-${key.toLowerCase()}`),
                value: key,
              }))}
              valid={!error}
              errorMessage={error?.message}
              title={t('common.status')}
              required
              disabled={!themeId}
              containerStyle={{ flexGrow: 1 }}
              style={{ height: '2.3rem' }}
            />
          )}
        />

        <Controller
          control={control}
          name="description"
          rules={{
            maxLength: {
              value: Lengths.NAME,
              message: t('error.length.less', {
                length: Lengths.DESCRIPTION,
                name: t('common.description'),
              }),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <StyledTextarea
              value={value}
              onChange={onChange}
              valid={!error}
              errorMessage={error?.message}
              title={t('common.description')}
              disabled={!themeId}
            />
          )}
        />

        <Controller
          control={control}
          name="imageMini"
          render={({ field: { onChange, value } }) => (
            <StyledImageUpload
              onChange={onChange}
              title={t('admin.themes.manage.modal.image.mini.title')}
              maxCount={1}
              displayImage
              displayImageSrc={value}
              showUploadList={false}
              disabled={!themeId}
            />
          )}
        />

        <Controller
          control={control}
          name="imageSide"
          render={({ field: { onChange, value } }) => (
            <StyledImageUpload
              onChange={onChange}
              title={t('admin.themes.manage.modal.image.side.title')}
              maxCount={1}
              displayImage
              displayImageSrc={value}
              showUploadList={false}
              disabled={!themeId}
            />
          )}
        />

        <Controller
          control={control}
          name="imageFull"
          render={({ field: { onChange, value } }) => (
            <StyledImageUpload
              onChange={onChange}
              title={t('admin.themes.manage.modal.image.full.title')}
              maxCount={1}
              displayImage
              displayImageSrc={value}
              showUploadList={false}
              disabled={!themeId}
            />
          )}
        />

        <StyledButton
          disabled={!themeId}
          loading={loading}
          onClick={handleSubmit(onEdit)}
        >
          {t('common.edit')}
        </StyledButton>
      </Wrapper>

      <TemplatePreviewModal
        selectedTheme={
          AtiraLinkThemes[themeName as keyof typeof AtiraLinkThemes]
        }
        open={previewModalVisible}
        onClose={() => setPreviewModalVisible(false)}
      />
    </Modal>
  );
};
