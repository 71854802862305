import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faPinterest } from '@fortawesome/free-brands-svg-icons/faPinterest';
import { faReddit } from '@fortawesome/free-brands-svg-icons/faReddit';
import { faSnapchat } from '@fortawesome/free-brands-svg-icons/faSnapchat';
import { faSpotify } from '@fortawesome/free-brands-svg-icons/faSpotify';
import { faTelegram } from '@fortawesome/free-brands-svg-icons/faTelegram';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { t } from 'i18next';
import React from 'react';
import styled from 'styled-components';

import { AtiraColumn } from '../../../components/AtiraColumn';
import { AtiraIcon } from '../../../components/AtiraIcon';
import { AtiraRow } from '../../../components/AtiraRow';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { AtiraLinkSocialHandlers } from '../../../model/atira-link/types/AtiraLinkSocialMediaHandlers.enum';
import { atiraLinkSliceSelectors } from '../../../redux/atira-link/atira-link.selector';
import { useAppSelector } from '../../../redux/store';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';

const socialMediaIcons: Record<AtiraLinkSocialHandlers, IconDefinition> = {
  WHATSAPP: faWhatsapp,
  INSTAGRAM: faInstagram,
  TWITTER: faXTwitter,
  FACEBOOK: faFacebook,
  YOUTUBE: faYoutube,
  TELEGRAM: faTelegram,
  TIKTOK: faTiktok,
  SNAPCHAT: faSnapchat,
  REDDIT: faReddit,
  LINKEDIN: faLinkedin,
  PINTEREST: faPinterest,
  SPOTIFY: faSpotify,
  TWITCH: faTwitch,
  GITHUB: faGithub,
};

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.m};
`;

const SectionTitle = styled(Text)`
  color: ${({ theme }) => theme.textColor};
  font-weight: bold;
  font-size: 1.3rem;
`;

const StyledNumber = styled(Text)`
  color: ${({ theme }) => theme.textColor};
`;

const Responsive = styled(Flex)`
  width: 100%;
  height: fit-content;

  @media (max-width: ${Breakpoints.TABLET}) {
    gap: ${Spacing.m};
    flex-direction: column;
  }
`;

export const DashboardSocialMediaStats: React.FC = () => {
  const stats = useAppSelector(
    atiraLinkSliceSelectors.selectSelectedAtiraLinkStats,
  );

  return (
    <Section>
      <SectionTitle> {t('common.social_media')}</SectionTitle>
      <Flex flexDirection="column" gap="m">
        <AtiraRow justify={'space-between'}>
          <Responsive>
            <AtiraColumn>
              <Flex flexDirection="column" gap="m">
                {stats?.SOCIAL?.length ? (
                  stats.SOCIAL.map((clickItem, index) => (
                    <Flex
                      gap="s"
                      alignItems="center"
                      key={`${clickItem}-${index}`}
                    >
                      <AtiraIcon
                        icon={
                          socialMediaIcons[
                            clickItem.handler as AtiraLinkSocialHandlers
                          ]
                        }
                        size="2x"
                        color="textColor"
                      />
                      <Text
                        fontWeight={'bolder'}
                      >{`${clickItem.count || 0} `}</Text>
                      <StyledNumber>
                        {t(`common.${clickItem.handler.toLowerCase()}`)}
                      </StyledNumber>
                    </Flex>
                  ))
                ) : (
                  <Text>{t('dashboard.social.empty')}</Text>
                )}
              </Flex>
            </AtiraColumn>
          </Responsive>
        </AtiraRow>
      </Flex>
    </Section>
  );
};
