import isString from 'lodash/isString';

import { AdminStats } from '../../model/admin/AdminStats';
import { AdminGetUserMessagesDto } from '../../model/admin/dto/AdminGetUserMessagesDto';
import { AdminGetUserMessagesResponseDto } from '../../model/admin/dto/AdminGetUserMessagesResponseDto';
import { AdminMessageUserDto } from '../../model/admin/dto/AdminMessageUserDto';
import { CreateThemeDto } from '../../model/admin/dto/CreateThemeDto';
import { EditThemeDto } from '../../model/admin/dto/EditThemeDto';
import { GetAdminStatsDto } from '../../model/admin/dto/GetAdminStatsDto';
import { GetReportsResponseDto } from '../../model/admin/dto/GetReportResponseDto';
import { GetReportsDto } from '../../model/admin/dto/GetReportsDto';
import { SearchUsersDto } from '../../model/admin/dto/SearchUsersDto';
import { SearchUsersResponseDto } from '../../model/admin/dto/SearchUsersResponseDto';
import { ToggleUserStatusDto } from '../../model/admin/dto/ToggleUserStatusDto';
import { GetWebhooksDto } from '../../model/plan/payment/dto/GetWebhooksDto';
import { GetWebhooksResponseDto } from '../../model/plan/payment/dto/GetWebhooksResponseDto';
import { RetryWebhookDto } from '../../model/plan/payment/dto/RetryWebhookDto';
import { GenericWithUserId } from '../../model/shared/dto/GenericWithUserId';
import { ThemeGetResponseDto } from '../../model/theme/dto/ThemeGetResponseDto';
import { SignupDto } from '../../model/user/dto/SignupDto';
import { AtiraApi } from '../AxiosClient';

export class AdminAxios {
  static getAdminStats(dto: GetAdminStatsDto) {
    return AtiraApi.post<AdminStats>('/admin/stats', dto).then(
      ({ data }) => data,
    );
  }

  static searchUsers(dto: SearchUsersDto) {
    return AtiraApi.post<SearchUsersResponseDto>(
      `/admin/user/search`,
      dto,
    ).then(({ data }) => data);
  }

  static getReports(dto: GetReportsDto) {
    return AtiraApi.post<GetReportsResponseDto>('/report/all', dto).then(
      ({ data }) => data,
    );
  }

  static toggleUserStatus(dto: ToggleUserStatusDto) {
    return AtiraApi.post<void>(`/admin/user/${dto.userId}/toggle-status`).then(
      ({ data }) => data,
    );
  }

  static sendMessageToUser(dto: AdminMessageUserDto) {
    return AtiraApi.post<void>(`/admin/user/${dto.userId}/message`, {
      message: dto.message,
    }).then(({ data }) => data);
  }

  static getAdminMessages(dto: AdminGetUserMessagesDto) {
    return AtiraApi.post<AdminGetUserMessagesResponseDto>(
      `/admin/user/${dto.userId}/get-messages`,
      dto,
    ).then(({ data }) => data);
  }

  static markAdminMessagesAsRead(dto: GenericWithUserId) {
    return AtiraApi.patch<void>(
      `/admin/user/${dto.userId}/mark-messages-as-read`,
    ).then(({ data }) => data);
  }

  static createTheme(dto: CreateThemeDto) {
    const formData = new FormData();

    Object.entries(dto).forEach(([key, value]) => {
      const isImage =
        key === 'imageMini' || key === 'imageFull' || key === 'imageSide';

      formData.append(
        key,
        isImage || isString(value) ? value : JSON.stringify(value),
      );
    });

    return AtiraApi.post<void>('/admin/theme', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(({ data }) => data);
  }

  static getAllThemes() {
    return AtiraApi.get<ThemeGetResponseDto>('/admin/theme').then(
      ({ data }) => data,
    );
  }

  static editTheme(dto: EditThemeDto) {
    const formData = new FormData();

    Object.entries(dto).forEach(([key, value]) => {
      const isImage =
        key === 'imageMini' || key === 'imageFull' || key === 'imageSide';

      formData.append(
        key,
        isImage || isString(value) ? value : JSON.stringify(value),
      );
    });

    return AtiraApi.patch<void>(`/admin/theme/${dto.themeId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(({ data }) => data);
  }

  static getWebhooks(dto: GetWebhooksDto) {
    return AtiraApi.post<GetWebhooksResponseDto>('/admin/webhooks', dto).then(
      ({ data }) => data,
    );
  }

  static retryWebhook(dto: RetryWebhookDto) {
    return AtiraApi.patch<void>(
      `/admin/webhooks/${dto.webhookId}/retry`,
      dto,
    ).then(({ data }) => data);
  }

  static createUser(dto: SignupDto) {
    return AtiraApi.post<void>('/admin/user', dto).then(({ data }) => data);
  }
}
