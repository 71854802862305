import { Table, TableProps } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AppLangs } from '../i18n';
import { Rounded } from '../theme/Rounded';
import { Flex } from './Flex';

interface AtiraTableProps<T extends Record<string, any>>
  extends Omit<TableProps<T>, 'dataSource'> {
  data: T[];
  columns: TableProps<T>['columns'];
  loading?: boolean;
  selectedRowKeys?: React.Key[];
  onSelectedRowKeysChange?: ATVoidFunction<React.Key[]>;
  size?: 'small';
}

const StyledTableWrapper = styled.div<{ size?: 'small' }>`
  .ant-table-wrapper {
    border-radius: ${Rounded.md};
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    border-bottom: 0.1rem solid black;
  }

  .ant-table-placeholder,
  .ant-table-placeholder:hover .ant-table-cell {
    background: ${({ theme }) => theme.light} !important;
    color: ${({ theme }) => theme.subTextColor} !important;
  }

  .ant-table-placeholder * {
    color: ${({ theme }) => theme.subTextColor} !important;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-right: 0.01rem solid lightgrey;
  }

  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background-color: ${({ theme }) => theme.light};
  }

  .ant-table-cell {
    color: ${({ theme }) => theme.textColor} !important;
  }

  .ant-table-column-sort {
    background-color: ${({ theme }) => theme.light} !important;
  }

  .ant-table-row:hover .ant-table-cell {
    background: ${({ theme }) => theme.darkSub} !important;
  }

  .ant-table-thead .ant-table-cell {
    background-color: ${({ theme }) => theme.light} !important;
    color: ${({ theme }) => theme.textColor} !important;
  }

  .ant-select-selector {
    background-color: ${({ theme }) => theme.light} !important;
    color: ${({ theme }) => theme.textColor} !important;
  }

  .ant-select-arrow {
    color: ${({ theme }) => theme.textColor} !important;
  }

  .ant-pagination-item-link {
    color: ${({ theme }) => theme.textColor} !important;
  }

  .ant-pagination-item {
    border-color: ${({ theme }) => theme.textColor} !important;
    background-color: ${({ theme }) => theme.light} !important;
    color: ${({ theme }) => theme.textColor} !important;
  }

  .ant-pagination-item-active {
    background-color: ${({ theme }) => theme.textColor} !important;
    color: ${({ theme }) => theme.sub} !important;
  }

  .ant-pagination-item a {
    color: inherit;
  }

  .ant-table-tbody {
    background-color: ${({ theme }) => theme.light} !important;
  }

  .ant-table-column-sorter svg {
    fill: ${({ theme }) => theme.textColor} !important ;
  }

  ${({ size }) =>
    size === 'small'
      ? `
  .ant-table-tbody > tr > td {
    padding: 0 8px !important;
    height: 2.5rem;
  }
`
      : ''}

  .ant-pagination {
    gap: 0.2rem;
  }
`;

export function AtiraTable<T extends Record<string, any>>({
  data,
  columns,
  loading = false,
  selectedRowKeys,
  onSelectedRowKeysChange,
  ...props
}: AtiraTableProps<T>) {
  const { i18n } = useTranslation();

  const rowSelection: TableRowSelection<T> = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: T[]) =>
      onSelectedRowKeysChange?.(newSelectedRowKeys),
  };

  return (
    <Flex flexDirection="column" width="100%">
      <StyledTableWrapper size={props.size}>
        <Table
          dataSource={data.map((x, i) => ({
            ...x,
            key: `table-row-${i}`,
          }))}
          rowSelection={props.rowSelection ? rowSelection : undefined}
          columns={columns?.filter((col: any) => col?.data?.enabled !== false)}
          scroll={{ x: 'max-content' }}
          loading={loading}
          style={{ overflowY: 'hidden', height: '100%' }}
          rowHoverable
          direction={i18n.language === AppLangs.AR ? 'rtl' : 'ltr'}
          {...props}
        />
      </StyledTableWrapper>
    </Flex>
  );
}
