import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { Header } from 'antd/es/layout/layout';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../assets';
import { AtiraColumn } from '../components/AtiraColumn';
import { AtiraIcon } from '../components/AtiraIcon';
import { AtiraRow } from '../components/AtiraRow';
import { Button } from '../components/Button';
import { Drawer } from '../components/Drawer';
import { Flex } from '../components/Flex';
import { AtiraImage } from '../components/Image';
import { LanguageSwitcher } from '../components/LanguageSwitcher';
import i18n, { AppLangs } from '../i18n';
import { Breakpoints } from '../theme/Breakpoints';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';

const getHeaderLinks = (t: TFunction) => [
  {
    path: '/',
    title: t('common.gettingStarted'),
  },
  {
    path: 'https://index.atiralink.com',
    title: t('common.atiraIndex'),
    isExternal: true,
  },
  {
    path: '/templates',
    title: t('common.templates'),
  },
  {
    path: '/pricing',
    title: t('common.pricing'),
  },
  {
    path: '/help',
    title: t('common.help_support'),
  },
];

const StyledHeader = styled(Header)<{ onRegisterPages: boolean }>`
  width: 100%;
  background-image: ${({ onRegisterPages, theme }) =>
    `linear-gradient(
    90deg, ${onRegisterPages ? theme.main : 'rgb(151, 11, 170) 0%'}
    ,${onRegisterPages ? theme.main : 'rgb(35, 40, 147) 99%'}
  )`};
  position: sticky;
  top: 0rem;
  z-index: 999;
  display: flex;
  align-items: center;
  height: fit-content;
  padding: ${Spacing.m};
  line-height: normal;
`;

const HeaderLink = styled(Link)<{ isActive?: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? theme.lightMain : theme.light)};
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  font-size: ${({ isActive }) => (isActive ? '1.1rem' : '1rem')};
  text-decoration: none;
  border-radius: ${Rounded.sm};
  padding: ${Spacing.s};
  transition: all 0.3s;

  &:hover {
    color: ${(props) => props.theme.lightMain};
  }
`;
const HeaderLinksSection = styled(AtiraColumn)`
  display: flex;

  @media (max-width: ${Breakpoints.DESKTOP}) {
    display: none;
  }
`;

const StartButton = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.main};
  padding: ${Spacing.s} ${Spacing.m};
  border-radius: ${Rounded.pill};
  background-color: ${(props) => props.theme.light};
  font-size: 0.75rem;
  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.sub};
    color: ${(props) => props.theme.dark};
  }
  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.1rem;
  }
`;

const DrawerButton = styled(AtiraColumn)`
  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: none;
  }

  @media (max-width: ${Breakpoints.TABLET}) {
    display: inline-block;
  }
`;

const HeaderLogoWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  @media (min-width: ${Breakpoints.DESKTOP}) {
    justify-content: flex-start;
  }
`;

const DrawerLink = styled(Link)<{ isActive?: boolean }>`
  font-size: 1.5rem;
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    box-shadow: none !important;
  }
  .ant-drawer-title {
    font-size: 1.7rem;
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    justify-content: space-between;
    font-size: 1rem;
  }
  .anticon-close svg {
    background-color: ${({ theme }) => theme.danger};
    opacity: 0.7;
    height: 1.8rem;
    width: 1.8rem;
    padding: 0.2rem;
    border-radius: ${Rounded.sm};
    transition: all 0.2s;
  }
  .anticon-close svg:hover {
    opacity: 1;
  }
  .anticon-close svg path {
    color: ${({ theme }) => theme.dark};
  }
  * {
    color: ${({ theme }) => theme.light};
  }
`;

const StyledNav = styled.nav`
  width: 100%;
`;

const HeaderLinksList = styled.ul`
  display: flex;
  gap: ${Spacing.l};
  align-items: center;
  justify-content: center;
  width: 100%;
  list-style-type: none;
`;

const drawerStyle = {
  background:
    'linear-gradient(90deg, rgba(14, 5, 138, 1) 40%, rgba(255, 255, 255, 0) 100%)',
};

const DrawerLinksList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.m};
  list-style-type: none;
`;

const DrawerLinkLi = styled.li<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${Spacing.s};
  transition: all 0.3s;
  text-underline-offset: 10px;
  transform: ${({ isActive }) =>
    isActive
      ? `translateX(${i18n.language === AppLangs.AR ? '-10px' : '10px'})`
      : 'none'};
  text-decoration: ${({ isActive }) => (isActive ? 'underline' : 'none')};

  &:hover {
    transform: translateX(${i18n.language === AppLangs.AR ? '-10px' : '10px'});
    text-decoration: underline;
  }
`;

export const PublicHeader: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const location = useLocation();

  const onRegisterPages =
    location.pathname.includes('forgot-password') ||
    location.pathname.includes('login') ||
    location.pathname.includes('signup');

  const headerLinks = getHeaderLinks(t);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <StyledHeader onRegisterPages={onRegisterPages}>
      <AtiraRow gutter={[0, 20]} justify={'space-between'} align={'middle'}>
        <AtiraColumn
          xs={onRegisterPages ? 12 : 24}
          sm={onRegisterPages ? 12 : 24}
          md={onRegisterPages ? 12 : 24}
          lg={6}
          xl={5}
        >
          <HeaderLogoWrapper>
            <Link to={'/'}>
              <AtiraImage
                src={Images.AtiraLinkSubLogo}
                style={{ maxWidth: '20rem' }}
              />
            </Link>

            {!onRegisterPages ? (
              <DrawerButton xs={6} sm={6} md={6} lg={8} xl={8}>
                <Flex justifyContent="flex-end">
                  <Button icon={faBars} onClick={showDrawer} />
                </Flex>
              </DrawerButton>
            ) : null}
          </HeaderLogoWrapper>
        </AtiraColumn>

        <AtiraColumn
          xs={onRegisterPages ? 12 : 0}
          sm={onRegisterPages ? 12 : 0}
          md={onRegisterPages ? 12 : 0}
          lg={0}
          xl={0}
        >
          <Flex justifyContent="flex-end">
            <Button icon={faBars} onClick={showDrawer} />
            <LanguageSwitcher />
          </Flex>
        </AtiraColumn>

        <HeaderLinksSection xs={0} sm={0} md={12} lg={10} xl={12}>
          <StyledNav>
            <HeaderLinksList>
              {headerLinks.map((link) => (
                <li key={link.path}>
                  <HeaderLink
                    to={link.path}
                    {...(link.isExternal
                      ? { target: '_blank' }
                      : { isActive: isActive(link.path) })}
                  >
                    {link.title}
                  </HeaderLink>
                </li>
              ))}
            </HeaderLinksList>
          </StyledNav>
        </HeaderLinksSection>

        <AtiraColumn
          xs={onRegisterPages ? 0 : 24}
          sm={onRegisterPages ? 0 : 24}
          md={onRegisterPages ? 0 : 24}
          lg={8}
          xl={6}
        >
          <AtiraRow align={'middle'} justify={'space-between'}>
            <AtiraColumn xs={7} sm={6} md={6} lg={0} xl={0}>
              <Flex>
                <LanguageSwitcher />
              </Flex>
            </AtiraColumn>

            <AtiraColumn xs={16} sm={12} md={12} lg={24} xl={24}>
              <Flex justifyContent="flex-end" gap="m" alignItems="center">
                <AtiraColumn xs={0} sm={0} md={0} lg={4} xl={4}>
                  <LanguageSwitcher />
                </AtiraColumn>
                <Flex gap="s" alignItems="center">
                  <StartButton to={'/login'}>{t('common.login')}</StartButton>

                  <StartButton to={'/signup'}>{t('common.signup')}</StartButton>
                </Flex>
              </Flex>
            </AtiraColumn>
          </AtiraRow>
        </AtiraColumn>
      </AtiraRow>

      <StyledDrawer
        onClose={onClose}
        open={open}
        placement="left"
        style={drawerStyle}
      >
        <nav>
          <DrawerLinksList>
            {headerLinks.map((link) => (
              <DrawerLinkLi
                key={link.path}
                {...(!link.isExternal ? { isActive: isActive(link.path) } : {})}
              >
                <AtiraIcon icon={faCircle} color="light" size="xs" />

                <DrawerLink
                  to={link.path}
                  {...(link.isExternal ? { target: '_blank' } : {})}
                  onClick={onClose}
                >
                  {link.title}
                </DrawerLink>
              </DrawerLinkLi>
            ))}
          </DrawerLinksList>
        </nav>
      </StyledDrawer>
    </StyledHeader>
  );
};
