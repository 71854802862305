import { createSlice } from '@reduxjs/toolkit';

import { PaymentAxios } from '../../axios/payment/payment.axios';
import { ChangeUserPlanDto } from '../../model/plan/payment/dto/ChangeUserPlanDto';
import { CreateCheckoutDto } from '../../model/plan/payment/dto/CreateCheckoutDto';
import { CreateUnpaidPlanDto } from '../../model/plan/payment/dto/CreateUnpaidPlanDto';
import { GetAllPlanResponseDto } from '../../model/plan/payment/dto/GetAllPlansResponseDto';
import { GenericWithUserId } from '../../model/shared/GenericWithUserId';
import { AtiraThunk } from '../AtiraThunk';

interface PaymentReducer {
  currentCheckoutURL: string | undefined;
  currenCheckoutURLLoading: boolean;
  //
  plans?: GetAllPlanResponseDto;
  plansLoading?: boolean;
}

const initialState = Object.freeze<PaymentReducer>({
  currentCheckoutURL: '',
  currenCheckoutURLLoading: false,
  plans: undefined,
  plansLoading: false,
});

const createCheckout = AtiraThunk<string, CreateCheckoutDto>(
  'payment/createCheckout',
  PaymentAxios.createCheckout,
);

const getAllPlans = AtiraThunk<GetAllPlanResponseDto, void>(
  '/plan',
  PaymentAxios.getAllPlans,
);

const cancelPlan = AtiraThunk<void, void>(
  '/plan/cancel',
  PaymentAxios.cancelPlan,
);

const resumeCancelledPlan = AtiraThunk<void, void>(
  '/plan/resume',
  PaymentAxios.resumeCancelledPlan,
);

const getUpdatePaymentMethodURL = AtiraThunk<string, void>(
  '/plan/update-payment-method',
  PaymentAxios.getUpdatePaymentMethodURL,
);

const changePlan = AtiraThunk<void, ChangeUserPlanDto>(
  '/plan/change',
  PaymentAxios.changePlan,
);

const startFreeTrial = AtiraThunk<void, void>(
  '/plan/start-free-trial',
  PaymentAxios.startFreeTrial,
);

const startUnpaidPlan = AtiraThunk<void, CreateUnpaidPlanDto>(
  '/plan/start-unpaid-plan',
  PaymentAxios.startUnpaidPlan,
);

const closeRestrictedContentBanner = AtiraThunk<void, GenericWithUserId>(
  '/payment/close-restricted-content-banner',
  (dto) => PaymentAxios.closeRestrictedContentBanner(dto),
);

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    resetCheckoutURL: (state) => {
      state.currentCheckoutURL = undefined;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(createCheckout.pending, (state, action) => {
      state.currenCheckoutURLLoading = true;
    });
    addCase(createCheckout.fulfilled, (state, action) => {
      state.currentCheckoutURL = action.payload;
      state.currenCheckoutURLLoading = false;
    });
    addCase(createCheckout.rejected, (state, action) => {
      state.currenCheckoutURLLoading = false;
    });

    addCase(getAllPlans.pending, (state) => {
      state.plansLoading = true;
    });
    addCase(getAllPlans.fulfilled, (state, action) => {
      state.plans = action.payload;
      state.plansLoading = false;
    });
    addCase(getAllPlans.rejected, (state) => {
      state.plansLoading = false;
    });
  },
});

export const paymentActions = {
  ...paymentSlice.actions,
  createCheckout,
  getAllPlans,
  cancelPlan,
  resumeCancelledPlan,
  getUpdatePaymentMethodURL,
  changePlan,
  startFreeTrial,
  closeRestrictedContentBanner,
  startUnpaidPlan,
};

export default paymentSlice.reducer;
