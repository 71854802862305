import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../../assets';
import { AtiraColumn } from '../../components/AtiraColumn';
import { AtiraIcon } from '../../components/AtiraIcon';
import { AtiraRow } from '../../components/AtiraRow';
import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { PageHeroSection } from '../../components/PageHeroSection';
import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';
import { Text } from '../../components/Text';
import { AtiraLink } from '../../model/atira-link/AtiraLink';
import { AtiraLinkSocialHandlers } from '../../model/atira-link/types/AtiraLinkSocialMediaHandlers.enum';
import { AtiraLinkThemes } from '../../model/atira-link/types/AtiraLinkThemes.enum';
import { AtiraLinkTheme } from '../../model/theme/AtiraLinkTheme';
import { AtiraLinkPlanThemeTier } from '../../model/theme/type/AtiraLinkPlanThemeTier';
import { atiraLinkSliceSelectors } from '../../redux/atira-link/atira-link.selector';
import { AtiraLinkActions } from '../../redux/atira-link/atira-link.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { Breakpoints } from '../../theme/Breakpoints';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';
import { PhonePreviewIframe } from '../my-link/components/PhonePreviewIframe';
import { TemplatePreviewModal } from '../shared/components/TemplatePreviewModal';

const previewPlaceholderData = {
  linkName: 'preview',
  customLinks: [{ label: 'Custom Link', value: 'https://atiralink.com' }],
  title: 'Template',
  bio: 'Atira Link offers a variety of templates that you might love',
  email: 'preview@atiralink',
  phone: '1234567890',
  socials: [
    {
      parentId: '',
      handle: AtiraLinkSocialHandlers.INSTAGRAM,
      url: 'https://www.instagram.com/',
      label: AtiraLinkSocialHandlers.INSTAGRAM,
    },
    {
      parentId: '',
      handle: AtiraLinkSocialHandlers.TWITTER,
      url: 'https://www.twitter.com/',
      label: AtiraLinkSocialHandlers.TWITTER,
    },
    {
      parentId: '',
      handle: AtiraLinkSocialHandlers.YOUTUBE,
      url: 'https://www.youtube.com/',
      label: AtiraLinkSocialHandlers.YOUTUBE,
    },
  ],
} as Partial<AtiraLink> & { linkName: string };

const StyledRow = styled(AtiraRow)`
  justify-content: center;
  padding-block: ${Spacing.m};
`;

const StyledSection = styled.section`
  margin-top: ${Spacing.xxl};
`;

const ThemeWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.s};
  width: 100%;
  margin: ${Spacing.s} 0;
`;

const ThemeImageWrapper = styled(Flex)`
  border-radius: ${Rounded.xxl};
  width: 100%;
  aspect-ratio: 9 / 16;
  overflow: hidden !important;
  cursor: pointer;
  border: ${({ theme }) => `3px solid ${theme.darkerSub}`};
  position: relative;

  img {
    object-fit: cover;
  }

  .ant-image:first-child {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  background: linear-gradient(90deg, #960baa 0%, #2b2694 100%);
  color: ${({ theme }) => theme.sub};
  font-size: 0.8rem;
  width: 100%;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: ${Spacing.s};
  border-radius: ${Rounded.lg};

  &:hover {
    color: ${({ theme }) => theme.sub};
    box-shadow:
      rgba(0, 0, 0, 0.4) 0px 2px 4px,
      rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
      rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transform: scale(1.05);
  }

  @media (min-width: ${Breakpoints.LARGE_DESKTOP}) {
    font-size: 1rem;
  }
`;

const PreviewButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  font-weight: bold;
  background-color: transparent;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 1;
    background-color: #652d90dd;
  }
`;

const ThemeName = styled(Text)`
  color: transparent;
  background-clip: text;
  background-image: linear-gradient(#960baa 25%, #2b2694);
  font-size: 1.25rem;
  font-weight: bold;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.5rem;
  }
`;

const FilterButtonsWrapper = styled(Flex)`
  gap: ${Spacing.m};
  justify-content: center;
  margin-bottom: ${Spacing.xl};
  padding: 0 ${Spacing.s};
`;

const FilterButton = styled(Button)<{ active: boolean }>`
  background-color: ${({ theme, active }) =>
    active ? theme.main : 'transparent'};
  color: ${({ theme, active }) => (active ? theme.sub : theme.main)};
  border: ${({ theme }) => `1px solid ${theme.main}`};
  width: 5rem;
  font-size: 0.8rem;

  &:hover {
    background-color: ${({ theme }) => theme.main};
    color: ${({ theme }) => theme.sub};
  }

  @media (min-width: ${Breakpoints.TABLET}) {
    width: 7rem;
    font-size: 1rem;
  }
`;

const ThemesRow = styled(AtiraRow)`
  min-height: 60vh;

  .ant-col {
    padding-inline: ${Spacing.s} !important;
    padding-bottom: ${Spacing.xl};
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    .ant-col {
      padding-inline: ${Spacing.l} !important;
    }
  }
`;

export const Templates: React.FC = () => {
  const [selectedTheme, setSelectedTheme] = useState<AtiraLinkThemes | null>(
    null,
  );
  const [themesTier, setThemesTier] = useState<
    AtiraLinkPlanThemeTier | undefined
  >(undefined);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const themes = useAppSelector(atiraLinkSliceSelectors.selectThemes);

  const themesLoading = useAppSelector(
    atiraLinkSliceSelectors.selectThemesLoading,
  );

  const onFilterByTier = (tier?: AtiraLinkPlanThemeTier) => {
    setThemesTier(tier);

    dispatch(AtiraLinkActions.getThemes(tier));
  };

  const renderTheme = useMemo(
    () => (theme: AtiraLinkTheme) => {
      const onUseTheme = (theme: AtiraLinkThemes) => {
        localStorage.setItem('selectedThemeFromTemplates', theme);
        navigate('/login');
      };

      return (
        <AtiraColumn key={theme._id} xs={12} sm={12} md={8} lg={8} xl={6}>
          <ThemeWrapper>
            <ThemeName>{theme.name}</ThemeName>

            <ThemeImageWrapper>
              <PhonePreviewIframe
                key={theme._id}
                data={{
                  ...previewPlaceholderData,
                  theme: theme.name as AtiraLinkThemes,
                }}
              />

              <PreviewButton
                onClick={() => setSelectedTheme(theme.name as AtiraLinkThemes)}
              >
                {t('common.preview')}
              </PreviewButton>
            </ThemeImageWrapper>

            {theme.tier !== AtiraLinkPlanThemeTier.PLAN_0 ? (
              <Tooltip title={t('common.use_now.premium')}>
                <StyledButton
                  onClick={() =>
                    navigate('/pricing?targetSection=plans-section')
                  }
                >
                  <AtiraIcon icon={faCrown} />
                  <Text color="light" align="center">
                    {t('common.use_now')}
                  </Text>
                </StyledButton>
              </Tooltip>
            ) : (
              <Tooltip title={t('common.use_now.free')}>
                <StyledButton
                  onClick={() => onUseTheme(theme.name as AtiraLinkThemes)}
                >
                  <Text color="light" align="center">
                    {t('common.use_now')}
                  </Text>
                </StyledButton>
              </Tooltip>
            )}
          </ThemeWrapper>
        </AtiraColumn>
      );
    },
    [t, navigate],
  );

  useEffect(() => {
    dispatch(AtiraLinkActions.getThemes());
  }, [dispatch]);

  return (
    <Fragment>
      <PageHeroSection
        title={t('templates.title')}
        description={t('templates.description')}
        image={Images.TemplatesHeroImage}
      />

      <StyledSection>
        <StyledRow>
          <AtiraColumn xs={24} sm={24} md={22} lg={20} xl={20}>
            <FilterButtonsWrapper>
              <FilterButton
                active={!themesTier}
                onClick={() => onFilterByTier(undefined)}
              >
                {t('common.all')}
              </FilterButton>

              <FilterButton
                active={themesTier === AtiraLinkPlanThemeTier.PLAN_0}
                onClick={() => onFilterByTier(AtiraLinkPlanThemeTier.PLAN_0)}
              >
                {t('plan.type.free')}
              </FilterButton>

              <FilterButton
                active={themesTier === AtiraLinkPlanThemeTier.PLAN_1}
                onClick={() => onFilterByTier(AtiraLinkPlanThemeTier.PLAN_1)}
              >
                {t('plan.type.basic')}
              </FilterButton>

              <FilterButton
                active={themesTier === AtiraLinkPlanThemeTier.PLAN_2}
                onClick={() => onFilterByTier(AtiraLinkPlanThemeTier.PLAN_2)}
              >
                {t('plan.type.pro')}
              </FilterButton>
            </FilterButtonsWrapper>

            <ThemesRow>
              {themesLoading && !themes?.length ? (
                <SpinnerFullScreen />
              ) : (
                themes?.map(renderTheme)
              )}
            </ThemesRow>
          </AtiraColumn>
        </StyledRow>
      </StyledSection>

      <TemplatePreviewModal
        selectedTheme={selectedTheme}
        onClose={() => setSelectedTheme(null)}
        open={selectedTheme !== null}
      />
    </Fragment>
  );
};
