import { NavigateFunction } from 'react-router-dom';

export const onNavigateWithRedirect = (navigate: NavigateFunction) => {
  const selectedThemeFromTemplates = localStorage.getItem(
    'selectedThemeFromTemplates',
  );

  if (selectedThemeFromTemplates && selectedThemeFromTemplates.length) {
    return navigate('/my-link', { replace: true });
  }

  return navigate('/dashboard', { replace: true });
};
