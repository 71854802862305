import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { MenuProps } from 'antd/es/menu/menu';
import React from 'react';
import styled from 'styled-components';

import { Images } from '../assets';
import i18n, { AppLangs, removeLangParamFromUrl } from '../i18n';
import { Spacing } from '../theme/Spacing';
import { ThemeType } from '../theme/Theme';
import { AtiraDropdown } from './AtiraDropdown';
import { AtiraIcon } from './AtiraIcon';
import { Button } from './Button';
import { Flex } from './Flex';
import { AtiraImage } from './Image';
import { Text } from './Text';

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: ${Spacing.s};
  padding: 0;
  margin: 0;
  background-color: ${({ theme }) => theme.transparent};
`;

const LanguageWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
`;

const changeLanguage = (lang: AppLangs) => {
  removeLangParamFromUrl();
  i18n.changeLanguage(lang);
};

const items: MenuProps['items'] = [
  {
    key: 'g1',
    type: 'group',
    children: [
      {
        key: 'en',
        title: '',
        label: (
          <LanguageWrapper>
            <AtiraImage src={Images.USAFlag} width={30} />
          </LanguageWrapper>
        ),
        onClick: () => changeLanguage(AppLangs.EN),
      },
      {
        key: 'tr',
        title: '',
        label: (
          <LanguageWrapper>
            <AtiraImage src={Images.TurkishFlag} width={30} />
          </LanguageWrapper>
        ),
        onClick: () => changeLanguage(AppLangs.TR),
      },
      {
        key: 'ar',
        title: '',
        label: (
          <LanguageWrapper>
            <AtiraImage src={Images.SaudiFlag} width={30} />
          </LanguageWrapper>
        ),
        onClick: () => changeLanguage(AppLangs.AR),
      },
    ],
  },
];

type LanguageSwitcherProps = {
  color?: keyof ThemeType;
  popupPlacement?: 'bottom' | 'topRight';
};

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  color = 'lightTextColor',
  popupPlacement = 'bottom',
}) => {
  const currentLanguage = i18n.language;

  return (
    <AtiraDropdown
      items={items}
      trigger={['click']}
      arrow={{ pointAtCenter: true }}
      placement={popupPlacement}
    >
      <StyledButton>
        <AtiraIcon icon={faGlobe} size="2x" color={color} />
        <Flex
          className={
            currentLanguage === AppLangs.AR
              ? 'atira-arabic-font'
              : 'atira-english-font'
          }
        >
          <Text color={color}>
            {currentLanguage.toLowerCase() === AppLangs.AR
              ? 'ع'
              : currentLanguage.toUpperCase()}
          </Text>
        </Flex>
      </StyledButton>
    </AtiraDropdown>
  );
};
