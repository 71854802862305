/* eslint-disable no-nested-ternary */
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons/faArrowUpFromBracket';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons/faCircleUser';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faMoon } from '@fortawesome/free-solid-svg-icons/faMoon';
import { faSun } from '@fortawesome/free-solid-svg-icons/faSun';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { faUsersGear } from '@fortawesome/free-solid-svg-icons/faUsersGear';
import { Badge, MenuProps, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Themes, useTheme } from '../ThemeContext';
import { UserRoles } from '../model/user/types/UserRoles.enum';
import { adminSliceSelectors } from '../redux/admin/admin.selector';
import { atiraLinkSliceSelectors } from '../redux/atira-link/atira-link.selector';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { userSliceSelectors } from '../redux/user/user.selector';
import { userActions } from '../redux/user/user.slice';
import { NotificationsDrawer } from '../routes/shared/components/NotificationsDrawer';
import { SelectLinkToManageModal } from '../routes/shared/components/SelectLinkToManageModal';
import { ShareDrawer } from '../routes/shared/components/ShareDrawer';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';
import { truncateString } from '../utils/String';
import { AtiraDropdown } from './AtiraDropdown';
import { AtiraIcon } from './AtiraIcon';
import { Button } from './Button';
import { Flex } from './Flex';
import { AtiraImage } from './Image';
import { ImageCreateEdit } from './ImageCreateEdit';
import { LanguageSwitcher } from './LanguageSwitcher';
import { Text } from './Text';

const Container = styled(Flex)`
  align-items: center;
  gap: ${Spacing.l};
`;

const UserProfileWrapper = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${Rounded.circle};
  width: 4rem;
  height: 4rem;
  overflow: hidden;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.textColor};
  margin: 0;
  padding: 0;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.textColor};
  display: flex;

  &:hover {
    color: ${({ theme }) => theme.textColor};
  }
`;

const StyledSubscribeLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.textColor};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: ${Spacing.m};
  border-radius: ${Rounded.pill};
  color: ${({ theme }) => theme.lightTextColor};
  background: linear-gradient(0deg, #2b2694, #960baa);
  transition: all 0.3s;
  height: 2rem;
  min-width: 8rem;
  background: linear-gradient(0deg, #2b2694, #960baa);

  &:hover {
    color: ${({ theme }) => theme.light};
    box-shadow: 0px 4px 4px 0px #00000040;
    transform: scale(1.02);
  }
`;

const StyledBadge = styled(Badge)`
  display: flex;
`;

const ManageLinksButton = styled(StyledButton)`
  position: relative;
`;

const SelectedLinkImageWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  position: absolute;
  top: -5px;
  right: -5px;
  width: 1rem;
  aspect-ratio: 1 / 1;
  border-radius: ${Rounded.circle};
  overflow: hidden;

  img {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    display: block;
  }
`;

export const UserHeader: React.FC = () => {
  const [openShareDrawer, setOpenShareDrawer] = useState(false);
  const [notificationsDrawerVisible, setNotificationsDrawerVisible] =
    useState(false);
  const [selectLinkModalVisible, setSelectLinkModalVisible] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { theme, toggleTheme, currentTheme } = useTheme();

  const user = useAppSelector(userSliceSelectors.selectLoggedInUser);
  const unreadMessages = useAppSelector(
    adminSliceSelectors.selectAdminMessages,
  )?.filter(({ read }) => read === false);
  const selectedLink = useAppSelector(
    atiraLinkSliceSelectors.selectSelectedAtiraLink,
  );
  const userLinks = useAppSelector(
    atiraLinkSliceSelectors.selectUserAtiraLinks,
  );

  const onLogout = () => {
    dispatch(userActions.logout());
    localStorage.removeItem('selectedThemeFromTemplates');
    if (currentTheme === Themes.DARK) {
      toggleTheme();
    }
    navigate('/login', { replace: true });
  };

  const items: MenuProps['items'] = [
    {
      key: 'g1',
      type: 'group',
      children: [
        {
          key: '2',
          label: (
            <Text fontSize="m" color="textColor">
              {user?.email ? (
                <Tooltip title={user.email}>
                  {truncateString(user.email)}
                </Tooltip>
              ) : (
                t('common.user_email')
              )}
            </Text>
          ),
        },
      ],
    },
    {
      key: '3',
      label: <hr />,
    },
    {
      key: '6',
      label: (
        <Button
          onClick={onLogout}
          fontSize="1rem"
          backgroundColor="transparent"
          color="textColor"
          border={`0.1rem solid ${theme.main}`}
          textAlign="center"
          width="100%"
        >
          {t('header.logout')}
        </Button>
      ),
      className: 'logout-button',
    },
  ];

  return (
    <Container>
      <StyledSubscribeLink to={'/subscription'}>
        {t('header.subscription')}
      </StyledSubscribeLink>

      <Tooltip title={t('header.links.tooltip')}>
        <ManageLinksButton
          onClick={() => setSelectLinkModalVisible(true)}
          icon={faLink}
          iconWidth="2xl"
          iconColor="subTextColor"
        >
          <SelectedLinkImageWrapper>
            {selectedLink ? (
              selectedLink?.image ? (
                <ImageCreateEdit src={selectedLink.image} />
              ) : (
                <AtiraIcon icon={faUserCircle} color="main" />
              )
            ) : (
              <AtiraIcon icon={faPlusCircle} color="main" />
            )}
          </SelectedLinkImageWrapper>
        </ManageLinksButton>
      </Tooltip>

      {userLinks.length ? (
        <Tooltip title={t('common.share')}>
          <StyledButton
            onClick={() => setOpenShareDrawer(true)}
            icon={faArrowUpFromBracket}
            iconWidth="2xl"
            iconColor="subTextColor"
          />
        </Tooltip>
      ) : null}

      <Tooltip title={t('header.toggle_theme')}>
        <StyledButton
          onClick={toggleTheme}
          icon={currentTheme === Themes.LIGHT ? faMoon : faSun}
          iconWidth="2xl"
          iconColor="subTextColor"
        />
      </Tooltip>
      <LanguageSwitcher color="subTextColor" />

      <ShareDrawer
        open={openShareDrawer}
        onClose={() => setOpenShareDrawer(false)}
      />

      {user?.roles?.includes(UserRoles.ADMIN) ? (
        <Tooltip title={t('admin.header.title')}>
          <StyledLink to={'/admin'}>
            <AtiraIcon icon={faUsersGear} size="2x" color="subTextColor" />
          </StyledLink>
        </Tooltip>
      ) : null}

      <Tooltip title={t('common.notifications')}>
        <StyledBadge
          count={unreadMessages?.length}
          color={unreadMessages?.length ? theme.main : theme.transparent}
        >
          <StyledButton
            icon={faBell}
            iconWidth="2x"
            onClick={() => setNotificationsDrawerVisible(true)}
            color="lightTextColor"
            iconColor="subTextColor"
          />
        </StyledBadge>
      </Tooltip>

      <Tooltip title={t('common.settings')}>
        <StyledLink to={'/settings'}>
          <AtiraIcon icon={faGear} size="2x" color="subTextColor" />
        </StyledLink>
      </Tooltip>

      <AtiraDropdown
        overlayStyle={{
          width: '15rem',
          borderRadius: '0.5rem',
        }}
        items={items}
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
        trigger={['click']}
      >
        <UserProfileWrapper>
          {user?.picture ? (
            <AtiraImage src={user.picture} />
          ) : (
            <AtiraIcon icon={faCircleUser} size="4x" color="main" />
          )}
        </UserProfileWrapper>
      </AtiraDropdown>

      <NotificationsDrawer
        open={notificationsDrawerVisible}
        onClose={() => setNotificationsDrawerVisible(false)}
      />

      <SelectLinkToManageModal
        open={selectLinkModalVisible}
        onClose={() => setSelectLinkModalVisible(false)}
      />
    </Container>
  );
};
