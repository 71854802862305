import { faCircleUser } from '@fortawesome/free-solid-svg-icons/faCircleUser';
import isString from 'lodash/isString';
import React from 'react';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import i18n, { AppLangs } from '../../../i18n';
import { AtiraLink } from '../../../model/atira-link/AtiraLink';
import { getMediaUrl } from '../../../utils/Media';
import { TemplatePreviewMode } from '../../shared/model/TemplatePreviewMode.enum';
import { AtiraLinkLeftSide } from './partials/AtiraLinkLeftSide';

const linkLanguageStrings = {
  socialsTabTitle: 'Socials',
  embedTabTitle: 'Other',
  copy: 'Copy',
  shareViaWhatsapp: 'Share via Whatsapp',
  shareViaEmail: 'Share via Email',
  shareOnSocialMedia: 'Share on Social Media',
  reportThisLink: 'Report this link',
  downloadAsContactFile: 'Download as contact file (vcf)',
  scanQR: 'Scan this QR code to open the link',
  showFromMobile: 'Show from mobile',
  visitMyWebsite: 'Visit my website',
  sendAnEmail: 'Send an Email',
  makeACall: 'Make a Call',
  myLocation: 'My Location',
  getYourLink: 'Get Your Link @ atiralink.com',
};

const PhotoWrapper = styled.div`
  svg {
    width: 100%;
    height: 100%;
  }
`;

const BackgroundDiv = styled.div`
  z-index: -1;
`;

type AtiraLinkPreviewPhoneEJSProps = {
  atiraLink: Partial<AtiraLink> & { linkName: string };
};

export const AtiraLinkPreviewPhoneEJSReadOnly: React.FC<
  AtiraLinkPreviewPhoneEJSProps
> = ({ atiraLink }) => {
  const shouldFlip = i18n.language === AppLangs.AR;
  const previewMode = TemplatePreviewMode.MOBILE;

  return (
    <div className="h-100 pe-none">
      <div
        className={`atira-link-body bg-white position-relative overflow-x-hidden overflow-y-auto h-100`}
      >
        <header className="py-3 shadow-sm sticky-top zindex-5 bg-white">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className={`d-flex`}>
                  <div className={`d-flex w-75 align-items-center gap-3`}>
                    {atiraLink?.image ? (
                      <img
                        src={
                          isString(atiraLink.image)
                            ? getMediaUrl(atiraLink.image! as string)
                            : URL.createObjectURL(atiraLink.image! as any)
                        }
                        className="header-photo object-fit-cover"
                        alt={atiraLink.title}
                      />
                    ) : (
                      <PhotoWrapper className="header-photo">
                        <AtiraIcon icon={faCircleUser} />
                      </PhotoWrapper>
                    )}
                    <span className="header-title text-truncate">
                      {atiraLink?.title}
                    </span>
                  </div>
                  <button
                    className={`navbar-toggler ${shouldFlip ? 'me-auto' : 'ms-auto'}`}
                  >
                    <i className="fa-solid fa-bars fs-5"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>

        <main className="atira-section py-5 d-flex align-items-center justify-content-center">
          <BackgroundDiv className="atira-link-bg position-absolute top-0 start-0 bottom-0 end-0"></BackgroundDiv>
          <div className="container">
            <div className="row justify-content-center">
              <div className={`col-12 d-flex flex-column gy-3`}>
                <AtiraLinkLeftSide
                  previewMode={previewMode}
                  atiraLink={atiraLink}
                  linkLanguageStrings={linkLanguageStrings}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
