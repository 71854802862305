import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Modal } from '../../../components/modal/Modal';
import { Lengths } from '../../../model/shared/enum/Lengths.enum';
import { SignupDto } from '../../../model/user/dto/SignupDto';
import { adminSliceSelectors } from '../../../redux/admin/admin.selector';
import { adminActions } from '../../../redux/admin/admin.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { Regexes } from '../../../utils/String';

const Container = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: ${Spacing.m};
`;

type AdminUserCreateModalProps = {
  open: boolean;
  onClose: VoidFunction;
};

export const AdminUserCreateModal: React.FC<AdminUserCreateModalProps> = ({
  open = false,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const { control, getValues, handleSubmit, reset } = useForm<SignupDto>();

  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const usersPage = useAppSelector(adminSliceSelectors.selectUsersTablePage);
  const usersPageSize = useAppSelector(
    adminSliceSelectors.selectUsersTablePageSize,
  );

  const onCreate = async () => {
    try {
      setLoading(true);

      await dispatch(adminActions.createUser(getValues())).unwrap();
      await dispatch(
        adminActions.searchUsers({
          userId,
          meta: {
            count: usersPageSize,
            page: usersPage - 1,
          },
        }),
      ).unwrap();

      reset({
        email: '',
        password: '',
        name: '',
      });
      onClose();

      AtiraToast.success(t('admin.createUser.success'));
    } catch (error) {
      console.error(error);
      AtiraToast.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={t('admin.createUser.modal.title')}
      open={open}
      onClose={onClose}
    >
      <Container>
        <Controller
          control={control}
          name="name"
          rules={{
            required: true,
            minLength: {
              value: Lengths.MIN_3,
              message: t('signup.error.user_name.short_message'),
            },
            maxLength: {
              value: 18,
              message: t('signup.error.user_name.long_message'),
            },
            pattern: {
              value: Regexes.NAME_REGEX,
              message: t('signup.error.user_name.invalid_characters'),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              title={t('common.name')}
              value={value}
              onChange={onChange}
              valid={!error}
              errorMessage={error?.message || t('signup.error.name')}
              required
            />
          )}
        />

        <Controller
          control={control}
          name="email"
          rules={{
            required: true,
            pattern: Regexes.EMAIL_REGEX,
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              title={t('common.email')}
              value={value}
              onChange={onChange}
              valid={!error}
              errorMessage={error?.message || t('signup.error.email')}
              required
            />
          )}
        />

        <Controller
          control={control}
          name="password"
          rules={{
            required: true,
            minLength: {
              value: 6,
              message: t('error.length.more', {
                name: t('common.password'),
                length: 6,
              }),
            },
            maxLength: {
              value: 64,
              message: t('error.length.less', {
                name: t('common.password'),
                length: 64,
              }),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              title={t('common.password')}
              value={value}
              onChange={onChange}
              valid={!error}
              required
              errorMessage={error?.message || t('signup.error.password')}
            />
          )}
        />

        <Button
          onClick={handleSubmit(onCreate)}
          loading={loading}
          hover
          hoverBackgroundColor="lightMain"
          height="2.5rem"
        >
          {t('common.create')}
        </Button>
      </Container>
    </Modal>
  );
};
