import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { ImageCreateEdit } from '../../../components/ImageCreateEdit';
import { Text } from '../../../components/Text';
import { TextWithTruncated } from '../../../components/TextWithTruncated';
import { UpgradeButton } from '../../../components/UpgradeButton';
import { Modal } from '../../../components/modal/Modal';
import { AtiraLink } from '../../../model/atira-link/AtiraLink';
import { AtiraLinkPlanType } from '../../../model/plan/types/AtiraLinkPlanType.enum';
import { atiraLinkSliceSelectors } from '../../../redux/atira-link/atira-link.selector';
import { AtiraLinkActions } from '../../../redux/atira-link/atira-link.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Shadow } from '../../../theme/Shadow';
import { Spacing } from '../../../theme/Spacing';

const LinksWrapper = styled(Flex)`
  gap: ${Spacing.s};
  flex-direction: column;
  width: 100%;
  margin-top: ${Spacing.l};

  @media (min-width: ${Breakpoints.TABLET}) {
    flex-direction: row;
  }
`;

const LinkContainer = styled(Flex)<{ active: boolean }>`
  width: 100%;
  border-radius: ${Rounded.md};
  height: 2.5rem;
  gap: ${Spacing.m};
  overflow: hidden;
  align-items: center;
  background-color: ${({ active, theme }) => (active ? theme.main : theme.sub)};
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  p {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    box-shadow: ${Shadow.SM};
    background-color: ${({ theme }) => theme.lightMain};
    transform: scale(1.02);

    p {
      color: ${({ theme }) => theme.sub};
    }
  }

  @media (min-width: ${Breakpoints.TABLET}) {
    width: 49%;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 2.5rem;
  flex-grow: 1;

  &:hover {
    box-shadow: ${Shadow.SM};
    background-color: ${({ theme }) => theme.lightMain};
    transform: scale(1.02);
  }

  @media (min-width: ${Breakpoints.TABLET}) {
    width: 49%;
  }
`;

const LinkImageWrapper = styled(Flex)`
  height: 100%;
  aspect-ratio: 1 / 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .ant-image {
    height: 100%;
  }

  img {
    object-fit: cover;
    height: 100% !important;
    display: block;
  }
`;

type SelectLinkToManageModalProps = {
  open: boolean;
  onClose: VoidFunction;
};

export const SelectLinkToManageModal: React.FC<
  SelectLinkToManageModalProps
> = ({ open = false, onClose }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userAtiraLinks = useAppSelector(
    atiraLinkSliceSelectors.selectUserAtiraLinks,
  );
  const userLinks = useAppSelector(
    atiraLinkSliceSelectors.selectUserAtiraLinks,
  );
  const selectedLink = useAppSelector(
    atiraLinkSliceSelectors.selectSelectedAtiraLink,
  );
  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser);

  const canCreateSecondLink =
    loggedInUser?.plan?.type === AtiraLinkPlanType.PLAN_2 &&
    userAtiraLinks.length < 2;
  const canViewSecondLink =
    loggedInUser?.plan?.type === AtiraLinkPlanType.PLAN_2;

  const onSelectLink = (link: AtiraLink) => {
    dispatch(AtiraLinkActions.setSelectedAtiraLink(link));
    dispatch(
      AtiraLinkActions.getUserAtiraLinks({ userId: loggedInUser?._id! }),
    );
  };

  const onCreateLink = () => {
    dispatch(AtiraLinkActions.resetSelectedAtiraLink());
    navigate('/my-link');
    onClose();
  };

  if (!canViewSecondLink) {
    return (
      <Modal title={t('selectLinkModal.title')} open={open} onClose={onClose}>
        <Flex
          flexDirection="column"
          gap="m"
          justifyContent="center"
          alignItems="center"
        >
          <Text align="center" style={{ whiteSpace: 'pre-line' }}>
            {t('selectLinkModal.disabled.title')}
          </Text>
          <UpgradeButton postClick={onClose} />
        </Flex>
      </Modal>
    );
  }

  return (
    <Modal title={t('selectLinkModal.title')} open={open} onClose={onClose}>
      <Text>{t('selectLinkModal.description')}</Text>

      <LinksWrapper>
        {userLinks.map((link) => (
          <LinkContainer
            key={link.linkName}
            active={link.linkName === selectedLink?.linkName}
            onClick={() => onSelectLink(link)}
          >
            <LinkImageWrapper>
              {link?.image ? (
                <ImageCreateEdit src={link.image} />
              ) : (
                <AtiraIcon
                  icon={faUser}
                  color={
                    selectedLink?.linkName === link.linkName
                      ? 'sub'
                      : 'textColor'
                  }
                />
              )}
            </LinkImageWrapper>

            <TextWithTruncated
              length={25}
              textProps={{
                color:
                  selectedLink?.linkName === link.linkName
                    ? 'sub'
                    : 'textColor',
              }}
            >
              {link.linkName}
            </TextWithTruncated>
          </LinkContainer>
        ))}

        {canCreateSecondLink ? (
          <StyledButton onClick={onCreateLink} icon={faPlus}>
            {t('selectLinkModal.new')}
          </StyledButton>
        ) : null}
      </LinksWrapper>
    </Modal>
  );
};
