import { Timeline, TimelineProps } from 'antd';
import groupBy from 'lodash/groupBy';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AtiraEmpty } from '../../components/AtiraEmpty';
import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import { UpgradeButton } from '../../components/UpgradeButton';
import { LemonSubscriptionHistoryStatus } from '../../model/plan/types/LemonSubscriptionHistoryStatus.enum';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { userActions } from '../../redux/user/user.slice';
import { standardDate } from '../../utils/Date';
import { getPlanTitle } from '../pricing/pricingUtils';

export const SubscriptionHistory: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const user = useAppSelector(userSliceSelectors.selectLoggedInUser);

  const history = user?.plan?.history;
  const historyItems = Object.entries(
    groupBy(history, (item) => item.subscriptionId),
  ).map(([key, value]) => {
    return value.map(({ data }) => ({
      key,
      color:
        data.status === LemonSubscriptionHistoryStatus.PAID ? 'green' : 'red',
      children: (
        <Flex flexDirection="column">
          <Text fontSize="l">
            {t(`subscription.plan.history.timeline.title-${data.status}`)}
          </Text>
          <Flex gap="s">
            <Text>
              {data.total_formatted}
              {' - '}
              {t(
                `subscription.plan.history.timeline.billingReason.title-${data.billing_reason}`,
              )}
            </Text>
            {data.status === LemonSubscriptionHistoryStatus.UNPAID ? (
              <Text color="danger">
                {'- '}
                {t('subscription.plan.history.timeline.unpaid')}
              </Text>
            ) : null}
          </Flex>
          <Text color="darkerSub">{standardDate(data.created_at!)}</Text>
        </Flex>
      ),
    })) as TimelineProps['items'];
  });

  useEffect(() => {
    dispatch(userActions.getLoggedInUser({ userId: user?._id! }));
  }, [dispatch, user?._id]);

  return (
    <Flex flexDirection="column" gap="m">
      <Flex>
        <Text fontSize="l">
          {t('subscription.plan.history.timeline.title')}
        </Text>
      </Flex>
      {historyItems?.length ? (
        historyItems.map((item) => (
          <Flex key={item?.[0].key} flexDirection="column" gap="s">
            <Flex gap="s">
              <Text>
                {t('subscription.plan.history.subscriptionId')}:{' '}
                {item?.[0].key?.toString()}
              </Text>
              <Text>
                {'- '}
                {t('subscription.plan.history.planType', {
                  plan: t(getPlanTitle(user?.plan?.type!)),
                })}
              </Text>
            </Flex>
            <Timeline items={item} />
          </Flex>
        ))
      ) : (
        <Flex
          flexDirection="column"
          alignSelf="center"
          justifyContent="center"
          alignItems="center"
          gap="m"
          width="22rem"
        >
          <AtiraEmpty
            description={t('subscription.plan.history.timeline.title-empty')}
          />
          <UpgradeButton />
        </Flex>
      )}
    </Flex>
  );
};
