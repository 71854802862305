import styled from 'styled-components';

import { Breakpoints } from '../theme/Breakpoints';
import { Spacing } from '../theme/Spacing';
import { Flex } from './Flex';

const StyledPageHeader = styled(Flex)`
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${Breakpoints.TABLET}) {
    flex-direction: column-reverse;
    gap: ${Spacing.s};
  }
`;

const LeftSideWrapper = styled(Flex)`
  width: 100%;
  gap: ${Spacing.m};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: fit-content;
  }
`;

type PageHeaderProps = {
  leftComponent: React.ReactNode;
  rightComponent: React.ReactNode;
  className?: string;
};

export const PageHeader: React.FC<PageHeaderProps> = ({
  leftComponent,
  rightComponent,
  className,
}) => {
  return (
    <StyledPageHeader className={className}>
      <LeftSideWrapper>{leftComponent}</LeftSideWrapper>
      <Flex justifyContent="end" className="header-right-component">
        {rightComponent}
      </Flex>
    </StyledPageHeader>
  );
};
