import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../../../assets';
import { AtiraColumn } from '../../../components/AtiraColumn';
import { AtiraRow } from '../../../components/AtiraRow';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { ThemeType } from '../../../theme/Theme';

const StyledLink = styled(Link)<{ disabledLink?: boolean }>`
  text-decoration: none;
  width: 12rem;
  display: flex;
  gap: ${Spacing.m};
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.black};
  font-size: 1rem;
  background-color: ${({ theme }) => theme.main};
  color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.md};
  padding: ${Spacing.s} ${Spacing.l};

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
    color: ${({ theme }) => theme.light};
  }

  ${({ disabledLink }) =>
    disabledLink
      ? `
    pointer-events: none;
    opacity: 0.5;
    color: ${({ theme }: { theme: ThemeType }) => theme.light} !important;
  `
      : ''}
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-block: ${Spacing.xl};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${Images.HelpSupportCallToActionsImage});
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
  }

  @media (min-width: ${Breakpoints.TABLET}) {
    padding-block: ${Spacing.xxxl};
  }
`;

export const HelpSupportCallToActionsSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <AtiraRow justify={'center'} align={'middle'}>
        <AtiraColumn xs={24} sm={24} md={22} lg={20} xl={20}>
          <AtiraRow justify={'center'} align={'middle'} gutter={[0, 30]}>
            <Flex flexDirection="column" gap="xl" width={'70%'}>
              <Flex flexDirection="column" gap="s" alignItems="center">
                <Text
                  fontSize="xxl"
                  fontWeight={'bold'}
                  align="center"
                  as={'h2'}
                >
                  {t('helpSupport.callToActionsSection.title')}
                </Text>
                <Text fontSize="l">
                  {t('helpSupport.callToActionsSection.description')}
                </Text>
              </Flex>
              <Flex flexDirection="column" gap="m" alignItems="center">
                <Text align="center">
                  {t('helpSupport.callToActionsSection.firstcallToActionTitle')}
                </Text>
                <StyledLink to={'/signup'}>
                  {t(
                    'helpSupport.callToActionsSection.firstcallToActionbutton',
                  )}
                </StyledLink>
              </Flex>

              <Flex flexDirection="column" gap="m" alignItems="center">
                <Text align="center">
                  {t(
                    'helpSupport.callToActionsSection.secondcallToActionTitle',
                  )}
                </Text>
                <Tooltip title={t('common.comingSoon')}>
                  <StyledLink disabledLink to="">
                    {t(
                      'helpSupport.callToActionsSection.secondcallToActionbutton',
                    )}
                  </StyledLink>
                </Tooltip>
              </Flex>
            </Flex>
          </AtiraRow>
        </AtiraColumn>
      </AtiraRow>
    </Container>
  );
};
