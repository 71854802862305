import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons/faXmarkCircle';
import React from 'react';
import styled from 'styled-components';

import { Button } from '../components/Button';
import { Flex } from '../components/Flex';
import i18n, { AppLangs } from '../i18n';
import { Breakpoints } from '../theme/Breakpoints';
import { Rounded } from '../theme/Rounded';
import { Shadow } from '../theme/Shadow';
import { Spacing } from '../theme/Spacing';

const Container = styled(Flex)<{
  visible: boolean;
  position: Props['position'];
}>`
  position: fixed;
  ${({ position }) => `${position}: ${Spacing.xxl}`};
  right: ${i18n.language === AppLangs.AR ? '50px' : '0'};
  left: ${i18n.language === AppLangs.AR ? '0' : '50px'};
  max-width: 80%;
  z-index: 1000;
  margin: auto;
  flex-direction: column;
  align-items: center;
  gap: ${Spacing.m};
  transition: all 0.5s ease;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    ${({ position }) => `${position}: ${Spacing.l}`};
    max-width: 65%;
  }
`;

const BannerJSX = styled(Flex)<{ visible: boolean }>`
  width: 100%;
  border-radius: ${Rounded.lg};
  background: linear-gradient(
    270deg,
    rgba(35, 40, 147, 1) 30%,
    rgba(151, 11, 170, 1) 76%
  );
  box-shadow: ${Shadow.LG};
`;

const ContentWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  align-items: center;
  margin: auto;
  background-color: ${({ theme }) => theme.light};
  border-radius: inherit;
  padding: ${Spacing.m};
  gap: ${Spacing.m};

  p {
    text-align: center;
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    flex-direction: row;
    justify-content: space-between;

    p {
      text-align: start;
    }
  }
`;

const CloseButton = styled(Button)`
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.light};
  color: ${({ theme }) => theme.main};
  border-radius: ${Rounded.circle};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  &:hover {
    transform: scale(1.1);
    color: ${({ theme }) => theme.lightMain};
  }
`;

type Props = {
  open: boolean;
  position?: 'top' | 'bottom';
  onClose?: () => void;
  children?: React.ReactNode;
};

export const Banner: React.FC<Props> = ({
  open,
  onClose,
  children,
  position = 'top',
}) => {
  return (
    <Container visible={open} position={position}>
      {onClose ? (
        <CloseButton onClick={onClose} icon={faXmarkCircle} iconWidth="2x" />
      ) : null}

      <BannerJSX visible={open}>
        <ContentWrapper>{children}</ContentWrapper>
      </BannerJSX>
    </Container>
  );
};
