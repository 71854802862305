/* eslint-disable no-nested-ternary */
import { Checkbox } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import VerificationInput from 'react-verification-input';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { GoogleButton } from '../../../components/logins/GoogleButton';
import { Modal } from '../../../components/modal/Modal';
import { AtiraLinkPlanDefinition } from '../../../model/plan/Plan-definition';
import { AtiraLinkPlanType } from '../../../model/plan/types/AtiraLinkPlanType.enum';
import { ForgotPasswordTokenDto } from '../../../model/user/dto/ForgotPasswordTokenDto';
import { LoginDto } from '../../../model/user/dto/LoginDto';
import { SignupDto } from '../../../model/user/dto/SignupDto';
import { LoginWithGoogleReturnPath } from '../../../model/user/types/LoginWithGoogleReturnPath.enum';
import { useAppDispatch } from '../../../redux/store';
import { userActions } from '../../../redux/user/user.slice';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { Regexes } from '../../../utils/String';

const StyledInput = styled(Input)`
  height: 2.5rem;
  border: 1px solid ${(props) => props.theme.lightergray};
  padding: ${Spacing.s};
  transition: border 0.2s;
  font-size: 1rem;
  border-radius: ${Rounded.lg};

  &:focus {
    border: ${({ theme }) => `1px solid ${theme.main} !important`};
  }
`;

const StyledButton = styled(Button)`
  height: 3rem;
  width: 100%;
  background-color: ${({ theme }) => theme.main};
  font-size: 1rem;
  transition: background-color 0.2s;
  margin: 0;
  border-radius: ${Rounded.lg};

  &:hover {
    background-color: ${(props) => props.theme.lightMain};
  }
`;

const StyledHr = styled.hr`
  background-color: ${(props) => props.theme.darkerSub};
  height: 1px;
  border: 0;
  opacity: 1;
  width: 100%;
`;

const ToggleButton = styled(Button)`
  text-decoration: none;
  font-size: 1rem;
  background-color: transparent;
  color: ${({ theme }) => theme.main};
  padding: 0;

  &:hover {
    color: ${({ theme }) => theme.lightMain};
  }
`;

const PrivacyTermsLink = styled(Link)`
  font-weight: bold;
  text-decoration: underline;
  color: ${({ theme }) => theme.black};
  font-size: 0.9rem;
`;

const InputsWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
`;

const RequiredStar = styled.span`
  color: ${({ theme }) => theme.danger};
`;
//

const InnerWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${Spacing.l};
`;

const PasswordButton = styled(Button)`
  background-color: ${(props) => props.theme.transparent};
  color: #4285f4;
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
  margin-inline-start: auto;
`;

//

const InputWrapper = styled(Flex)<{ valid: boolean }>`
  width: 100%;
  margin-top: ${Spacing.xl};

  .vi__container {
    width: 100% !important;
    direction: ltr;
  }

  .vi__character {
    border: ${({ theme, valid }) =>
      `2px solid ${valid ? theme.darkSub : theme.red}`} !important;
    background-color: ${({ theme }) => theme.transparent} !important;
    border-radius: ${Rounded.lg} !important;
  }

  .vi__character--filled {
    border-color: ${({ theme }) => theme.green} !important;
  }
`;

type Props = {
  signupFlow: {
    plan?: AtiraLinkPlanDefinition;
    modalVisible: boolean;
  };
  setSignupPaymentFlow: ATVoidFunction<Props['signupFlow']>;
};

enum Mode {
  SIGNUP,
  LOGIN,
  VERIFY,
}

export const SignupFlowModal: React.FC<Props> = ({
  signupFlow,
  setSignupPaymentFlow,
}) => {
  const [agreed, setAgreed] = useState(false);
  const [mode, setMode] = useState<Mode>(Mode.SIGNUP);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { control, handleSubmit, getValues, clearErrors } = useForm<
    SignupDto | LoginDto | ForgotPasswordTokenDto
  >({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      token: '',
    },
  });

  const onSwitchModes = (mode: Mode) => {
    setMode(mode);
    clearErrors();
  };

  const onVerifyToken = async () => {
    try {
      setLoading(true);
      const token = getValues('token');

      const { user } = await dispatch(
        userActions.verifyUserAccount({ token }),
      ).unwrap();

      localStorage.setItem(
        'processCheckout',
        JSON.stringify(
          (user?.plan?.freeTrial?.hasUsed &&
            user.plan?.freeTrial?.endsAt !== null) ||
            user.plan?.type === AtiraLinkPlanType.PLAN_0,
        ),
      );
      localStorage.setItem('plan', JSON.stringify(signupFlow.plan));
      navigate('/subscription');

      AtiraToast.success(t('welcome_message'));
    } catch (e: any) {
      AtiraToast.apiError(e, { autoClose: 6000 });
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onNext = async () => {
    try {
      setLoading(true);
      if (mode === Mode.SIGNUP) {
        await dispatch(userActions.signup(getValues() as SignupDto)).unwrap();
        AtiraToast.success(t('signup.verification_sent_message'));
        setMode(Mode.VERIFY);
      } else if (mode === Mode.LOGIN) {
        const { user } = await dispatch(
          userActions.login(getValues() as LoginDto),
        ).unwrap();

        localStorage.setItem(
          'processCheckout',
          JSON.stringify(
            (user?.plan?.freeTrial.hasUsed &&
              user.plan?.freeTrial?.endsAt !== null) ||
              user.plan?.type === AtiraLinkPlanType.PLAN_0,
          ),
        );
        localStorage.setItem('plan', JSON.stringify(signupFlow.plan));
        navigate('/subscription');
      }
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={t('plan.signupFlow.modal.title')}
      open={signupFlow.modalVisible}
      onClose={() =>
        setSignupPaymentFlow({ ...signupFlow, modalVisible: false })
      }
      width={700}
    >
      <Flex flexDirection="column" gap="m" width="100%" height="100%">
        {/* TODO Note that any change in those inputs below should be reflected in Login.tsx/Signup.tsx/SignupToken.tsx */}

        {mode === Mode.SIGNUP ? (
          <InputsWrapper>
            <Controller
              name="name"
              rules={{
                required: true,
                minLength: {
                  value: 3,
                  message: t('signup.error.user_name.short_message'),
                },
                maxLength: {
                  value: 18,
                  message: t('signup.error.user_name.long_message'),
                },
                pattern: {
                  value: Regexes.NAME_REGEX,
                  message: t('signup.error.user_name.invalid_characters'),
                },
              }}
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <StyledInput
                  title={t('common.name')}
                  type="name"
                  value={value}
                  placeholder={t('common.name')}
                  onChange={onChange}
                  id="name"
                  valid={isEmpty((errors as FieldErrors<SignupDto>).name)}
                  errorMessage={
                    (errors as FieldErrors<SignupDto>).name?.message ||
                    t('signup.error.name')
                  }
                  required
                />
              )}
            />

            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                pattern: Regexes.EMAIL_REGEX,
              }}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <StyledInput
                  title={t('common.email')}
                  type="email"
                  value={value}
                  placeholder={t('common.email')}
                  onChange={onChange}
                  id="email"
                  valid={isEmpty((errors as FieldErrors<SignupDto>).email)}
                  errorMessage={
                    (errors as FieldErrors<SignupDto>).email?.message ||
                    t('signup.error.email')
                  }
                  required
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <StyledInput
                  title={t('common.password')}
                  placeholder={t('common.password')}
                  type="password"
                  value={value}
                  onChange={onChange}
                  id="password"
                  valid={isEmpty((errors as FieldErrors<SignupDto>).password)}
                  required
                  errorMessage={
                    (errors as FieldErrors<SignupDto>).password?.message ||
                    t('signup.error.password')
                  }
                />
              )}
            />

            <Flex gap="s">
              <Checkbox
                value={agreed}
                onChange={() => setAgreed((prev) => !prev)}
              />

              <Text align="center">
                <Trans
                  i18nKey={'signup.terms_privacy.agree'}
                  components={[
                    <PrivacyTermsLink to={''} />,
                    <PrivacyTermsLink to={''} />,
                    <RequiredStar />,
                  ]}
                />
              </Text>
            </Flex>

            <Flex flexDirection="column" gap="m" width={'100%'} marginTop="m">
              <StyledButton
                disabled={!agreed}
                loading={loading}
                onClick={handleSubmit(onNext, (e) => {
                  console.log('invalid form submission', e);
                })}
                title={t('common.signup')}
              />

              <GoogleButton
                onAfterAuthURLGeneration={() => {
                  localStorage.setItem(
                    'processCheckoutId',
                    signupFlow.plan?.lemonVariantId!,
                  );
                }}
                returnPath={LoginWithGoogleReturnPath.PRICING}
              >
                {t('sign_up_with_google')}
              </GoogleButton>
              <StyledHr />

              <Flex gap="m" alignItems="center" justifyContent="center">
                <Text>{t('sign_in.has_account')}</Text>

                <ToggleButton onClick={() => onSwitchModes(Mode.LOGIN)}>
                  {t('common.login')}
                </ToggleButton>
              </Flex>
            </Flex>
          </InputsWrapper>
        ) : mode === Mode.LOGIN ? (
          <InnerWrapper>
            <Controller
              name="email"
              rules={{ required: true, pattern: Regexes.EMAIL_REGEX }}
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <StyledInput
                  title={t('common.email')}
                  type="email"
                  value={value}
                  onChange={onChange}
                  placeholder={t('common.email')}
                  id="email"
                  valid={isEmpty((errors as FieldErrors<LoginDto>).email)}
                  errorMessage={
                    (errors as FieldErrors<LoginDto>).email?.message ||
                    t('signup.error.email')
                  }
                  required
                />
              )}
            />

            <Flex flexDirection="column" width={'100%'} gap="s">
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, onChange },
                  formState: { errors },
                }) => (
                  <StyledInput
                    title={t('common.password')}
                    value={value}
                    type="password"
                    onChange={onChange}
                    placeholder={t('common.password')}
                    id="password"
                    valid={isEmpty((errors as FieldErrors<LoginDto>).password)}
                    errorMessage={
                      (errors as FieldErrors<LoginDto>).password?.message ||
                      t('signup.error.password')
                    }
                    required
                  />
                )}
              />

              <PasswordButton
                onClick={() => navigate('/forgot-password')}
                title={t('login.forgot_password')}
              />
            </Flex>

            <Flex flexDirection="column" gap="m" width={'100%'}>
              <StyledButton loading={loading} onClick={handleSubmit(onNext)}>
                {t('common.login')}
              </StyledButton>

              <GoogleButton
                onAfterAuthURLGeneration={() => {
                  localStorage.setItem(
                    'processCheckoutId',
                    signupFlow.plan?.lemonVariantId!,
                  );
                }}
                returnPath={LoginWithGoogleReturnPath.PRICING}
              >
                {t('sign_in_with_google')}
              </GoogleButton>

              <StyledHr />

              <Flex gap="m" alignItems="center" justifyContent="center">
                <Text>{t('login.do_not_have_account')}</Text>

                <ToggleButton onClick={() => onSwitchModes(Mode.SIGNUP)}>
                  {t('common.signup')}
                </ToggleButton>
              </Flex>
            </Flex>
          </InnerWrapper>
        ) : mode === Mode.VERIFY ? (
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width={'100%'}
            gap="s"
          >
            <Text color="textColor">{t('signup.verification.token')}</Text>
            <Controller
              name="token"
              control={control}
              rules={{ required: true, minLength: 6 }}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <InputWrapper
                  valid={!(errors as FieldErrors<ForgotPasswordTokenDto>).token}
                >
                  <VerificationInput
                    placeholder={''}
                    onChange={onChange}
                    value={value}
                  />
                </InputWrapper>
              )}
            />
            <StyledButton onClick={onVerifyToken} loading={loading}>
              {t('plan.signupFlow.modal.verify.button')}
            </StyledButton>
          </Flex>
        ) : null}

        <StyledHr />

        <Flex justifyContent="center">
          <Text fontSize="m" color="darkerSub" align="center">
            {[Mode.SIGNUP, Mode.VERIFY].includes(mode)
              ? t('plan.signupFlow.modal.footer.after_signup')
              : t('plan.signupFlow.modal.footer.after_login')}
            {t('plan.signupFlow.modal.footer')}
          </Text>
        </Flex>
      </Flex>
    </Modal>
  );
};
