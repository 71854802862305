import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraColumn } from '../../../components/AtiraColumn';
import { AtiraRow } from '../../../components/AtiraRow';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { SpinnerFullScreen } from '../../../components/SpinnerFullScreen';
import { Text } from '../../../components/Text';
import { adminSliceSelectors } from '../../../redux/admin/admin.selector';
import { adminActions } from '../../../redux/admin/admin.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Spacing } from '../../../theme/Spacing';
import { AdminThemeCard } from '../components/AdminThemeCard';
import { AdminThemeManageModal } from '../components/AdminThemeManageModal';

const Container = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};
  height: 100%;
`;

const CreateButton = styled(Button)`
  font-size: 1.5rem;
  width: fit-content;
`;

const StyledSpinner = styled(SpinnerFullScreen)`
  margin: auto;
`;

const StyledColumn = styled(AtiraColumn)`
  padding-left: 0 !important;
  padding-right: 0 !important;

  @media (min-width: ${Breakpoints.MOBILE}) {
    &:nth-child(odd) {
      padding-inline-end: ${Spacing.m} !important;
    }

    &:nth-child(even) {
      padding-inline-start: ${Spacing.m} !important;
    }
  }

  @media (min-width: ${Breakpoints.TABLET}) {
    &:nth-child(odd),
    &:nth-child(even) {
      padding-inline-start: 0 !important;
      padding-inline-end: 0 !important;
    }

    &:nth-child(3n - 1) {
      padding-inline: ${Spacing.m} !important;
    }
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    &:nth-child(3n - 1) {
      padding-inline: 0 !important;
    }

    &:not(:nth-child(4n)) {
      padding-inline-end: ${Spacing.m} !important;
    }
  }

  @media (min-width: ${Breakpoints.LARGE_DESKTOP}) {
    &:not(:nth-child(4n)) {
      padding-inline-end: 0 !important;
    }

    &:not(:nth-child(6n)) {
      padding-inline-end: ${Spacing.m} !important;
    }
  }
`;

export const AdminThemesTab: React.FC = () => {
  const [manageModalVisible, setManageModalVisible] = useState(false);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const themes = useAppSelector(adminSliceSelectors.selectAllThemes);
  const themesLoading = useAppSelector(adminSliceSelectors.selectThemesLoading);

  useEffect(() => {
    dispatch(adminActions.getAllThemes());
  }, [dispatch]);

  return (
    <Container>
      <Flex alignItems="center" justifyContent="space-between">
        <CreateButton onClick={() => setManageModalVisible(true)}>
          {t('common.manage')}
        </CreateButton>

        <Text fontWeight={'bold'}>
          {t('admin.themes.count', { count: themes.length })}
        </Text>
      </Flex>

      <AtiraRow gutter={[20, 20]}>
        {themesLoading && !themes?.length ? (
          <StyledSpinner />
        ) : (
          themes?.map((theme) => (
            <StyledColumn key={theme._id} xs={24} sm={12} md={8} lg={6} xl={4}>
              <AdminThemeCard theme={theme} />
            </StyledColumn>
          ))
        )}
      </AtiraRow>

      <AdminThemeManageModal
        open={manageModalVisible}
        onClose={() => setManageModalVisible(false)}
      />
    </Container>
  );
};
