import { AtiraRouteNotLoggedIn } from '../AtiraRouteNotLoggedIn';
import { PublicLayout } from '../PublicLayout';
import { ReportViolation } from '../ReportViolation/ReportViolation';
import { AboutPage } from '../about/AboutPage';
import { BlogPage } from '../blog/BlogPage';
import { ContactUs } from '../contact_us/ContactUs';
import { FaqsPage } from '../faqs/FaqsPage';
import { HelpSupport } from '../help-support/HelpSupport';
import { LandingPage } from '../landing-page/LandingPage';
import { Login } from '../loginSignup/Login';
import { ForgotPassword } from '../loginSignup/forgot-password/ForgotPassword';
import { Signup } from '../loginSignup/signup/Signup';
import { NewPage } from '../new/NewPage';
import { Pricing } from '../pricing/PricingRoute';
import { PrivacyPolicy } from '../privacy-policy/PrivacyPolicy';
import { Templates } from '../templates/Templates';
import { TermsCondetions } from '../terms_condetions/TermsCondetions';

export const PublicRouter = [
  {
    element: <PublicLayout />,
    errorElement: <div>error</div>,
    children: [
      {
        path: '/',
        element: <AtiraRouteNotLoggedIn component={<LandingPage />} />,
      },
      {
        path: '/login',
        element: <AtiraRouteNotLoggedIn component={<Login />} />,
      },
      {
        path: '/signup',
        element: <AtiraRouteNotLoggedIn component={<Signup />} />,
      },
      {
        path: '/forgot-password',
        element: <AtiraRouteNotLoggedIn component={<ForgotPassword />} />,
      },
      {
        path: '/pricing',
        element: <AtiraRouteNotLoggedIn component={<Pricing />} />,
      },
      {
        path: '/templates',
        element: <AtiraRouteNotLoggedIn component={<Templates />} />,
      },
      {
        path: '/privacy-policy',
        element: <AtiraRouteNotLoggedIn component={<PrivacyPolicy />} />,
      },
      {
        path: '/new',
        element: <AtiraRouteNotLoggedIn component={<NewPage />} />,
      },
      {
        path: '/about',
        element: <AtiraRouteNotLoggedIn component={<AboutPage />} />,
      },
      {
        path: '/blog',
        element: <AtiraRouteNotLoggedIn component={<BlogPage />} />,
      },
      {
        path: '/faqs',
        element: <AtiraRouteNotLoggedIn component={<FaqsPage />} />,
      },
      {
        path: '/terms-and-conditions',
        element: <AtiraRouteNotLoggedIn component={<TermsCondetions />} />,
      },
      {
        path: '/contact-us',
        element: <AtiraRouteNotLoggedIn component={<ContactUs />} />,
      },
      {
        path: '/help',
        element: <AtiraRouteNotLoggedIn component={<HelpSupport />} />,
      },
      {
        path: '/report-violation',
        element: <AtiraRouteNotLoggedIn component={<ReportViolation />} />,
      },
    ],
  },
];
