import isObject from 'lodash/isObject';

import { AtiraLink } from '../../model/atira-link/AtiraLink';
import { AtiraLinkCategory } from '../../model/atira-link/AtiraLinkCategory';
import { CreateCategoryDto } from '../../model/atira-link/dto/CreateCategoryDto';
import { EditAtiraLinkIndexDto } from '../../model/atira-link/dto/EditAtiraLinkIndexDto';
import { EditCategoryDto } from '../../model/atira-link/dto/EditCategoryDto';
import { AtiraLinkCategoryType } from '../../model/atira-link/types/AtiraLinkCategoryType.enum';
import { AtiraApi } from '../AxiosClient';

export class AtiraLinkIndexAxios {
  static getAllCategories(flat?: boolean) {
    return AtiraApi.get<AtiraLinkCategory[]>(`/index`, {
      params: flat ? { mode: 'flat' } : {},
    }).then(({ data }) => data);
  }

  static createCategory(dto: CreateCategoryDto) {
    const formData = new FormData();
    const { image, availableTo, ...rest } = dto;

    Object.entries(rest).forEach(([key, value]) => {
      formData.append(key, isObject(value) ? JSON.stringify(value) : value);
    });

    if (image) {
      formData.append('image', image);
    }

    switch (availableTo as unknown as AtiraLinkCategoryType) {
      case AtiraLinkCategoryType.PERSONAL:
        formData.append(
          'availableTo',
          JSON.stringify([AtiraLinkCategoryType.PERSONAL]),
        );
        break;

      case AtiraLinkCategoryType.CORPORATE:
        formData.append(
          'availableTo',
          JSON.stringify([AtiraLinkCategoryType.CORPORATE]),
        );
        break;

      case AtiraLinkCategoryType.BOTH:
      default: {
        formData.append(
          'availableTo',
          JSON.stringify([
            AtiraLinkCategoryType.PERSONAL,
            AtiraLinkCategoryType.CORPORATE,
          ]),
        );
      }
    }

    return AtiraApi.post<void>(`/index`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(({ data }) => data);
  }

  static editCategory(dto: EditCategoryDto) {
    const formData = new FormData();
    const { image, availableTo, ...rest } = dto;

    Object.entries(rest).forEach(([key, value]) => {
      formData.append(key, isObject(value) ? JSON.stringify(value) : value);
    });

    if (image) {
      formData.append('image', image);
    }

    switch (availableTo as unknown as AtiraLinkCategoryType) {
      case AtiraLinkCategoryType.PERSONAL:
        formData.append(
          'availableTo',
          JSON.stringify([AtiraLinkCategoryType.PERSONAL]),
        );
        break;

      case AtiraLinkCategoryType.CORPORATE:
        formData.append(
          'availableTo',
          JSON.stringify([AtiraLinkCategoryType.CORPORATE]),
        );
        break;

      case AtiraLinkCategoryType.BOTH: {
        formData.append(
          'availableTo',
          JSON.stringify([
            AtiraLinkCategoryType.PERSONAL,
            AtiraLinkCategoryType.CORPORATE,
          ]),
        );
        break;
      }
    }

    return AtiraApi.patch<void>(`/index/${dto._id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(({ data }) => data);
  }

  static upsertUserCategories(dto: EditAtiraLinkIndexDto) {
    const formData = new FormData();

    const { userId, ...rest } = dto;

    Object.entries(rest).forEach(([key, value]) => {
      formData.append(key, JSON.stringify(value));
    });

    formData.append('userId', userId);

    return AtiraApi.patch<void>(`/index/user/${dto.userId}/set`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(({ data }) => data);
  }

  static getLatestUsers() {
    return AtiraApi.get<AtiraLink[]>(`/index/public/latest`).then(
      ({ data }) => data,
    );
  }
}
