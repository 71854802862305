import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraTabs } from '../../components/AtiraTabs';
import { Flex } from '../../components/Flex';
import { UserHeader } from '../../components/UserHeader';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { PageMeta } from '../../model/meta/PageMeta';
import { QRActions } from '../../redux/QR/qr.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';
import { QRGenerator } from './QRGenerator';
import { UserQRs } from './UserQRs';

const HeaderWrapper = styled(Flex)`
  display: none;
  margin-top: ${Spacing.s};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: flex;
  }
`;

enum TabsKeys {
  USER_QRS = 'USER_QRS',
  CREATE_QR = 'CREATE_QR',
}

export const QRRoute: React.FC = () => {
  const { t } = useTranslation();
  useDocumentTitle(`${t('app.name').toUpperCase()} - ${t('common.my_qrs')}`);

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      QRActions.getUserQRs({ userId: loggedInUserId, meta: PageMeta.create() }),
    );
  }, [dispatch, loggedInUserId]);

  return (
    <AtiraTabs
      items={[
        {
          key: TabsKeys.USER_QRS,
          label: t('side_menu.my_qrs'),
          children: <UserQRs />,
        },
        {
          key: TabsKeys.CREATE_QR,
          label: t('common.create'),
          children: <QRGenerator />,
        },
      ]}
      sideComponent={
        <HeaderWrapper>
          <UserHeader />
        </HeaderWrapper>
      }
    />
  );
};
