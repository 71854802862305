import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../components/AtiraIcon';
import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';
import { Text } from '../../components/Text';
import { AtiraLinkPlan } from '../../model/plan/Plan';
import { paymentActions } from '../../redux/payment/payment.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Breakpoints } from '../../theme/Breakpoints';
import { Rounded } from '../../theme/Rounded';
import { Shadow } from '../../theme/Shadow';
import { Spacing } from '../../theme/Spacing';
import { AtiraToast } from '../../utils/AtiraToast';
import { standardDate } from '../../utils/Date';
import { getPlanTitle, toUSD, translatePerks } from '../pricing/pricingUtils';

const Card = styled(Flex)`
  border-radius: ${Rounded.pill};
  flex-direction: column;
  align-items: center;
  gap: ${Spacing.m};
  background-color: ${({ theme }) => theme.light};
  padding: ${Spacing.m};
  padding-bottom: ${Spacing.l};
  height: 68rem;
  max-width: 20rem;
  box-shadow: 0px 4px 4px 0px #00000040;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
`;

const CardPlanInfoInnerWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: ${({ theme }) => theme.light};
`;

const CardTitle = styled(Text)`
  font-weight: 700;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.main};
  text-align: center;
`;

const FeaturesWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.l};
  width: 100%;
  margin-bottom: ${Spacing.l};
`;

const CardPlanInfoWrapper = styled(Flex)<{
  showFreeTrial?: boolean;
  content?: string;
}>`
  padding: ${Spacing.l};
  aspect-ratio: 1 / 1;
  border-radius: ${Rounded.circle};
  width: 100%;
  background: radial-gradient(50% 50% at 50% 50%, #960baa 76.5%, #2b2694 100%);

  ${({ showFreeTrial, content, theme }) =>
    showFreeTrial
      ? `
  &::before {
    content: "${content}";
    text-align: center;
    color: white;
    position: absolute;
    top: 150px;
    font-size: 1rem;
    right: -20px;
    width: 15rem;
    height: 1.4rem;
    background-color: ${theme.main};
    transform-origin: top right;
    transform: rotate(45deg);
  }
  `
      : ``}
`;

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.m};
  flex: 1;

  @media (min-width: ${Breakpoints.TABLET}) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const InfoWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.md};
  box-shadow: ${Shadow.MD};
  padding: ${Spacing.m};
  flex-grow: 1;
  flex-direction: column;
`;

const SpanBold = styled.span`
  font-weight: bold;
`;

export const Subscription: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const user = useAppSelector(userSliceSelectors.selectLoggedInUser)!;
  const plan = user?.plan || ({} as AtiraLinkPlan);
  const isOnFreeTrial =
    plan.freeTrial?.hasUsed && (plan.freeTrial?.endsAt?.length || 0) > 0;

  const onUpdatePaymentMethod = async () => {
    try {
      setLoading(true);
      const url = await dispatch(
        paymentActions.getUpdatePaymentMethodURL(),
      ).unwrap();
      window.createLemonSqueezy();
      window.LemonSqueezy.Url.Open(url);
    } catch (e: any) {
      console.error('Failed to update payment method:', e);
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
    }
  };

  if (isEmpty(plan)) {
    return <SpinnerFullScreen />;
  }

  return (
    <Container>
      <Flex flexDirection="column" gap="m" flexGrow={1}>
        <Flex flexDirection="column">
          <Text fontWeight={'bold'} fontSize="xl">
            {t('subscription.plan.title')}
          </Text>
          <Text>{t('subscription.plan.description')}</Text>
        </Flex>

        <InfoWrapper>
          <Text>
            <Trans
              i18nKey={'subscription.status'}
              values={{
                status: t(
                  `subscription.status.${isOnFreeTrial ? 'freeTrial' : plan.lemonSubscriptionData?.subscription.status || 'not_subscribed'}`,
                ),
              }}
              components={[<SpanBold />]}
            />
          </Text>

          {plan.lemonSubscriptionData?.subscription || isOnFreeTrial ? (
            <Text>
              <Trans
                i18nKey={`subscription.status.${isOnFreeTrial ? 'freeTrial' : plan.lemonSubscriptionData?.subscription.status}.description`}
                values={{
                  amount: toUSD(
                    Number(plan.lemonSubscriptionData?.variant?.price!),
                  ),
                  date: standardDate(plan.lemonPlanData?.renewsAt!),
                  last4:
                    plan.lemonSubscriptionData?.subscription.card_last_four,
                }}
                components={[<SpanBold />]}
              />
            </Text>
          ) : null}
        </InfoWrapper>

        <Flex flexDirection="column" marginTop="m">
          <Text fontWeight={'bold'} fontSize="l">
            {t('subscription.page.payment.title')}
          </Text>

          <Text fontSize="m">{t('subscription.page.payment.description')}</Text>
        </Flex>

        <InfoWrapper>
          <Button
            loading={loading}
            width="15rem"
            height="2.5rem"
            title={t('subscription.page.payment.button')}
            onClick={onUpdatePaymentMethod}
          />
        </InfoWrapper>
      </Flex>

      <Card>
        <CardPlanInfoWrapper
          content={t('subscription.plan.noticeModal.freeTrial.cardRibbon')}
          showFreeTrial={isOnFreeTrial}
        >
          <CardPlanInfoInnerWrapper>
            <Text fontSize="l">
              {toUSD(Number(plan.lemonSubscriptionData?.variant?.price!))}
            </Text>
            <Text fontSize="l">{t('common.per_year')}</Text>
          </CardPlanInfoInnerWrapper>
        </CardPlanInfoWrapper>
        <FeaturesWrapper>
          <CardTitle>{t(getPlanTitle(plan.type))}</CardTitle>
          {isOnFreeTrial ? (
            <Text align="center" color="main">
              {t(
                'subscription.plan.noticeModal.freeTrial.cardRibbon.description',
                { date: standardDate(user?.plan?.freeTrial.endsAt!) },
              )}
            </Text>
          ) : null}

          {Object.entries(plan.perks || {})?.map(([key, value]) => (
            <Flex gap="s" key={key}>
              <Text fontSize="xm" color="textColor">
                {/* @ts-ignore */}
                {t(translatePerks(key, value))}
              </Text>
              {key === 'index' ? (
                <Tooltip
                  title={
                    <span>
                      {t('plan.index.info')}
                      <a
                        href="https://index.atiralink.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://index.atiralink.com
                      </a>
                    </span>
                  }
                >
                  <span></span>
                  <AtiraIcon icon={faInfoCircle} color="main" />
                </Tooltip>
              ) : null}
            </Flex>
          ))}
        </FeaturesWrapper>
      </Card>
    </Container>
  );
};
