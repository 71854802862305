import { ThemeType } from '../theme/Theme';
import { Flex } from './Flex';
import { Spinner } from './Spinner';

type SpinnerFullScreenProps = {
  color?: keyof ThemeType;
};

export const SpinnerFullScreen: React.FC<SpinnerFullScreenProps> = ({
  color,
}) => {
  return (
    <Flex justifyContent="center" alignItems="center" flex={1}>
      <Flex width={'3rem'} height={'3rem'}>
        <Spinner color={color} />
      </Flex>
    </Flex>
  );
};
