import { faDesktop } from '@fortawesome/free-solid-svg-icons/faDesktop';
import { faMobileScreenButton } from '@fortawesome/free-solid-svg-icons/faMobileScreenButton';
import { faTabletScreenButton } from '@fortawesome/free-solid-svg-icons/faTabletScreenButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { createGlobalStyle } from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Modal } from '../../../components/modal/Modal';
import { AtiraLink } from '../../../model/atira-link/AtiraLink';
import { AtiraLinkSocialHandlers } from '../../../model/atira-link/types/AtiraLinkSocialMediaHandlers.enum';
import { AtiraLinkThemes } from '../../../model/atira-link/types/AtiraLinkThemes.enum';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraLinkPreviewPhone } from '../../my-link/components/AtiraLinkPreviewPhone';
import { TemplatePreviewMode } from '../model/TemplatePreviewMode.enum';

const previewPlaceholderData = {
  linkName: 'preview',
  customLinks: [{ label: 'Custom Link', value: 'https://atiralink.com' }],
  title: 'Theme Preview',
  bio: 'You can add a bio here',
  email: 'preview@atiralink',
  phone: '1234567890',
  socials: [
    {
      parentId: '',
      handle: AtiraLinkSocialHandlers.WHATSAPP,
      url: 'https://wa.me/',
      label: AtiraLinkSocialHandlers.WHATSAPP,
    },
    {
      parentId: '',
      handle: AtiraLinkSocialHandlers.INSTAGRAM,
      url: 'https://www.instagram.com/',
      label: AtiraLinkSocialHandlers.INSTAGRAM,
    },
    {
      parentId: '',
      handle: AtiraLinkSocialHandlers.TWITTER,
      url: 'https://www.twitter.com/',
      label: AtiraLinkSocialHandlers.TWITTER,
    },
    {
      parentId: '',
      handle: AtiraLinkSocialHandlers.YOUTUBE,
      url: 'https://www.youtube.com/',
      label: AtiraLinkSocialHandlers.YOUTUBE,
    },
  ],
} as Partial<AtiraLink> & { linkName: string };

const Wrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${Spacing.s};
`;

const StyledModal = styled(Modal)`
  .ant-modal-content,
  .ant-modal-title,
  .ant-modal-header,
  .ant-modal-title > div {
    background-color: transparent !important;
  }

  .ant-modal-body {
    width: fit-content;
  }

  .ant-modal-header {
    position: fixed;
    z-index: 100000;
  }

  .ant-modal-header p,
  .ant-modal-hr {
    display: none;
  }

  .ant-modal-header button {
    margin-inline-start: auto;
    padding: ${Spacing.s};
    border-radius: ${Rounded.md};
    background-color: ${({ theme }) => theme.sub};
    color: ${({ theme }) => theme.main};
    padding: 0.75rem;
    border: ${({ theme }) => `1px solid ${theme.sub}`};

    &:hover {
      background-color: ${({ theme }) => theme.main};
      color: ${({ theme }) => theme.light};
    }
  }

  .ant-modal-header button:hover {
    opacity: 0.7;
  }

  .ant-modal-content {
    padding: ${Spacing.s};
    box-shadow: none;
  }
`;

const StyledButton = styled(Button)<{ isSelected: boolean }>`
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.sub : theme.main};
  color: ${({ theme, isSelected }) => (isSelected ? theme.main : theme.sub)};
  padding: 0.75rem;
  border: ${({ theme }) => `1px solid ${theme.sub}`};

  &:hover {
    background-color: ${({ theme }) => theme.sub};
    color: ${({ theme }) => theme.main};
  }
`;

const PreviewModeButtonsWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  gap: ${Spacing.m};
  display: none;

  button:nth-child(2),
  button:nth-child(3) {
    display: none;
  }

  @media (min-width: ${Breakpoints.TABLET}) {
    display: flex;
    button:nth-child(2) {
      display: flex;
    }
  }

  @media (min-width: ${Breakpoints.LARGE_DESKTOP}) {
    button:nth-child(3) {
      display: flex;
    }
  }
`;

const ThemedOverlay = createGlobalStyle`
  .ant-modal-mask {
    background-color: #652d90d1 !important;
    backdrop-filter: blur(20px);
  }
`;

type TemplatePreviewModalProps = {
  selectedTheme?: AtiraLinkThemes | null;
  open: boolean;
  onClose: VoidFunction;
};

export const TemplatePreviewModal: React.FC<TemplatePreviewModalProps> = ({
  selectedTheme,
  onClose,
  open = false,
}) => {
  const [selectedPreviewMode, setSelectedPreviewMode] =
    useState<TemplatePreviewMode>(TemplatePreviewMode.MOBILE);

  const { t } = useTranslation();

  return (
    <StyledModal
      style={{ top: 0 }}
      title={t('common.preview')}
      open={open}
      onClose={onClose}
      width={'fit-content'}
    >
      <Wrapper>
        <PreviewModeButtonsWrapper>
          <StyledButton
            isSelected={selectedPreviewMode === TemplatePreviewMode.MOBILE}
            onClick={() => setSelectedPreviewMode(TemplatePreviewMode.MOBILE)}
            icon={faMobileScreenButton}
            iconWidth="lg"
          />

          <StyledButton
            isSelected={selectedPreviewMode === TemplatePreviewMode.TABLET}
            onClick={() => setSelectedPreviewMode(TemplatePreviewMode.TABLET)}
            icon={faTabletScreenButton}
            iconWidth="lg"
          />

          <StyledButton
            isSelected={selectedPreviewMode === TemplatePreviewMode.DESKTOP}
            onClick={() => setSelectedPreviewMode(TemplatePreviewMode.DESKTOP)}
            icon={faDesktop}
            iconWidth="lg"
          />
        </PreviewModeButtonsWrapper>

        <AtiraLinkPreviewPhone
          key={selectedTheme}
          isPreviewMode
          previewMode={selectedPreviewMode}
          values={{
            ...previewPlaceholderData,
            theme: selectedTheme || AtiraLinkThemes.BASIC,
          }}
        />
      </Wrapper>

      <ThemedOverlay />
    </StyledModal>
  );
};
