import { faCrown } from '@fortawesome/free-solid-svg-icons/faCrown';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faQrcode } from '@fortawesome/free-solid-svg-icons/faQrcode';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faUpRightFromSquare';
import { Tooltip } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraColumn } from '../../../components/AtiraColumn';
import { AtiraIcon } from '../../../components/AtiraIcon';
import { AtiraRow } from '../../../components/AtiraRow';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { usePlansContext } from '../../../plans-context';
import { atiraLinkSliceSelectors } from '../../../redux/atira-link/atira-link.selector';
import { AtiraLinkActions } from '../../../redux/atira-link/atira-link.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';

const Container = styled(Flex)`
  gap: ${Spacing.s};
  flex-direction: column;

  @media (min-width: ${Breakpoints.MOBILE}) {
    flex-direction: row;
  }
`;

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.m};
  flex: 1;
`;

const SectionTitle = styled(Text)`
  color: ${({ theme }) => theme.textColor};
  font-weight: bold;
  font-size: 1.3rem;
`;

const StyledNumber = styled(Text)`
  color: ${({ theme }) => theme.textColor};
`;

const Responsive = styled(Flex)`
  width: 100%;
  height: fit-content;

  @media (max-width: ${Breakpoints.TABLET}) {
    gap: ${Spacing.m};
    flex-direction: column;
  }
`;

const TextsWrapper = styled(Flex)`
  gap: ${Spacing.s};

  flex-wrap: wrap;
`;

const ActionsWrapper = styled(Flex)`
  gap: ${Spacing.s};
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${Breakpoints.TABLET}) {
    width: 30%;
  }
`;

const StyledButton = styled(Button)`
  padding: 0.6rem ${Spacing.m};
  font-size: 1.1rem;
  flex-grow: 1;

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
  }
`;

export const DashboardActivityStats: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { setPlansModalVisible } = usePlansContext();

  const stats = useAppSelector(
    atiraLinkSliceSelectors.selectSelectedAtiraLinkStats,
  );
  const user = useAppSelector(userSliceSelectors.selectLoggedInUser)!;
  const userLinks = useAppSelector(
    atiraLinkSliceSelectors.selectUserAtiraLinks,
  );

  const canCreateLink =
    Number(user.plan?.perks?.linkCount || 0) <= userLinks.length;

  const visits = (stats?.MOBILE.view || 0) + (stats?.DESKTOP.view || 0);
  const uniqueVisits =
    (stats?.MOBILE.uniqueView || 0) + (stats?.DESKTOP.uniqueView || 0);
  const clicks = (stats?.MOBILE.click || 0) + (stats?.DESKTOP.click || 0);
  const uniqueClicks =
    (stats?.MOBILE.uniqueClick || 0) + (stats?.DESKTOP.uniqueClick || 0);
  const ctr = ((clicks / visits) * 100 || 0).toFixed(1);

  const onCreateLink = () => {
    dispatch(AtiraLinkActions.resetSelectedAtiraLink());
    navigate('/my-link');
  };

  const onCreateQR = () => {
    navigate('/my-qrs', {
      state: {
        targetedTab: 'CREATE_QR',
      },
    });
  };

  const onShortenURL = () => {
    navigate('/my-urls', {
      state: {
        targetedTab: 'SHORTEN_URL',
      },
    });
  };

  useEffect(() => {
    dispatch(AtiraLinkActions.getUserAtiraLinks({ userId: user._id }));
  }, [dispatch]);

  return (
    <Container>
      <ActionsWrapper>
        <StyledButton
          onClick={
            canCreateLink ? onCreateLink : () => setPlansModalVisible(true)
          }
          icon={canCreateLink ? faUpRightFromSquare : faCrown}
        >
          {t('dashboard.action.link')}
        </StyledButton>

        <StyledButton onClick={onCreateQR} icon={faQrcode}>
          {t('dashboard.action.qr')}
        </StyledButton>

        <StyledButton onClick={onShortenURL} icon={faLink}>
          {t('dashboard.action.url')}
        </StyledButton>
      </ActionsWrapper>

      <Section>
        <Flex gap="s">
          <SectionTitle>
            {t('dashboard.stats.activity.title', {
              date: `${user.plan?.perks?.stats} ${t('common.days')}`,
            })}
          </SectionTitle>
          <Tooltip
            title={t('dashboard.stats.activity.title.tooltip', {
              date: `${user.plan?.perks?.stats} ${t('common.days')}`,
            })}
          >
            <AtiraIcon size="xs" icon={faInfoCircle} />
            <div />
          </Tooltip>
        </Flex>

        <AtiraRow justify={'space-between'}>
          <Responsive>
            <AtiraColumn>
              <TextsWrapper>
                <Text fontWeight={'bolder'}>{visits}</Text>
                <StyledNumber>{t('common.views')}</StyledNumber>
                <Text>/</Text>
                <Text fontWeight={'bolder'}>{uniqueVisits}</Text>
                <StyledNumber>{t('common.unique_views')}</StyledNumber>
              </TextsWrapper>
            </AtiraColumn>

            <AtiraColumn>
              <TextsWrapper>
                <Text fontWeight={'bolder'}>{clicks}</Text>
                <StyledNumber>{t('common.clicks')}</StyledNumber>
                <Text>/</Text>
                <Text fontWeight={'bolder'}>{uniqueClicks}</Text>
                <StyledNumber>{t('common.unique_clicks')}</StyledNumber>
              </TextsWrapper>
            </AtiraColumn>

            <AtiraColumn xl={4}>
              <Flex gap="s">
                <Text fontWeight={'bolder'}>{ctr}%</Text>
                <StyledNumber fontWeight={'bolder'}>
                  {t('common.ctr')}
                </StyledNumber>
              </Flex>
            </AtiraColumn>
          </Responsive>
        </AtiraRow>
      </Section>
    </Container>
  );
};
