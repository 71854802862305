import { notification } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraTabs } from '../../components/AtiraTabs';
import { Flex } from '../../components/Flex';
import { UserHeader } from '../../components/UserHeader';
import { AtiraLinkPlanType } from '../../model/plan/types/AtiraLinkPlanType.enum';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { userActions } from '../../redux/user/user.slice';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';
import { AtiraToast } from '../../utils/AtiraToast';
import { Subscription } from './Subscription';
import { SubscriptionHistory } from './SubscriptionHistory';
import { SubscriptionPlans } from './SubscriptionPlans';

const HeaderWrapper = styled(Flex)`
  display: none;
  margin-top: ${Spacing.s};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: flex;
  }
`;

export const SubscriptionRoute: React.FC = () => {
  const [searchParams, _] = useSearchParams();
  const timer = useRef<NodeJS.Timer | null>(null);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  useEffect(() => {
    const canWork =
      searchParams.get('success') === 'true' &&
      searchParams.get('userId') === userId &&
      !timer.current;

    if (canWork) {
      notification.destroy();
      AtiraToast.success(t('subscription.polling.began'), { duration: 15 });

      let timeoutId: NodeJS.Timeout;

      const poll = async () => {
        try {
          const user = await dispatch(
            userActions.getLoggedInUser({ userId }),
          ).unwrap();

          if (user.plan?.type !== AtiraLinkPlanType.PLAN_0) {
            clearInterval(timer.current as NodeJS.Timeout);
            clearTimeout(timeoutId);
          }
        } catch (e: any) {
          console.error('Failed to fetch the logged-in user:', e);
          clearInterval(timer.current as NodeJS.Timeout);
          clearTimeout(timeoutId);
        }
      };

      timer.current = setInterval(poll, 2000);

      timeoutId = setTimeout(() => {
        clearInterval(timer.current as NodeJS.Timeout);
      }, 120000); // 2 minutes

      const params = new URLSearchParams(window.location.search);
      params.delete('success');
      params.delete('userId');
      window.history.replaceState({}, '', window.location.pathname);

      return () => {
        clearInterval(timer.current as NodeJS.Timeout);
        clearTimeout(timeoutId);
        timer.current = null;
      };
    }
  }, [dispatch, searchParams, t, userId]);

  useEffect(() => {
    dispatch(userActions.getLoggedInUser({ userId }));
  }, [dispatch, userId]);

  return (
    <Flex>
      <AtiraTabs
        items={[
          {
            key: 'plans',
            label: t('common.plans'),
            children: <SubscriptionPlans />,
          },
          {
            key: 'current-plan',
            label: t('subscription.plan.current'),
            children: <Subscription />,
          },
          {
            key: 'payment-history',
            label: t('subscription.plan.history'),
            children: <SubscriptionHistory />,
          },
        ]}
        sideComponent={
          <HeaderWrapper>
            <UserHeader />
          </HeaderWrapper>
        }
      />
    </Flex>
  );
};
