import { useEffect, useState } from 'react';

export const useLemon = () => {
  const [lemonLoaded, setLemonLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.lemonsqueezy.com/js/lemon.js';
    script.defer = true;
    script.onload = () => {
      setLemonLoaded(true);
      window.createLemonSqueezy();
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return lemonLoaded;
};
