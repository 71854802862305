import { createSlice } from '@reduxjs/toolkit';

import { AtiraLinkIndexAxios } from '../../axios/atira-link/atira-link-index.axios';
import { AtiraLink } from '../../model/atira-link/AtiraLink';
import { AtiraLinkCategory } from '../../model/atira-link/AtiraLinkCategory';
import { CreateCategoryDto } from '../../model/atira-link/dto/CreateCategoryDto';
import { EditAtiraLinkIndexDto } from '../../model/atira-link/dto/EditAtiraLinkIndexDto';
import { EditCategoryDto } from '../../model/atira-link/dto/EditCategoryDto';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from '../user/user.slice';

interface AtiraLinkIndexReducer {
  categories: AtiraLinkCategory[];
  allCategories: AtiraLinkCategory[];
  latestUsers: AtiraLink[];
  latestUsersLoading: boolean;
}

const initialState = Object.freeze<AtiraLinkIndexReducer>({
  categories: [],
  allCategories: [],
  latestUsers: [],
  latestUsersLoading: false,
});

const getAllCategories = AtiraThunk<AtiraLinkCategory[], void>(
  `/atira-link-index`,
  () => AtiraLinkIndexAxios.getAllCategories(),
);

const getAllCategoriesFlat = AtiraThunk<AtiraLinkCategory[], void>(
  `/atira-link-index/flat`,
  () => AtiraLinkIndexAxios.getAllCategories(true),
);

const createCatagory = AtiraThunk<void, CreateCategoryDto>(
  '/atira-link-index/create',
  AtiraLinkIndexAxios.createCategory,
);

const upsertUserCategories = AtiraThunk<void, EditAtiraLinkIndexDto>(
  '/index/user/:id/set',
  AtiraLinkIndexAxios.upsertUserCategories,
);

const editCategory = AtiraThunk<void, EditCategoryDto>(
  '/atira-link-index/edit',
  AtiraLinkIndexAxios.editCategory,
);

const getLatestUsers = AtiraThunk<AtiraLink[], void>(
  '/index/latest',
  AtiraLinkIndexAxios.getLatestUsers,
);

const AtiraLinkSlice = createSlice({
  name: 'atiraLinkIndexSlice',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getAllCategories.fulfilled, (state, action) => {
      state.categories = action.payload || [];
    });

    addCase(getAllCategoriesFlat.fulfilled, (state, action) => {
      state.allCategories = action.payload || [];
    });

    addCase(getLatestUsers.pending, (state) => {
      state.latestUsersLoading = true;
    });
    addCase(getLatestUsers.fulfilled, (state, action) => {
      state.latestUsers = action.payload || [];
      state.latestUsersLoading = false;
    });
    addCase(getLatestUsers.rejected, (state) => {
      state.latestUsersLoading = false;
    });
    //@ts-ignore
    addCase(userActions.logout.fulfilled, (state: AtiraLinkReducer) => {
      return (state = initialState);
    });
  },
});

export const AtiraLinkIndexActions = {
  getAllCategories,
  getAllCategoriesFlat,
  createCatagory,
  upsertUserCategories,
  editCategory,
  getLatestUsers,
};

export default AtiraLinkSlice.reducer;
