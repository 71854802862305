import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const atiraIndexState = (state: RootState) => state.atiraLinkIndex;

const selectAllCategories = createSelector(
  atiraIndexState,
  (state) => state.categories || [],
);

const selectAllCategoriesFlat = createSelector(
  atiraIndexState,
  (state) => state.allCategories || [],
);

const selectLatestUsers = createSelector(
  atiraIndexState,
  (state) => state.latestUsers,
);

const selectLatestUsersLoading = createSelector(
  atiraIndexState,
  (state) => state.latestUsersLoading,
);

export const atiraLinkIndexSliceSelectors = {
  selectAllCategories,
  selectAllCategoriesFlat,
  selectLatestUsers,
  selectLatestUsersLoading,
};
