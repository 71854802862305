import { faExternalLink } from '@fortawesome/free-solid-svg-icons/faExternalLink';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { Cascader, Switch, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { createGlobalStyle } from 'styled-components';

import { useTheme } from '../../../ThemeContext';
import { AtiraIcon } from '../../../components/AtiraIcon';
import { AtiraSelect } from '../../../components/AtiraSelect';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { AtiraLinkCategory } from '../../../model/atira-link/AtiraLinkCategory';
import { EditAtiraLinkIndexDto } from '../../../model/atira-link/dto/EditAtiraLinkIndexDto';
import { AtiraLinkCategoryAvailableLangs } from '../../../model/atira-link/types/AtiraLinkCategoryAvailableLangs.enum';
import { AtiraLinkCategoryType } from '../../../model/atira-link/types/AtiraLinkCategoryType.enum';
import { atiraLinkIndexSliceSelectors } from '../../../redux/atira-link/atira-link-index.selector';
import { AtiraLinkIndexActions } from '../../../redux/atira-link/atira-link-index.slice';
import { atiraLinkSliceSelectors } from '../../../redux/atira-link/atira-link.selector';
import { AtiraLinkActions } from '../../../redux/atira-link/atira-link.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';

const ContentWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};
  width: 100%;
`;

const SectionTitle = styled(Text)`
  color: ${({ theme }) => theme.textColor};
  font-weight: bold;
  font-size: 1.3rem;
  height: 2rem;
`;

const SectionWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  width: 100%;
`;

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.s};
`;

const StyledAnchor = styled.a`
  height: 2.5rem;
  text-align: center;
  background-color: ${({ theme }) => theme.main};
  border-radius: ${Rounded.lg};
  color: ${({ theme }) => theme.light};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${Spacing.s};
  font-size: 1rem;

  &:hover {
    color: ${({ theme }) => theme.light};
  }
`;

const ActionsWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  gap: ${Spacing.m};
  flex-wrap: wrap;
  margin-top: ${Spacing.l};

  button,
  a {
    width: 100%;
  }

  @media (min-width: ${Breakpoints.MOBILE}) {
    flex-wrap: nowrap;

    button,
    a {
      width: 12rem;
    }
  }
`;

const Description = styled(Text)`
  color: ${({ theme }) => theme.subTextColor};
  max-width: 45rem;
`;

const StyledCascader = styled(Cascader)`
  .ant-select-selector:hover {
    border: 1px solid ${({ theme }) => theme.subTextColor} !important;
  }

  .ant-select-selector {
    border: 1px solid ${({ theme }) => theme.textColor} !important;
    background-color: ${({ theme }) => theme.light} !important;
    height: 2.3rem;
  }

  .ant-select-selection-item {
    color: ${({ theme }) => theme.textColor} !important;
  }

  svg {
    fill: ${({ theme }) => theme.textColor};
  }

  .ant-select-selection-placeholder {
    color: ${({ theme }) => theme.textColor};
  }

  .ant-select-clear {
    background-color: ${({ theme }) => theme.textColor};
    border-radius: ${Rounded.circle};
  }
`;

const MenuThemedStyle = createGlobalStyle`
  .ant-cascader-menu-item-content { 
    color: ${({ theme }) => theme.textColor};
  }

  .ant-cascader-menu-item:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }

  .ant-cascader-menu-item-active {
    background-color: ${({ theme }) => theme.sub} !important;
  }

  .ant-cascader-menu-item-expand-icon svg {
    color: ${({ theme }) => theme.textColor};
  }
`;

export const SettingsIndex: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();

  const { theme } = useTheme();

  const categories = useAppSelector(
    atiraLinkIndexSliceSelectors.selectAllCategories,
  );

  const selectedAtiraLink = useAppSelector(
    atiraLinkSliceSelectors.selectSelectedAtiraLink,
  );
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const { control, watch, handleSubmit, setValue, getValues, reset } =
    useForm<EditAtiraLinkIndexDto>();

  const [hasIndex, hasType] = watch(['indexVisible', 'type']);

  const dispatch = useAppDispatch();

  const getTypeDropDownItems = () =>
    Object.keys(AtiraLinkCategoryType)
      .filter((t) => t !== AtiraLinkCategoryType.BOTH)
      .map((type) => ({
        label: t(`common.${type.toLowerCase()}`),
        value: type,
      }));

  const getCascaderOptions = () => {
    const processCategory = (
      category: AtiraLinkCategory,
    ): { label: string; value: string; children?: any } => ({
      label:
        category?.locale?.[
          i18n.language.toUpperCase() as AtiraLinkCategoryAvailableLangs
        ]?.name || category.name,
      value: category._id,
      children:
        category.subCategories
          ?.filter((cat) => cat.availableTo.includes(hasType!))
          ?.map(processCategory) || [],
    });

    const root = categories.filter(
      (cat) => !cat.parentCategoryId && cat.availableTo.includes(hasType!),
    );
    return root.map(processCategory);
  };

  const onNext = async () => {
    try {
      setLoading(true);
      await dispatch(
        AtiraLinkIndexActions.upsertUserCategories({
          userId: selectedAtiraLink?.userId!,
          indexVisible: hasIndex,
          type: hasType,
          categoriesIds: getValues('categoriesIds') || [],
        }),
      ).unwrap();
      await dispatch(
        AtiraLinkActions.getUserAtiraLinks({
          userId: selectedAtiraLink?.userId!,
        }),
      ).unwrap();
      AtiraToast.success(t('settings.index.update.success'));
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(AtiraLinkIndexActions.getAllCategories());
    dispatch(AtiraLinkActions.getUserAtiraLinks({ userId }));
  }, [dispatch, userId]);

  useEffect(() => {
    reset({
      indexVisible: selectedAtiraLink?.indexVisible,
      type: selectedAtiraLink?.type,
      categoriesIds: selectedAtiraLink?.categoriesIds || [],
    });
  }, [reset, selectedAtiraLink]);

  return (
    <Flex justifyContent="space-between" gap="m">
      <ContentWrapper>
        <SectionWrapper>
          <Flex paddingTop="s">
            <SectionTitle>{t('settings.index.title')}</SectionTitle>
          </Flex>

          <Section>
            <Text fontSize="l">{t('settings.index.description')}</Text>
            <Description>{t('settings.index.description-2')}</Description>

            <Controller
              name="indexVisible"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Flex
                  flexDirection="column"
                  gap="s"
                  marginBottom="m"
                  marginTop="m"
                  width={'fit-content'}
                >
                  <Text>{t('common.enable')}</Text>

                  <Tooltip title={t('settings.index.switch-info')}>
                    <Flex alignItems="center" gap="s">
                      <Switch
                        style={{ alignSelf: 'center' }}
                        onChange={onChange}
                        value={value}
                      />
                      <AtiraIcon icon={faInfoCircle} />
                    </Flex>
                  </Tooltip>
                </Flex>
              )}
            />

            <Flex flexDirection="column" gap="m">
              <Controller
                name="type"
                rules={{
                  required: {
                    value: true,
                    message: t('common.this_field_required'),
                  },
                }}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <AtiraSelect
                    required
                    disabled={!hasIndex}
                    info={t('settings.index.type.select.title.info')}
                    title={t('settings.index.type.select.title')}
                    value={
                      value
                        ? {
                            label: t(`common.${value?.toLowerCase()}`),
                            value,
                          }
                        : undefined
                    }
                    options={getTypeDropDownItems()}
                    onChange={(e) => {
                      onChange(e);
                      setValue('categoriesIds', []);
                    }}
                    containerStyle={{ maxWidth: '18rem' }}
                  />
                )}
              />

              {hasIndex && hasType ? (
                <Flex flexDirection="column">
                  <Flex>
                    <Tooltip title={t('settings.index.category.info')}>
                      <AtiraIcon icon={faInfoCircle} />
                      <span style={{ marginInlineEnd: '0.2rem' }}></span>
                    </Tooltip>
                    <Text style={{ fontSize: '14px' }}>
                      {t('settings.index.category.title')}
                    </Text>
                  </Flex>
                  <Controller
                    name="categoriesIds"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <StyledCascader
                        placeholder={t('settings.index.category.placeholder')}
                        showSearch
                        allowClear
                        onClear={() => onChange([])}
                        expandTrigger="hover"
                        options={getCascaderOptions()}
                        onChange={onChange}
                        value={value}
                        changeOnSelect
                        style={{ maxWidth: '18rem', width: 'auto' }}
                      />
                    )}
                  />
                  <MenuThemedStyle theme={theme} />
                </Flex>
              ) : null}
            </Flex>

            <ActionsWrapper>
              <Button
                title={t('common.update')}
                onClick={handleSubmit(onNext)}
                disabled={!hasType}
                loading={loading}
                height="2.5rem"
              />

              <StyledAnchor
                href="https://index.atiralink.com"
                target="_blank"
                rel="noreferrer"
              >
                {t('settings.index.site.visit')}
                <AtiraIcon icon={faExternalLink} />
              </StyledAnchor>
            </ActionsWrapper>
          </Section>
        </SectionWrapper>
      </ContentWrapper>
    </Flex>
  );
};
